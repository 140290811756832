import EnhancedTable from 'components/EnhancedTable';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { useSettingsStore } from 'store/Settings/settingsStore';

export const BarcodesInformation = ({
  refreshData,
  locationData,
}: {
  refreshData: () => void;
  locationData: ILocationData;
}) => {
  const { LOCATION_MODAL_INFO } = useSettingsStore();

  return (
    <EnhancedTable
      disablePagination={true}
      showHeader={false}
      headCells={LOCATION_MODAL_INFO}
      rows={locationData?.rowData ? [locationData.rowData] : []}
      isLoading={false}
      refreshData={{
        refreshData,
      }}
      showSearch={false}
      hideToolbar={true}
      enableHoverOnRows={false}
      allowSorting={false}
      currentActiveSpinner={{}}
      tableFor=""
      tableSubtitle=""
      tableTitle=""
    />
  );
};
