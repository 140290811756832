// functions
import { singleRequestHandler } from '../requestHelpers';

// store
import IssuesStore from '../../store/IssuesStore';

export const getImage = (url: string) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = 'anonymous'; // This enables CORS

    img.addEventListener('load', () => {
      const canvas = document.createElement('canvas');

      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(img, 0, 0);

      canvas.toBlob((blob) => {
        resolve(blob);
      });
    });
    img.addEventListener('error', (err) => reject(err));
    img.src = url;
  });

export const openImageInNewTab = (
  systemId: string,
  location: string,
  setImagesDownloading: (download: boolean) => any,
  slotStatusVersion: any,
  isReview: any,
  imgIndex: string | number,
) => {
  singleRequestHandler({
    request: IssuesStore.getImages,
    requestParams: [systemId, location, slotStatusVersion, isReview],
    callbackBeforeSend: () => setImagesDownloading(true),
    callbackSuccess: (r: { data: { urls: any } }) => {
      const locationImageURLs = r.data?.urls ? r.data?.urls : [];
      const imageURL = locationImageURLs[imgIndex];

      // Open image on new tab
      if (imageURL) {
        window.open(imageURL, '_blank');
      }
    },
    callbackFinally: () => setImagesDownloading(false),
    messageErrorFallback: `An error occurred while opening images for ${location}`,
  });
};
