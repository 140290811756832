/* tslint:disable */
/* eslint-disable */
/**
 * Warehouse Status
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface IActiveIssueCountGetResponseST
 */
export interface IActiveIssueCountGetResponseST {
  /**
   *
   * @type {IIssueCountST}
   * @memberof IActiveIssueCountGetResponseST
   */
  issue_count: IIssueCountST;
}
/**
 *
 * @export
 * @interface IErrorResponseErrorsInnerST
 */
export interface IErrorResponseErrorsInnerST {
  /**
   * Error code
   * @type {string}
   * @memberof IErrorResponseErrorsInnerST
   */
  code: string;
}
/**
 *
 * @export
 * @interface IErrorResponseST
 */
export interface IErrorResponseST {
  /**
   * Error description
   * @type {string}
   * @memberof IErrorResponseST
   */
  message: string;
  /**
   * Error timestamp
   * @type {string}
   * @memberof IErrorResponseST
   */
  timestamp: string;
  /**
   * User triggering the request
   * @type {string}
   * @memberof IErrorResponseST
   */
  user_name: string;
  /**
   * The request trace id
   * @type {string}
   * @memberof IErrorResponseST
   */
  trace_id: string;
  /**
   * The URL that can be used to report the error
   * @type {string}
   * @memberof IErrorResponseST
   */
  report_url: string | null;
  /**
   * List of Error objects.
   * @type {Array<IErrorResponseErrorsInnerST>}
   * @memberof IErrorResponseST
   */
  errors: Array<IErrorResponseErrorsInnerST>;
}
/**
 * Request history to update location exclusion status.
 * @export
 * @interface IExclusionRequestResponseST
 */
export interface IExclusionRequestResponseST {
  /**
   * Unique identifier of the location exclusion request.
   * @type {string}
   * @memberof IExclusionRequestResponseST
   */
  request_id?: string;
  /**
   * User provided reason.
   * @type {string}
   * @memberof IExclusionRequestResponseST
   */
  reason?: string;
  /**
   * Name of the user to update the location exclusion status.
   * @type {string}
   * @memberof IExclusionRequestResponseST
   */
  email?: string;
  /**
   * Datetime when the request was made.
   * @type {string}
   * @memberof IExclusionRequestResponseST
   */
  created_at?: string;
  /**
   * List of modified locations data.
   * @type {Array<IExclusionStatusPostRequestLocationsInnerST>}
   * @memberof IExclusionRequestResponseST
   */
  locations?: Array<IExclusionStatusPostRequestLocationsInnerST>;
}
/**
 *
 * @export
 * @interface IExclusionStatusPostRequestLocationsInnerST
 */
export interface IExclusionStatusPostRequestLocationsInnerST {
  /**
   * Slot label.
   * @type {string}
   * @memberof IExclusionStatusPostRequestLocationsInnerST
   */
  label: string;
  /**
   * Exclusion Status of this slot
   * @type {string}
   * @memberof IExclusionStatusPostRequestLocationsInnerST
   */
  exclusion: IExclusionStatusPostRequestLocationsInnerSTExclusionEnum;
}

export const IExclusionStatusPostRequestLocationsInnerSTExclusionEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type IExclusionStatusPostRequestLocationsInnerSTExclusionEnum =
  typeof IExclusionStatusPostRequestLocationsInnerSTExclusionEnum[keyof typeof IExclusionStatusPostRequestLocationsInnerSTExclusionEnum];

/**
 * Batch update location exclusion status.
 * @export
 * @interface IExclusionStatusPostRequestST
 */
export interface IExclusionStatusPostRequestST {
  /**
   * User provided reason.
   * @type {string}
   * @memberof IExclusionStatusPostRequestST
   */
  reason?: string;
  /**
   * List of modified locations data.
   * @type {Array<IExclusionStatusPostRequestLocationsInnerST>}
   * @memberof IExclusionStatusPostRequestST
   */
  locations?: Array<IExclusionStatusPostRequestLocationsInnerST>;
}
/**
 *
 * @export
 * @interface IIssueCountST
 */
export interface IIssueCountST {
  [key: string]: any;

  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE1_W_BC_V_NOTEMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE2_W_BC_V_NORESULT: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE3_W_EMPTY_V_NORESULT: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE4_W_INVALID: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  INCONCLUSIVE5_V_INVALID: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE1_W_BC_V_EMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE2_W_BC_NE_V_BC: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE3_W_EMPTY_V_BC: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE4_W_EMPTY_V_NOTEMPTY: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  ISSUE5_W_BC_DUPLICATE: IIssueStateST;
  /**
   *
   * @type {IIssueStateST}
   * @memberof IIssueCountST
   */
  C0014_DOUBLE_DEPTH: IIssueStateST;
  /**
   *
   * @type {Array<string>}
   * @memberof IIssueCountST
   */
  states_in_totals: Array<IIssueCountSTStatesInTotalsEnum>;
  /**
   *
   * @type {IIssueCountTotalsST}
   * @memberof IIssueCountST
   */
  totals: IIssueCountTotalsST;
}

export const IIssueCountSTStatesInTotalsEnum = {
  New: 'NEW',
  Known: 'KNOWN',
  Snoozed: 'SNOOZED',
  Solved: 'SOLVED',
  Na: 'NA',
} as const;

export type IIssueCountSTStatesInTotalsEnum =
  typeof IIssueCountSTStatesInTotalsEnum[keyof typeof IIssueCountSTStatesInTotalsEnum];

/**
 *
 * @export
 * @interface IIssueCountTotalsST
 */
export interface IIssueCountTotalsST {
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE1_W_BC_V_EMPTY: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE2_W_BC_NE_V_BC: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE3_W_EMPTY_V_BC: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE4_W_EMPTY_V_NOTEMPTY: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  ISSUE5_W_BC_DUPLICATE: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE1_W_BC_V_NOTEMPTY: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE2_W_BC_V_NORESULT: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE3_W_EMPTY_V_NORESULT: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE4_W_INVALID: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  INCONCLUSIVE5_V_INVALID: number;
  /**
   *
   * @type {number}
   * @memberof IIssueCountTotalsST
   */
  C0014_DOUBLE_DEPTH: number;
}
/**
 *
 * @export
 * @interface IIssueGetAllResponseST
 */
export interface IIssueGetAllResponseST {
  /**
   *
   * @type {{ [key: string]: Array<IIssueST>; }}
   * @memberof IIssueGetAllResponseST
   */
  issues: { [key: string]: Array<IIssueST> };
}
/**
 *
 * @export
 * @interface IIssueGetResponseST
 */
export interface IIssueGetResponseST {
  /**
   * Unique id of this issue
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  issue_id: string;
  /**
   * The location associated with this issue
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  location: string;
  /**
   * The type of the issue
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  type: IIssueGetResponseSTTypeEnum;
  /**
   *
   * @type {IIssueSlotStatusST}
   * @memberof IIssueGetResponseST
   */
  slot_status: IIssueSlotStatusST;
  /**
   * The state the issue is in
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  state: IIssueGetResponseSTStateEnum;
  /**
   * When this issue was first found
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  first_found_on: string | null;
  /**
   * When this issue transitioned to either SOLVED or NA
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  resolved_on: string | null;
  /**
   * Type of the issue after resolution
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  resolved_type: IIssueGetResponseSTResolvedTypeEnum;
  /**
   * List of ids of issues that are linked to this issue
   * @type {Array<string>}
   * @memberof IIssueGetResponseST
   */
  linked_issues: Array<string> | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof IIssueGetResponseST
   */
  wms_slot_status_with_barcode_duplicate: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof IIssueGetResponseST
   */
  wms_slot_status_with_verity_bc: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof IIssueGetResponseST
   */
  verity_slot_status_with_wms_bc: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   * When the issue was snoozed
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  snoozed_on: string | null;
  /**
   * ID of user who snoozed the issue
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  snoozed_by_user_id: string | null;
  /**
   * Name of user who snoozed the issue
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  snoozed_by_user_name: string | null;
  /**
   * Date and time until the issue is snoozed
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  snoozed_until: string | null;
  /**
   * When the issue was last updated
   * @type {string}
   * @memberof IIssueGetResponseST
   */
  updated_at: string;
  /**
   * Version of this issue
   * @type {number}
   * @memberof IIssueGetResponseST
   */
  version: number;
}

export const IIssueGetResponseSTTypeEnum = {
  Issue1WBcVEmpty: 'ISSUE1_W_BC_V_EMPTY',
  Issue2WBcNeVBc: 'ISSUE2_W_BC_NE_V_BC',
  Issue3WEmptyVBc: 'ISSUE3_W_EMPTY_V_BC',
  Issue4WEmptyVNotempty: 'ISSUE4_W_EMPTY_V_NOTEMPTY',
  Issue5WBcDuplicate: 'ISSUE5_W_BC_DUPLICATE',
  Inconclusive1WBcVNotempty: 'INCONCLUSIVE1_W_BC_V_NOTEMPTY',
  Inconclusive2WBcVNoresult: 'INCONCLUSIVE2_W_BC_V_NORESULT',
  Inconclusive3WEmptyVNoresult: 'INCONCLUSIVE3_W_EMPTY_V_NORESULT',
  Inconclusive4WInvalid: 'INCONCLUSIVE4_W_INVALID',
  Inconclusive5VInvalid: 'INCONCLUSIVE5_V_INVALID',
  C0014DoubleDepth: 'C0014_DOUBLE_DEPTH',
} as const;

export type IIssueGetResponseSTTypeEnum =
  typeof IIssueGetResponseSTTypeEnum[keyof typeof IIssueGetResponseSTTypeEnum];
export const IIssueGetResponseSTStateEnum = {
  New: 'NEW',
  Known: 'KNOWN',
  Snoozed: 'SNOOZED',
  Solved: 'SOLVED',
  Na: 'NA',
} as const;

export type IIssueGetResponseSTStateEnum =
  typeof IIssueGetResponseSTStateEnum[keyof typeof IIssueGetResponseSTStateEnum];
export const IIssueGetResponseSTResolvedTypeEnum = {
  Correct1WEmptyVEmpty: 'CORRECT1_W_EMPTY_V_EMPTY',
  Correct2WBcEqVBc: 'CORRECT2_W_BC_EQ_V_BC',
  NoneVDataOutdated: 'NONE_V_DATA_OUTDATED',
  NoneVDataMissing: 'NONE_V_DATA_MISSING',
  NoneWDataMissing: 'NONE_W_DATA_MISSING',
  NoneSlotBlocked: 'NONE_SLOT_BLOCKED',
  C001BCorrectWBcNaVEmpty: 'C001B_CORRECT_W_BC_NA_V_EMPTY',
  C001BCorrectWBcNaVBc: 'C001B_CORRECT_W_BC_NA_V_BC',
  C001BCorrectWBcNaVNotempty: 'C001B_CORRECT_W_BC_NA_V_NOTEMPTY',
  NonePictureOnly: 'NONE_PICTURE_ONLY',
  Null: 'null',
} as const;

export type IIssueGetResponseSTResolvedTypeEnum =
  typeof IIssueGetResponseSTResolvedTypeEnum[keyof typeof IIssueGetResponseSTResolvedTypeEnum];

/**
 *
 * @export
 * @interface IIssueST
 */
export interface IIssueST {
  /**
   * Unique id of this issue
   * @type {string}
   * @memberof IIssueST
   */
  issue_id: string;
  /**
   * The location associated with this issue
   * @type {string}
   * @memberof IIssueST
   */
  location: string;
  /**
   * The type of the issue
   * @type {string}
   * @memberof IIssueST
   */
  type: IIssueSTTypeEnum;
  /**
   *
   * @type {IIssueSlotStatusST}
   * @memberof IIssueST
   */
  slot_status: IIssueSlotStatusST;
  /**
   * The state the issue is in
   * @type {string}
   * @memberof IIssueST
   */
  state: IIssueSTStateEnum;
  /**
   * When this issue was first found
   * @type {string}
   * @memberof IIssueST
   */
  first_found_on: string | null;
  /**
   * When this issue transitioned to either SOLVED or NA
   * @type {string}
   * @memberof IIssueST
   */
  resolved_on: string | null;
  /**
   * Type of the issue after resolution
   * @type {string}
   * @memberof IIssueST
   */
  resolved_type: IIssueSTResolvedTypeEnum;
  /**
   * List of ids of issues that are linked to this issue
   * @type {Array<string>}
   * @memberof IIssueST
   */
  linked_issues: Array<string> | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof IIssueST
   */
  wms_slot_status_with_barcode_duplicate: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof IIssueST
   */
  wms_slot_status_with_verity_bc: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof IIssueST
   */
  verity_slot_status_with_wms_bc: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   * When the issue was snoozed
   * @type {string}
   * @memberof IIssueST
   */
  snoozed_on: string | null;
  /**
   * ID of user who snoozed the issue
   * @type {string}
   * @memberof IIssueST
   */
  snoozed_by_user_id: string | null;
  /**
   * Name of user who snoozed the issue
   * @type {string}
   * @memberof IIssueST
   */
  snoozed_by_user_name: string | null;
  /**
   * Date and time until the issue is snoozed
   * @type {string}
   * @memberof IIssueST
   */
  snoozed_until: string | null;
  /**
   * When the issue was last updated
   * @type {string}
   * @memberof IIssueST
   */
  updated_at: string;
  /**
   * Version of this issue
   * @type {number}
   * @memberof IIssueST
   */
  version: number;
}

export const IIssueSTTypeEnum = {
  Issue1WBcVEmpty: 'ISSUE1_W_BC_V_EMPTY',
  Issue2WBcNeVBc: 'ISSUE2_W_BC_NE_V_BC',
  Issue3WEmptyVBc: 'ISSUE3_W_EMPTY_V_BC',
  Issue4WEmptyVNotempty: 'ISSUE4_W_EMPTY_V_NOTEMPTY',
  Issue5WBcDuplicate: 'ISSUE5_W_BC_DUPLICATE',
  Inconclusive1WBcVNotempty: 'INCONCLUSIVE1_W_BC_V_NOTEMPTY',
  Inconclusive2WBcVNoresult: 'INCONCLUSIVE2_W_BC_V_NORESULT',
  Inconclusive3WEmptyVNoresult: 'INCONCLUSIVE3_W_EMPTY_V_NORESULT',
  Inconclusive4WInvalid: 'INCONCLUSIVE4_W_INVALID',
  Inconclusive5VInvalid: 'INCONCLUSIVE5_V_INVALID',
  C0014DoubleDepth: 'C0014_DOUBLE_DEPTH',
} as const;

export type IIssueSTTypeEnum = typeof IIssueSTTypeEnum[keyof typeof IIssueSTTypeEnum];
export const IIssueSTStateEnum = {
  New: 'NEW',
  Known: 'KNOWN',
  Snoozed: 'SNOOZED',
  Solved: 'SOLVED',
  Na: 'NA',
} as const;

export type IIssueSTStateEnum = typeof IIssueSTStateEnum[keyof typeof IIssueSTStateEnum];
export const IIssueSTResolvedTypeEnum = {
  Correct1WEmptyVEmpty: 'CORRECT1_W_EMPTY_V_EMPTY',
  Correct2WBcEqVBc: 'CORRECT2_W_BC_EQ_V_BC',
  NoneVDataOutdated: 'NONE_V_DATA_OUTDATED',
  NoneVDataMissing: 'NONE_V_DATA_MISSING',
  NoneWDataMissing: 'NONE_W_DATA_MISSING',
  NoneSlotBlocked: 'NONE_SLOT_BLOCKED',
  C001BCorrectWBcNaVEmpty: 'C001B_CORRECT_W_BC_NA_V_EMPTY',
  C001BCorrectWBcNaVBc: 'C001B_CORRECT_W_BC_NA_V_BC',
  C001BCorrectWBcNaVNotempty: 'C001B_CORRECT_W_BC_NA_V_NOTEMPTY',
  NonePictureOnly: 'NONE_PICTURE_ONLY',
  Null: 'null',
} as const;

export type IIssueSTResolvedTypeEnum =
  typeof IIssueSTResolvedTypeEnum[keyof typeof IIssueSTResolvedTypeEnum];

/**
 *
 * @export
 * @interface IIssueSlotStatusST
 */
export interface IIssueSlotStatusST {
  /**
   *
   * @type {string}
   * @memberof IIssueSlotStatusST
   */
  slot_label?: string;
  /**
   *
   * @type {IVeritySlotStatusST}
   * @memberof IIssueSlotStatusST
   */
  verity_status?: IVeritySlotStatusST;
  /**
   *
   * @type {IWMSSlotStatusST}
   * @memberof IIssueSlotStatusST
   */
  wms_status?: IWMSSlotStatusST;
}
/**
 *
 * @export
 * @interface IIssueStateST
 */
export interface IIssueStateST {
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  NEW: number;
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  KNOWN: number;
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  SNOOZED: number;
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  SOLVED: number;
  /**
   *
   * @type {number}
   * @memberof IIssueStateST
   */
  NA: number;
}
/**
 * @type IIssueVeritySlotStatusWithWmsBcST
 * @export
 */
export type IIssueVeritySlotStatusWithWmsBcST = IVeritySlotStatusST | string;

/**
 * @type IIssueWmsSlotStatusWithBarcodeDuplicateST
 * @export
 */
export type IIssueWmsSlotStatusWithBarcodeDuplicateST = IWMSSlotStatusST | string;

/**
 *
 * @export
 * @interface ILIssueST
 */
export interface ILIssueST {
  /**
   * Unique id of this issue
   * @type {string}
   * @memberof ILIssueST
   */
  issue_id: string;
  /**
   * The location associated with this issue
   * @type {string}
   * @memberof ILIssueST
   */
  location: string;
  /**
   * The type of the issue
   * @type {string}
   * @memberof ILIssueST
   */
  type: ILIssueSTTypeEnum;
  /**
   * The state the issue is in
   * @type {string}
   * @memberof ILIssueST
   */
  state: ILIssueSTStateEnum;
}

export const ILIssueSTTypeEnum = {
  Issue1WBcVEmpty: 'ISSUE1_W_BC_V_EMPTY',
  Issue2WBcNeVBc: 'ISSUE2_W_BC_NE_V_BC',
  Issue3WEmptyVBc: 'ISSUE3_W_EMPTY_V_BC',
  Issue4WEmptyVNotempty: 'ISSUE4_W_EMPTY_V_NOTEMPTY',
  Issue5WBcDuplicate: 'ISSUE5_W_BC_DUPLICATE',
  Inconclusive1WBcVNotempty: 'INCONCLUSIVE1_W_BC_V_NOTEMPTY',
  Inconclusive2WBcVNoresult: 'INCONCLUSIVE2_W_BC_V_NORESULT',
  Inconclusive3WEmptyVNoresult: 'INCONCLUSIVE3_W_EMPTY_V_NORESULT',
  Inconclusive4WInvalid: 'INCONCLUSIVE4_W_INVALID',
  Inconclusive5VInvalid: 'INCONCLUSIVE5_V_INVALID',
  C0014DoubleDepth: 'C0014_DOUBLE_DEPTH',
} as const;

export type ILIssueSTTypeEnum = typeof ILIssueSTTypeEnum[keyof typeof ILIssueSTTypeEnum];
export const ILIssueSTStateEnum = {
  New: 'NEW',
  Known: 'KNOWN',
  Snoozed: 'SNOOZED',
  Solved: 'SOLVED',
  Na: 'NA',
} as const;

export type ILIssueSTStateEnum = typeof ILIssueSTStateEnum[keyof typeof ILIssueSTStateEnum];

/**
 *
 * @export
 * @interface ILLocationDataST
 */
export interface ILLocationDataST {
  /**
   *
   * @type {string}
   * @memberof ILLocationDataST
   */
  slot_label?: string;
  /**
   *
   * @type {ILLocationDataVerityStatusST}
   * @memberof ILLocationDataST
   */
  verity_status?: ILLocationDataVerityStatusST | null;
  /**
   *
   * @type {ILLocationDataWmsStatusST}
   * @memberof ILLocationDataST
   */
  wms_status?: ILLocationDataWmsStatusST | null;
  /**
   *
   * @type {ILLocationDataSlotSettingsST}
   * @memberof ILLocationDataST
   */
  slot_settings?: ILLocationDataSlotSettingsST;
  /**
   *
   * @type {Array<ILIssueST>}
   * @memberof ILLocationDataST
   */
  issues?: Array<ILIssueST>;
}
/**
 *
 * @export
 * @interface ILLocationDataSlotSettingsST
 */
export interface ILLocationDataSlotSettingsST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILLocationDataSlotSettingsST
   */
  slot_label: string;
  /**
   * Exclusion Status of this slot defined in UDB.
   * @type {string}
   * @memberof ILLocationDataSlotSettingsST
   */
  exclusion_status: ILLocationDataSlotSettingsSTExclusionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ILLocationDataSlotSettingsST
   */
  exclusion_request_id: string | null;
}

export const ILLocationDataSlotSettingsSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type ILLocationDataSlotSettingsSTExclusionStatusEnum =
  typeof ILLocationDataSlotSettingsSTExclusionStatusEnum[keyof typeof ILLocationDataSlotSettingsSTExclusionStatusEnum];

/**
 * @type ILLocationDataVerityStatusST
 * @export
 */
export type ILLocationDataVerityStatusST = ILVeritySlotStatusST | string;

/**
 * @type ILLocationDataWmsStatusST
 * @export
 */
export type ILLocationDataWmsStatusST = ILWMSSlotStatusST | string;

/**
 * Slot settings.
 * @export
 * @interface ILSlotSettingsST
 */
export interface ILSlotSettingsST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILSlotSettingsST
   */
  slot_label: string;
  /**
   * Exclusion Status of this slot defined in UDB.
   * @type {string}
   * @memberof ILSlotSettingsST
   */
  exclusion_status: ILSlotSettingsSTExclusionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ILSlotSettingsST
   */
  exclusion_request_id: string | null;
}

export const ILSlotSettingsSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type ILSlotSettingsSTExclusionStatusEnum =
  typeof ILSlotSettingsSTExclusionStatusEnum[keyof typeof ILSlotSettingsSTExclusionStatusEnum];

/**
 *
 * @export
 * @interface ILVeritySlotStatusST
 */
export interface ILVeritySlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILVeritySlotStatusST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof ILVeritySlotStatusST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates weather the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof ILVeritySlotStatusST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof ILVeritySlotStatusST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof ILVeritySlotStatusST
   */
  barcodes: Array<string>;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof ILVeritySlotStatusST
   */
  approved: boolean;
}

/**
 *
 * @export
 * @interface ILWMSSlotStatusST
 */
export interface ILWMSSlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILWMSSlotStatusST
   */
  slot_label: string;
  /**
   * State of the slot
   * @type {string}
   * @memberof ILWMSSlotStatusST
   */
  state: ILWMSSlotStatusSTStateEnum;
  /**
   * Timestamp of when the content of this slot changed
   * @type {string}
   * @memberof ILWMSSlotStatusST
   */
  changed_at: string;
  /**
   * List of barcodes expected at location
   * @type {Array<string>}
   * @memberof ILWMSSlotStatusST
   */
  barcodes: Array<string>;
  /**
   * List of article numbers expected at location
   * @type {Array<string>}
   * @memberof ILWMSSlotStatusST
   */
  article_nos: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof ILWMSSlotStatusST
   */
  customers: Array<string>;
  /**
   * List of quantity of each article
   * @type {Array<number>}
   * @memberof ILWMSSlotStatusST
   */
  qtys: Array<number>;
  /**
   * Exclusion Status of this slot defined in WMS data.
   * @type {string}
   * @memberof ILWMSSlotStatusST
   */
  exclusion_status: ILWMSSlotStatusSTExclusionStatusEnum;
}

export const ILWMSSlotStatusSTStateEnum = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  Invalid: 'INVALID',
} as const;

export type ILWMSSlotStatusSTStateEnum =
  typeof ILWMSSlotStatusSTStateEnum[keyof typeof ILWMSSlotStatusSTStateEnum];
export const ILWMSSlotStatusSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
} as const;

export type ILWMSSlotStatusSTExclusionStatusEnum =
  typeof ILWMSSlotStatusSTExclusionStatusEnum[keyof typeof ILWMSSlotStatusSTExclusionStatusEnum];

/**
 *
 * @export
 * @interface ILocationDataForReviewST
 */
export interface ILocationDataForReviewST {
  /**
   *
   * @type {string}
   * @memberof ILocationDataForReviewST
   */
  slot_label: string;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof ILocationDataForReviewST
   */
  verity_status: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof ILocationDataForReviewST
   */
  wms_status: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {ILocationDataForReviewSlotSettingsST}
   * @memberof ILocationDataForReviewST
   */
  slot_settings?: ILocationDataForReviewSlotSettingsST;
  /**
   * State of the report location
   * @type {string}
   * @memberof ILocationDataForReviewST
   */
  state?: ILocationDataForReviewSTStateEnum;
  /**
   *
   * @type {Array<IIssueST>}
   * @memberof ILocationDataForReviewST
   */
  issues?: Array<IIssueST>;
}

export const ILocationDataForReviewSTStateEnum = {
  ToBeExecuted: 'TO_BE_EXECUTED',
  PendingBackup: 'PENDING_BACKUP',
  Scanned: 'SCANNED',
  Aborted: 'ABORTED',
  Failed: 'FAILED',
  Excluded: 'EXCLUDED',
  Unreachable: 'UNREACHABLE',
} as const;

export type ILocationDataForReviewSTStateEnum =
  typeof ILocationDataForReviewSTStateEnum[keyof typeof ILocationDataForReviewSTStateEnum];

/**
 *
 * @export
 * @interface ILocationDataForReviewSlotSettingsST
 */
export interface ILocationDataForReviewSlotSettingsST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ILocationDataForReviewSlotSettingsST
   */
  slot_label: string;
  /**
   * Exclusion Status of this slot defined in UDB.
   * @type {string}
   * @memberof ILocationDataForReviewSlotSettingsST
   */
  exclusion_status: ILocationDataForReviewSlotSettingsSTExclusionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ILocationDataForReviewSlotSettingsST
   */
  exclusion_request_id: string | null;
  /**
   * Time when this information was created / last updated
   * @type {string}
   * @memberof ILocationDataForReviewSlotSettingsST
   */
  updated_at: string | null;
}

export const ILocationDataForReviewSlotSettingsSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type ILocationDataForReviewSlotSettingsSTExclusionStatusEnum =
  typeof ILocationDataForReviewSlotSettingsSTExclusionStatusEnum[keyof typeof ILocationDataForReviewSlotSettingsSTExclusionStatusEnum];

/**
 *
 * @export
 * @interface ILocationDataST
 */
export interface ILocationDataST {
  /**
   *
   * @type {string}
   * @memberof ILocationDataST
   */
  slot_label: string;
  /**
   *
   * @type {IIssueVeritySlotStatusWithWmsBcST}
   * @memberof ILocationDataST
   */
  verity_status: IIssueVeritySlotStatusWithWmsBcST | null;
  /**
   *
   * @type {IIssueWmsSlotStatusWithBarcodeDuplicateST}
   * @memberof ILocationDataST
   */
  wms_status: IIssueWmsSlotStatusWithBarcodeDuplicateST | null;
  /**
   *
   * @type {ILocationDataForReviewSlotSettingsST}
   * @memberof ILocationDataST
   */
  slot_settings?: ILocationDataForReviewSlotSettingsST;
  /**
   * State of the report location
   * @type {string}
   * @memberof ILocationDataST
   */
  state?: ILocationDataSTStateEnum;
  /**
   *
   * @type {Array<IIssueST>}
   * @memberof ILocationDataST
   */
  issues: Array<IIssueST>;
}

export const ILocationDataSTStateEnum = {
  ToBeExecuted: 'TO_BE_EXECUTED',
  PendingBackup: 'PENDING_BACKUP',
  Scanned: 'SCANNED',
  Aborted: 'ABORTED',
  Failed: 'FAILED',
  Excluded: 'EXCLUDED',
  Unreachable: 'UNREACHABLE',
} as const;

export type ILocationDataSTStateEnum =
  typeof ILocationDataSTStateEnum[keyof typeof ILocationDataSTStateEnum];

/**
 *
 * @export
 * @interface ILocationsDataSendFeedbackPostRequestST
 */
export interface ILocationsDataSendFeedbackPostRequestST {
  /**
   * the location label
   * @type {string}
   * @memberof ILocationsDataSendFeedbackPostRequestST
   */
  location: string;
  /**
   * the version of the verity slot status of the desired location
   * @type {number}
   * @memberof ILocationsDataSendFeedbackPostRequestST
   */
  verity_slot_status_version: number | null;
  /**
   * the version of the WMS slot status of the desired location
   * @type {number}
   * @memberof ILocationsDataSendFeedbackPostRequestST
   */
  wms_slot_status_version: number | null;
  /**
   * The context from which the route is invoked
   * @type {string}
   * @memberof ILocationsDataSendFeedbackPostRequestST
   */
  context: string;
  /**
   * the report id
   * @type {string}
   * @memberof ILocationsDataSendFeedbackPostRequestST
   */
  report_id: string | null;
  /**
   * the message to include in the email
   * @type {string}
   * @memberof ILocationsDataSendFeedbackPostRequestST
   */
  message: string;
  /**
   * Link to UDB where the user is making this request.
   * @type {string}
   * @memberof ILocationsDataSendFeedbackPostRequestST
   */
  link_to_location?: string;
  /**
   * List of feedback categories, chosen manually when reporting.
   * @type {Array<string>}
   * @memberof ILocationsDataSendFeedbackPostRequestST
   */
  feedback_categories: Array<ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum>;
}

export const ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum = {
  GlareOrReflection: 'GLARE_OR_REFLECTION',
  PictureTooFar: 'PICTURE_TOO_FAR',
  NonEmptyButEmpty: 'NON_EMPTY_BUT_EMPTY',
  NoBarcodeButBarcode: 'NO_BARCODE_BUT_BARCODE',
  BarcodeLabelIssue: 'BARCODE_LABEL_ISSUE',
  BlurryPicture: 'BLURRY_PICTURE',
  EmptyButNonEmpty: 'EMPTY_BUT_NON_EMPTY',
  IncorrectBarcode: 'INCORRECT_BARCODE',
  Other: 'OTHER',
  BarcodeNotVisible: 'BARCODE_NOT_VISIBLE',
  BarcodePartiallyVisible: 'BARCODE_PARTIALLY_VISIBLE',
  BarcodeNotReadableGlare: 'BARCODE_NOT_READABLE_GLARE',
  BarcodeNotReadableBlur: 'BARCODE_NOT_READABLE_BLUR',
  BarcodeIgnoredButVisible: 'BARCODE_IGNORED_BUT_VISIBLE',
  BarcodeObstructed: 'BARCODE_OBSTRUCTED',
  SlotOutOfBounds: 'SLOT_OUT_OF_BOUNDS',
  EmptySlotDetected: 'EMPTY_SLOT_DETECTED',
  BarcodeIrrelevant: 'BARCODE_IRRELEVANT',
} as const;

export type ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum =
  typeof ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum[keyof typeof ILocationsDataSendFeedbackPostRequestSTFeedbackCategoriesEnum];

/**
 *
 * @export
 * @interface ILocationsMetaDataGetResponseST
 */
export interface ILocationsMetaDataGetResponseST {
  /**
   *
   * @type {{ [key: string]: ILLocationDataST; }}
   * @memberof ILocationsMetaDataGetResponseST
   */
  locations?: { [key: string]: ILLocationDataST };
}
/**
 * Slot settings.
 * @export
 * @interface ISlotSettingsST
 */
export interface ISlotSettingsST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof ISlotSettingsST
   */
  slot_label: string;
  /**
   * Exclusion Status of this slot defined in UDB.
   * @type {string}
   * @memberof ISlotSettingsST
   */
  exclusion_status: ISlotSettingsSTExclusionStatusEnum;
  /**
   *
   * @type {string}
   * @memberof ISlotSettingsST
   */
  exclusion_request_id: string | null;
  /**
   * Time when this information was created / last updated
   * @type {string}
   * @memberof ISlotSettingsST
   */
  updated_at: string | null;
}

export const ISlotSettingsSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
  NotSet: 'NOT_SET',
} as const;

export type ISlotSettingsSTExclusionStatusEnum =
  typeof ISlotSettingsSTExclusionStatusEnum[keyof typeof ISlotSettingsSTExclusionStatusEnum];

/**
 *
 * @export
 * @interface IVeritySlotStatusST
 */
export interface IVeritySlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  slot_label: string;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusST
   */
  state: IVeritySlotStatusStateST;
  /**
   * Indicates wether the slot information needs to be refined with a backup flight
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  pending_backup: boolean;
  /**
   * Time when Verity collected the data
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  collected_at: string;
  /**
   * List of barcodes that were detected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  barcodes: Array<string>;
  /**
   * Ids of images collected
   * @type {Array<string>}
   * @memberof IVeritySlotStatusST
   */
  image_ids: Array<string>;
  /**
   * Result ID for this Verity slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  result_id: string | null;
  /**
   * Time when Verity will next start collecting data for this slot
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  next_scheduled_at: string | null;
  /**
   * List of overrides to Verity data
   * @type {Array<IVeritySlotStatusUserOverrideST>}
   * @memberof IVeritySlotStatusST
   */
  user_overrides: Array<IVeritySlotStatusUserOverrideST> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  version: number;
  /**
   * Version of the slot status in the staging table
   * @type {number}
   * @memberof IVeritySlotStatusST
   */
  staging_version: number | null;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  updated_at: string;
  /**
   * Indicates if the slot status was approved as part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusST
   */
  approved: boolean;
  /**
   * Indicates the username of the person that approved the slot status
   * @type {string}
   * @memberof IVeritySlotStatusST
   */
  approved_by: string | null;
}

/**
 * State of the slot
 * @export
 * @enum {string}
 */

export const IVeritySlotStatusStateST = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  NotEmpty: 'NOT_EMPTY',
  Unreachable: 'UNREACHABLE',
  NoResult: 'NO_RESULT',
} as const;

export type IVeritySlotStatusStateST =
  typeof IVeritySlotStatusStateST[keyof typeof IVeritySlotStatusStateST];

/**
 *
 * @export
 * @interface IVeritySlotStatusUserOverrideST
 */
export interface IVeritySlotStatusUserOverrideST {
  /**
   * Id of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_id: string;
  /**
   * Name of user making the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  user_name: string;
  /**
   * Timestamp of when the user submitted the override
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  timestamp: string;
  /**
   * User-provided flag indicating if original Verity data were correct
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  verity_correct: boolean;
  /**
   * True, if override corresponds to data provided by WMS
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  set_to_expected: boolean;
  /**
   *
   * @type {IVeritySlotStatusStateST}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  state: IVeritySlotStatusStateST;
  /**
   * List of barcodes that should override those found by Verity
   * @type {Array<string>}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  barcodes: Array<string>;
  /**
   * Free-text comment by user
   * @type {string}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  comment: string | null;
  /**
   * Indicates if the update is part of a review
   * @type {boolean}
   * @memberof IVeritySlotStatusUserOverrideST
   */
  review: boolean;
}

/**
 *
 * @export
 * @interface IWMSSlotStatusST
 */
export interface IWMSSlotStatusST {
  /**
   * Label of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_label: string;
  /**
   * State of the slot
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  state: IWMSSlotStatusSTStateEnum;
  /**
   * Timestamp of when the content of this slot changed
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  changed_at: string;
  /**
   * ID of the WMS snapshot providing these data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  snapshot_id: string;
  /**
   * List of barcodes expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  barcodes: Array<string>;
  /**
   * List of article numbers expected at location
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  article_nos: Array<string>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  slot_usage: IWMSSlotStatusSTSlotUsageEnum;
  /**
   *
   * @type {Array<string>}
   * @memberof IWMSSlotStatusST
   */
  customers: Array<string>;
  /**
   *
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  unit_load_dimensions: Array<number> | null;
  /**
   * Version of the slot status
   * @type {number}
   * @memberof IWMSSlotStatusST
   */
  version: number;
  /**
   * Time when this information was created/last updated
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  updated_at: string;
  /**
   * List of quantity of each article
   * @type {Array<number>}
   * @memberof IWMSSlotStatusST
   */
  qtys: Array<number>;
  /**
   *
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  query_time: string;
  /**
   * RAW data
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  raw_data: string | null;
  /**
   * An identifier for the given location check in the client system (Optional)
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  client_location_check_id?: string | null;
  /**
   * Exclusion Status of this slot defined in WMS data.
   * @type {string}
   * @memberof IWMSSlotStatusST
   */
  exclusion_status: IWMSSlotStatusSTExclusionStatusEnum;
}

export const IWMSSlotStatusSTStateEnum = {
  Empty: 'EMPTY',
  Barcode: 'BARCODE',
  Invalid: 'INVALID',
} as const;

export type IWMSSlotStatusSTStateEnum =
  typeof IWMSSlotStatusSTStateEnum[keyof typeof IWMSSlotStatusSTStateEnum];
export const IWMSSlotStatusSTSlotUsageEnum = {
  Blocked: 'BLOCKED',
  Normal: 'NORMAL',
  Null: 'null',
} as const;

export type IWMSSlotStatusSTSlotUsageEnum =
  typeof IWMSSlotStatusSTSlotUsageEnum[keyof typeof IWMSSlotStatusSTSlotUsageEnum];
export const IWMSSlotStatusSTExclusionStatusEnum = {
  Include: 'INCLUDE',
  Exclude: 'EXCLUDE',
} as const;

export type IWMSSlotStatusSTExclusionStatusEnum =
  typeof IWMSSlotStatusSTExclusionStatusEnum[keyof typeof IWMSSlotStatusSTExclusionStatusEnum];

/**
 *
 * @export
 * @interface IWarehouseOccupancyGetResponseOccupancyST
 */
export interface IWarehouseOccupancyGetResponseOccupancyST {
  /**
   *
   * @type {number}
   * @memberof IWarehouseOccupancyGetResponseOccupancyST
   */
  locs_blocked: number;
  /**
   *
   * @type {number}
   * @memberof IWarehouseOccupancyGetResponseOccupancyST
   */
  locs_empty: number;
  /**
   *
   * @type {number}
   * @memberof IWarehouseOccupancyGetResponseOccupancyST
   */
  locs_inferred_using_verity_data: number;
  /**
   *
   * @type {number}
   * @memberof IWarehouseOccupancyGetResponseOccupancyST
   */
  locs_not_empty_no_barcode: number;
  /**
   *
   * @type {number}
   * @memberof IWarehouseOccupancyGetResponseOccupancyST
   */
  locs_unknown: number;
  /**
   *
   * @type {number}
   * @memberof IWarehouseOccupancyGetResponseOccupancyST
   */
  locs_with_barcode: number;
  /**
   *
   * @type {number}
   * @memberof IWarehouseOccupancyGetResponseOccupancyST
   */
  total: number;
}
/**
 *
 * @export
 * @interface IWarehouseOccupancyGetResponseST
 */
export interface IWarehouseOccupancyGetResponseST {
  /**
   *
   * @type {IWarehouseOccupancyGetResponseOccupancyST}
   * @memberof IWarehouseOccupancyGetResponseST
   */
  occupancy: IWarehouseOccupancyGetResponseOccupancyST;
}
/**
 *
 * @export
 * @interface IWarehouseStatusDownloadGetResponseST
 */
export interface IWarehouseStatusDownloadGetResponseST {
  /**
   *
   * @type {string}
   * @memberof IWarehouseStatusDownloadGetResponseST
   */
  report_url: string;
}
/**
 *
 * @export
 * @interface IWarehouseStatusGetResponseST
 */
export interface IWarehouseStatusGetResponseST {
  /**
   *
   * @type {{ [key: string]: ILocationDataST; }}
   * @memberof IWarehouseStatusGetResponseST
   */
  locations_data?: { [key: string]: ILocationDataST };
  /**
   *
   * @type {{ [key: string]: Array<IIssueST>; }}
   * @memberof IWarehouseStatusGetResponseST
   */
  issues?: { [key: string]: Array<IIssueST> };
  /**
   *
   * @type {IIssueCountST}
   * @memberof IWarehouseStatusGetResponseST
   */
  issue_count?: IIssueCountST;
}
/**
 *
 * @export
 * @interface IWarehouseStatusSendEmailPutResponseST
 */
export interface IWarehouseStatusSendEmailPutResponseST {
  /**
   *
   * @type {string}
   * @memberof IWarehouseStatusSendEmailPutResponseST
   */
  message: string;
}

/**
 * WarehouseStatusApi - axios parameter creator
 * @export
 */
export const WarehouseStatusApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Returns active issue count.
     * @summary Get active issues.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveIssueCount: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getActiveIssueCount', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/active-issue-count`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns all issues.
     * @summary Get all issues.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllIssues: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getAllIssues', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/issue`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns specific issue.
     * @summary Get specific issue.
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIssue: async (
      systemIdStr: string,
      issueId: string,
      version: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getIssue', 'systemIdStr', systemIdStr);
      // verify required parameter 'issueId' is not null or undefined
      assertParamExists('getIssue', 'issueId', issueId);
      // verify required parameter 'version' is not null or undefined
      assertParamExists('getIssue', 'version', version);
      const localVarPath = `/{system_id_str}/issue/{issue_id}/{version}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'issue_id'}}`, encodeURIComponent(String(issueId)))
        .replace(`{${'version'}}`, encodeURIComponent(String(version)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get detailed information about the exclusion status change.
     * @param {string} systemIdStr
     * @param {string} exclusionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationExclusionStatusHistory: async (
      systemIdStr: string,
      exclusionRequestId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getLocationExclusionStatusHistory', 'systemIdStr', systemIdStr);
      // verify required parameter 'exclusionRequestId' is not null or undefined
      assertParamExists(
        'getLocationExclusionStatusHistory',
        'exclusionRequestId',
        exclusionRequestId,
      );
      const localVarPath = `/{system_id_str}/locations-data/exclusion/{exclusion_request_id}`
        .replace(`{${'system_id_str'}}`, encodeURIComponent(String(systemIdStr)))
        .replace(`{${'exclusion_request_id'}}`, encodeURIComponent(String(exclusionRequestId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns locations data.
     * @summary Get locations data.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationsData: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getLocationsData', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/locations-data`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return locations meta data, location meta data is a light weight collections of location data which could be used in locations table page.
     * @summary Get locations meta data.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationsMetaData: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getLocationsMetaData', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/locations-metadata`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Return warehouse occupancy info.
     * @summary Get warehouse occupancy.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOccupancy: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getOccupancy', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/warehouse/occupancy`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns warehouse status.
     * @summary Get warehouse status.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWarehouseStatus: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('getWarehouseStatus', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/warehouse/status`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Resets the status of the warehouse by deleting the verity slot statuses
     * @summary Resets the status of the warehouse
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetWarehouseStatus: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('resetWarehouseStatus', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/warehouse/status/reset`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send feedback of location data to Verity.
     * @summary Send feedback of location data to Verity.
     * @param {string} systemIdStr
     * @param {ILocationsDataSendFeedbackPostRequestST} iLocationsDataSendFeedbackPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendLocationsDataFeedback: async (
      systemIdStr: string,
      iLocationsDataSendFeedbackPostRequestST: ILocationsDataSendFeedbackPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('sendLocationsDataFeedback', 'systemIdStr', systemIdStr);
      // verify required parameter 'iLocationsDataSendFeedbackPostRequestST' is not null or undefined
      assertParamExists(
        'sendLocationsDataFeedback',
        'iLocationsDataSendFeedbackPostRequestST',
        iLocationsDataSendFeedbackPostRequestST,
      );
      const localVarPath = `/{system_id_str}/locations-data/send-feedback`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iLocationsDataSendFeedbackPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Send warehouse status to email.
     * @summary Send warehouse status to email.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendWarehouseStatusEmail: async (
      systemIdStr: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('sendWarehouseStatusEmail', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/warehouse/status/send-email`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Update locations exclusion status.
     * @param {string} systemIdStr
     * @param {IExclusionStatusPostRequestST} [iExclusionStatusPostRequestST]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLocationsDataExclusionStatus: async (
      systemIdStr: string,
      iExclusionStatusPostRequestST?: IExclusionStatusPostRequestST,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'systemIdStr' is not null or undefined
      assertParamExists('updateLocationsDataExclusionStatus', 'systemIdStr', systemIdStr);
      const localVarPath = `/{system_id_str}/locations-data/exclusion`.replace(
        `{${'system_id_str'}}`,
        encodeURIComponent(String(systemIdStr)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication VerityUserDashboard required
      await setApiKeyToObject(localVarHeaderParameter, 'Authorization', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        iExclusionStatusPostRequestST,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * WarehouseStatusApi - functional programming interface
 * @export
 */
export const WarehouseStatusApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WarehouseStatusApiAxiosParamCreator(configuration);
  return {
    /**
     * Returns active issue count.
     * @summary Get active issues.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActiveIssueCount(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IActiveIssueCountGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveIssueCount(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns all issues.
     * @summary Get all issues.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllIssues(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IIssueGetAllResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllIssues(systemIdStr, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns specific issue.
     * @summary Get specific issue.
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIssue(
      systemIdStr: string,
      issueId: string,
      version: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IIssueGetResponseST>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIssue(
        systemIdStr,
        issueId,
        version,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get detailed information about the exclusion status change.
     * @param {string} systemIdStr
     * @param {string} exclusionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationExclusionStatusHistory(
      systemIdStr: string,
      exclusionRequestId: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExclusionRequestResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationExclusionStatusHistory(
        systemIdStr,
        exclusionRequestId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns locations data.
     * @summary Get locations data.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationsData(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: ILocationDataST }>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationsData(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return locations meta data, location meta data is a light weight collections of location data which could be used in locations table page.
     * @summary Get locations meta data.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLocationsMetaData(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<ILocationsMetaDataGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationsMetaData(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Return warehouse occupancy info.
     * @summary Get warehouse occupancy.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOccupancy(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWarehouseOccupancyGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOccupancy(systemIdStr, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns warehouse status.
     * @summary Get warehouse status.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWarehouseStatus(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IWarehouseStatusGetResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWarehouseStatus(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Resets the status of the warehouse by deleting the verity slot statuses
     * @summary Resets the status of the warehouse
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resetWarehouseStatus(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resetWarehouseStatus(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Send feedback of location data to Verity.
     * @summary Send feedback of location data to Verity.
     * @param {string} systemIdStr
     * @param {ILocationsDataSendFeedbackPostRequestST} iLocationsDataSendFeedbackPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendLocationsDataFeedback(
      systemIdStr: string,
      iLocationsDataSendFeedbackPostRequestST: ILocationsDataSendFeedbackPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendLocationsDataFeedback(
        systemIdStr,
        iLocationsDataSendFeedbackPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Send warehouse status to email.
     * @summary Send warehouse status to email.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async sendWarehouseStatusEmail(
      systemIdStr: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<IWarehouseStatusSendEmailPutResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.sendWarehouseStatusEmail(
        systemIdStr,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Update locations exclusion status.
     * @param {string} systemIdStr
     * @param {IExclusionStatusPostRequestST} [iExclusionStatusPostRequestST]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateLocationsDataExclusionStatus(
      systemIdStr: string,
      iExclusionStatusPostRequestST?: IExclusionStatusPostRequestST,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<IExclusionRequestResponseST>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateLocationsDataExclusionStatus(
        systemIdStr,
        iExclusionStatusPostRequestST,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * WarehouseStatusApi - factory interface
 * @export
 */
export const WarehouseStatusApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = WarehouseStatusApiFp(configuration);
  return {
    /**
     * Returns active issue count.
     * @summary Get active issues.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveIssueCount(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IActiveIssueCountGetResponseST> {
      return localVarFp
        .getActiveIssueCount(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns all issues.
     * @summary Get all issues.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllIssues(systemIdStr: string, options?: any): AxiosPromise<IIssueGetAllResponseST> {
      return localVarFp
        .getAllIssues(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns specific issue.
     * @summary Get specific issue.
     * @param {string} systemIdStr
     * @param {string} issueId
     * @param {string} version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIssue(
      systemIdStr: string,
      issueId: string,
      version: string,
      options?: any,
    ): AxiosPromise<IIssueGetResponseST> {
      return localVarFp
        .getIssue(systemIdStr, issueId, version, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get detailed information about the exclusion status change.
     * @param {string} systemIdStr
     * @param {string} exclusionRequestId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationExclusionStatusHistory(
      systemIdStr: string,
      exclusionRequestId: string,
      options?: any,
    ): AxiosPromise<IExclusionRequestResponseST> {
      return localVarFp
        .getLocationExclusionStatusHistory(systemIdStr, exclusionRequestId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns locations data.
     * @summary Get locations data.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationsData(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<{ [key: string]: ILocationDataST }> {
      return localVarFp
        .getLocationsData(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return locations meta data, location meta data is a light weight collections of location data which could be used in locations table page.
     * @summary Get locations meta data.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLocationsMetaData(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<ILocationsMetaDataGetResponseST> {
      return localVarFp
        .getLocationsMetaData(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Return warehouse occupancy info.
     * @summary Get warehouse occupancy.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOccupancy(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IWarehouseOccupancyGetResponseST> {
      return localVarFp
        .getOccupancy(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns warehouse status.
     * @summary Get warehouse status.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWarehouseStatus(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IWarehouseStatusGetResponseST> {
      return localVarFp
        .getWarehouseStatus(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Resets the status of the warehouse by deleting the verity slot statuses
     * @summary Resets the status of the warehouse
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resetWarehouseStatus(systemIdStr: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .resetWarehouseStatus(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send feedback of location data to Verity.
     * @summary Send feedback of location data to Verity.
     * @param {string} systemIdStr
     * @param {ILocationsDataSendFeedbackPostRequestST} iLocationsDataSendFeedbackPostRequestST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendLocationsDataFeedback(
      systemIdStr: string,
      iLocationsDataSendFeedbackPostRequestST: ILocationsDataSendFeedbackPostRequestST,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .sendLocationsDataFeedback(systemIdStr, iLocationsDataSendFeedbackPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Send warehouse status to email.
     * @summary Send warehouse status to email.
     * @param {string} systemIdStr
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    sendWarehouseStatusEmail(
      systemIdStr: string,
      options?: any,
    ): AxiosPromise<IWarehouseStatusSendEmailPutResponseST> {
      return localVarFp
        .sendWarehouseStatusEmail(systemIdStr, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Update locations exclusion status.
     * @param {string} systemIdStr
     * @param {IExclusionStatusPostRequestST} [iExclusionStatusPostRequestST]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateLocationsDataExclusionStatus(
      systemIdStr: string,
      iExclusionStatusPostRequestST?: IExclusionStatusPostRequestST,
      options?: any,
    ): AxiosPromise<IExclusionRequestResponseST> {
      return localVarFp
        .updateLocationsDataExclusionStatus(systemIdStr, iExclusionStatusPostRequestST, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * WarehouseStatusApi - object-oriented interface
 * @export
 * @class WarehouseStatusApi
 * @extends {BaseAPI}
 */
export class WarehouseStatusApi extends BaseAPI {
  /**
   * Returns active issue count.
   * @summary Get active issues.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public getActiveIssueCount(systemIdStr: string, options?: AxiosRequestConfig) {
    return WarehouseStatusApiFp(this.configuration)
      .getActiveIssueCount(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns all issues.
   * @summary Get all issues.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public getAllIssues(systemIdStr: string, options?: AxiosRequestConfig) {
    return WarehouseStatusApiFp(this.configuration)
      .getAllIssues(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns specific issue.
   * @summary Get specific issue.
   * @param {string} systemIdStr
   * @param {string} issueId
   * @param {string} version
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public getIssue(
    systemIdStr: string,
    issueId: string,
    version: string,
    options?: AxiosRequestConfig,
  ) {
    return WarehouseStatusApiFp(this.configuration)
      .getIssue(systemIdStr, issueId, version, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get detailed information about the exclusion status change.
   * @param {string} systemIdStr
   * @param {string} exclusionRequestId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public getLocationExclusionStatusHistory(
    systemIdStr: string,
    exclusionRequestId: string,
    options?: AxiosRequestConfig,
  ) {
    return WarehouseStatusApiFp(this.configuration)
      .getLocationExclusionStatusHistory(systemIdStr, exclusionRequestId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns locations data.
   * @summary Get locations data.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public getLocationsData(systemIdStr: string, options?: AxiosRequestConfig) {
    return WarehouseStatusApiFp(this.configuration)
      .getLocationsData(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return locations meta data, location meta data is a light weight collections of location data which could be used in locations table page.
   * @summary Get locations meta data.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public getLocationsMetaData(systemIdStr: string, options?: AxiosRequestConfig) {
    return WarehouseStatusApiFp(this.configuration)
      .getLocationsMetaData(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Return warehouse occupancy info.
   * @summary Get warehouse occupancy.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public getOccupancy(systemIdStr: string, options?: AxiosRequestConfig) {
    return WarehouseStatusApiFp(this.configuration)
      .getOccupancy(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns warehouse status.
   * @summary Get warehouse status.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public getWarehouseStatus(systemIdStr: string, options?: AxiosRequestConfig) {
    return WarehouseStatusApiFp(this.configuration)
      .getWarehouseStatus(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Resets the status of the warehouse by deleting the verity slot statuses
   * @summary Resets the status of the warehouse
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public resetWarehouseStatus(systemIdStr: string, options?: AxiosRequestConfig) {
    return WarehouseStatusApiFp(this.configuration)
      .resetWarehouseStatus(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send feedback of location data to Verity.
   * @summary Send feedback of location data to Verity.
   * @param {string} systemIdStr
   * @param {ILocationsDataSendFeedbackPostRequestST} iLocationsDataSendFeedbackPostRequestST
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public sendLocationsDataFeedback(
    systemIdStr: string,
    iLocationsDataSendFeedbackPostRequestST: ILocationsDataSendFeedbackPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return WarehouseStatusApiFp(this.configuration)
      .sendLocationsDataFeedback(systemIdStr, iLocationsDataSendFeedbackPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Send warehouse status to email.
   * @summary Send warehouse status to email.
   * @param {string} systemIdStr
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public sendWarehouseStatusEmail(systemIdStr: string, options?: AxiosRequestConfig) {
    return WarehouseStatusApiFp(this.configuration)
      .sendWarehouseStatusEmail(systemIdStr, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Update locations exclusion status.
   * @param {string} systemIdStr
   * @param {IExclusionStatusPostRequestST} [iExclusionStatusPostRequestST]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WarehouseStatusApi
   */
  public updateLocationsDataExclusionStatus(
    systemIdStr: string,
    iExclusionStatusPostRequestST?: IExclusionStatusPostRequestST,
    options?: AxiosRequestConfig,
  ) {
    return WarehouseStatusApiFp(this.configuration)
      .updateLocationsDataExclusionStatus(systemIdStr, iExclusionStatusPostRequestST, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
