import { Paper, Typography } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import Spinner from 'components/common/Spinner';
import { Box } from 'components/common/Box';
import IssuesServices from 'services/IssuesServices';
import { VERITY_STATES } from 'common/slotStates';
import { getImage } from 'common/functions/imageFunctions';
import { MutationNames } from 'ts-types/MutationNames';
import { useEffect } from 'react';
import { locationStyles } from '../styles';
import { ImageGridComponent } from './ImageGridComponent';
import { PictureCounter } from './PictureCounter';
import { getImageText } from './utils/getImageText';
import { QueryNames } from '../../../../ts-types/QueryNames';

export const ImageGrid = (props: {
  verityState: '-' | VERITY_STATES;
  currentLocationData: ILocationData;
  systemId: string;
  imageStatusUpdate: any;
}) => {
  const { systemId, verityState, currentLocationData, imageStatusUpdate } = props;
  const { location, slotStatusVersion, isReview } = currentLocationData;

  const { classes } = locationStyles();

  const {
    isLoading: isLoadingImages,
    data = { urls: [], num_requested: 0 },
    refetch,
  } = useQuery({
    queryKey: [QueryNames.LOCATION_MODAL_GET_IMAGES, location],
    queryFn: () => IssuesServices.getImages(systemId, location, slotStatusVersion, isReview),
    select: ({ data }) => data,
    enabled: slotStatusVersion !== '-',
  });

  useEffect(() => {
    if (imageStatusUpdate || currentLocationData) {
      refetch();
    }
  }, [imageStatusUpdate, currentLocationData, refetch]);

  const { mutate: generateAndDownloadZip, isPending: imagesDownloading } = useMutation({
    mutationKey: [MutationNames.DOWNLOAD_IMAGES, location],
    mutationFn: async () => {
      const zip = new JSZip();

      const getImagePromises = data.urls.map((url: string, index: number) => {
        const imageName = `${location}-img-${index + 1}.png`;
        return getImage(url).then((imageData: any) => zip.file(imageName, imageData));
      });

      await Promise.all(getImagePromises);
      const content = await zip.generateAsync({ type: 'blob' });
      const zipFolderName = `${location}-images.zip`;
      saveAs(content, zipFolderName);
    },
  });

  return data.urls.length ? (
    <div>
      {!isLoadingImages ? (
        <>
          <PictureCounter
            imageUrls={data.urls}
            imagesDownloading={imagesDownloading}
            handleDownloadImages={generateAndDownloadZip}
          />
          <ImageGridComponent imageUrls={data.urls} />
        </>
      ) : (
        <Box className={classes.spinnerLoadingImages}>
          <Spinner />
        </Box>
      )}
    </div>
  ) : (
    <Paper className={classes.paper} elevation={0}>
      <div className={classes.imageContainer}>
        <Typography
          className={classes.imageContainerText}
          color="textSecondary"
          align="center"
          variant="h6"
        >
          {getImageText(verityState, data.urls.length, data.num_requested)}
        </Typography>
      </div>
    </Paper>
  );
};
