import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { FacilitySettings } from 'store/FacilityLevelStore/IFacilityLevelStore';

export const isAmendForumShown = (facilitySettings: FacilitySettings) =>
  facilitySettings.allow_user_amend || false;

export const isShareFeedbackOnLocationShown = (facilitySettings: FacilitySettings) =>
  facilitySettings.support_email_recipients !== '' &&
  userHasPermission(PERMISSION.SHARE_FEEDBACK_ON_LOCATION);

export const isFeedbackShown = (facilitySettings: FacilitySettings) =>
  isAmendForumShown(facilitySettings) || isShareFeedbackOnLocationShown(facilitySettings);
