import { useEffect } from 'react';
import { pickSort } from 'common/functions/otherFunctions';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { BackDropLoader } from 'components/BackDropLoader';
import { controlledZoneListStyles } from './controlledZonesList.styles';
import { ZonesListProps } from '../ZonesList.model';
import { ControlledZonesListItem } from './components/ControlledZonesListItem/ControlledZonesListItem';
import { DroneZonePlaceholder } from '../DroneZonePlaceholder/DroneZonePlaceholder';
import { zoneElementFromSelectedZone } from '../helpers/zoneElementFromSelectedZone';

export const ControlledZonesList = ({
  zones = {},
  systemId,
  flightDomainId,
  isLoading,
  onSelect,
}: ZonesListProps<ControlledZones, ControlledZone>) => {
  const { classes, cx } = controlledZoneListStyles();
  const focusedZoneElement = zoneElementFromSelectedZone(zones);
  const { stateFacilityLevel } = useFacilityLevelStore();

  const isCoexistenceWmsIntegrationEnabled =
    stateFacilityLevel.facilitySettings.enable_coexistence_wms_integration;

  useEffect(() => {
    focusedZoneElement?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [focusedZoneElement]);

  const sortedZones = pickSort(
    Object.values(zones).map((i) => i),
    'asc',
    (o) => o.name.toLowerCase(),
  );

  const isListVisible = !isLoading && !!sortedZones.length;
  const isPlaceholderVisible = !isLoading && !sortedZones.length;
  const isOverlayVisible = isLoading;

  return (
    <div className={classes.wrapper}>
      <BackDropLoader isVisible={isOverlayVisible} showLoader={isLoading} />

      {isListVisible && (
        <ul className={cx(classes.wrapperBody, classes.zoneListWrapper)}>
          {sortedZones.map((zone) => (
            <ControlledZonesListItem
              key={zone.id}
              droneZone={zone}
              systemId={systemId}
              flightDomainId={flightDomainId}
              onSelect={onSelect}
              isCoexistenceWmsIntegrationEnabled={isCoexistenceWmsIntegrationEnabled}
            />
          ))}
        </ul>
      )}

      {isPlaceholderVisible && (
        <div className={classes.wrapperBody}>
          <DroneZonePlaceholder message='There are no "controlled zones"' />
        </div>
      )}
    </div>
  );
};
