import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Tooltip, Typography } from '@mui/material';
import { ILocationDataST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import {
  ILocationDataForReviewSlotSettingsSTExclusionStatusEnum,
  IWMSSlotStatusSTExclusionStatusEnum,
} from 'codegen/report';

type ContentCellProps = {
  row: ILocationDataST;
};

const EXCLUSION_ICONS = {
  INCLUDE: <CheckIcon />,
  EXCLUDE: <ClearIcon />,
  NOT_SET: <>-</>,
} as const;

const getCellContent = ({
  exclusionStatusWMS,
  exclusionStatusVerity,
}: {
  exclusionStatusWMS: IWMSSlotStatusSTExclusionStatusEnum | undefined;
  exclusionStatusVerity: ILocationDataForReviewSlotSettingsSTExclusionStatusEnum | undefined;
}): {
  content: typeof EXCLUSION_ICONS[keyof typeof EXCLUSION_ICONS];
  tooltip: string;
} => {
  if (!exclusionStatusWMS) {
    return {
      content: EXCLUSION_ICONS.NOT_SET,
      tooltip: 'There is no WMS data available for this location',
    };
  }

  if (exclusionStatusVerity === 'NOT_SET') {
    const tooltipMessage =
      exclusionStatusWMS === 'EXCLUDE'
        ? 'Drones will not visit this location (WMS)'
        : 'Drones will visit this location (WMS)';

    return {
      content: EXCLUSION_ICONS[exclusionStatusWMS],
      tooltip: tooltipMessage,
    };
  }

  const tooltipMessage =
    exclusionStatusVerity === 'EXCLUDE'
      ? 'Drones will not visit this location (User)'
      : 'Drones will visit this location (User)';

  return {
    content: EXCLUSION_ICONS[exclusionStatusVerity ?? 'NOT_SET'],
    tooltip: tooltipMessage,
  };
};

export const ExclusionStatusCell = ({ row }: ContentCellProps) => {
  const wmsStatus = row.wms_status as IWMSSlotStatusST | undefined;

  const exclusionStatusWMS = wmsStatus?.exclusion_status;
  const exclusionStatusVerity = row.slot_settings?.exclusion_status;

  const { content, tooltip } = getCellContent({ exclusionStatusWMS, exclusionStatusVerity });

  return (
    <Tooltip title={tooltip} className="c-data-grid-cell c-data-grid-cell-exclusion-status">
      <Typography
        variant="body2"
        component="span"
        sx={{ width: '100%', textAlign: 'center', cursor: 'default' }}
      >
        {content}
      </Typography>
    </Tooltip>
  );
};
