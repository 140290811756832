import WarehouseIcon from '@mui/icons-material/Warehouse';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { IconButton } from '@mui/material';
import DroneIcon from 'components/common/FacilityMenu/Icons/DroneIcon';
import { TABLE_DATETIME_FORMAT } from 'common/datetimeFormats';
import { formatDateInTimezone } from 'common/functions/dateTimeFunctions';
import { LocationHistoryItem } from './transformLocationHistory';

export const getTimeLineIcon = (item: LocationHistoryItem, lastItem: boolean): JSX.Element => {
  switch (item.type) {
    case 'WMS_UPDATE':
      return (
        <IconButton
          onClick={() => {
            const date = formatDateInTimezone(item.updated_at, TABLE_DATETIME_FORMAT);
            const wmsUploadUrl = `warehouse-data-uploads?loadSince=all&searchTerm=${encodeURIComponent(
              date.toLowerCase(),
            )}`;
            window.open(wmsUploadUrl, '_blank');
          }}
          sx={{ p: 0 }}
        >
          <WarehouseIcon fontSize="small" sx={{ color: '#424242' }} />
        </IconButton>
      );

    case 'DRONE_REPORT':
      return <DroneIcon fontSize="20px" color="#424242" />;

    case 'EXCLUSION_STATUS_UPDATE':
      return item.exclusion_status === 'EXCLUDE' ? (
        <LocationOffIcon fontSize="small" sx={{ color: '#424242' }} />
      ) : (
        <LocationOnIcon fontSize="small" sx={{ color: '#424242' }} />
      );

    default:
      return <> </>;
  }
};
