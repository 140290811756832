import {
  ILocationHistoryResponseST,
  ISlotSettingsST,
  IWMSSlotStatusST,
  IVeritySlotStatusST,
  ISlotSettingsSTExclusionStatusEnum,
  IVeritySlotStatusStateST,
  IWMSSlotStatusSTStateEnum,
} from 'codegen/location_information';
import { LocationHistoryEventTypes } from '../models/LocationHistoryEventTypes.model';

export type LocationHistoryItem = {
  type: LocationHistoryEventTypes;
  updated_at: string;
  exclusion_status: ISlotSettingsSTExclusionStatusEnum | null;
  state: IVeritySlotStatusStateST | IWMSSlotStatusSTStateEnum | null;
  barcodes: Array<string>;
};

export const transformLocationHistory = (
  locationHistory: ILocationHistoryResponseST,
  numberOfItems?: number,
): LocationHistoryItem[] => {
  const { vs_history, wms_history, ss_history } = locationHistory;

  const flattenedList: LocationHistoryItem[] = [
    ...vs_history.map((item: IVeritySlotStatusST) => ({
      type: LocationHistoryEventTypes.DRONE_REPORT,
      state: item.state,
      barcodes: item.barcodes,
      exclusion_status: null,
      updated_at: item.updated_at,
    })),
    ...wms_history.map((item: IWMSSlotStatusST) => ({
      type: LocationHistoryEventTypes.WMS_UPDATE,
      state: item.state,
      barcodes: item.barcodes,
      exclusion_status: null,
      updated_at: item.updated_at,
    })),
    ...ss_history.map((item: ISlotSettingsST) => ({
      type: LocationHistoryEventTypes.EXCLUSION_STATUS_UPDATE,
      state: null,
      barcodes: [],
      exclusion_status: item.exclusion_status,
      updated_at: item.updated_at ?? '',
    })),
  ];

  flattenedList.sort((a, b) => new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime());

  if (numberOfItems) {
    return flattenedList.slice(0, numberOfItems);
  }

  return flattenedList;
};
