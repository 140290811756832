import { ReactElement } from 'react';
import { requestQueueHandler } from 'common/requestHelpers';
import { NETWORK_REQUEST_SETTINGS } from 'common/settings';
import { HeadCellProp } from 'components/EnhancedTable/types/cell';
import { IRequestController, ReservedSlot } from '../../hooks';
import { IOverwriteData } from '../../store/Modals/facilityModals/IFacilityModalsStore';
import WarehouseStore from '../../store/WarehouseStore';

/**
 * Default occupancy data
 */
export const defaultOccupancyData = {
  id: 'warehouse-occupancy',
  title: 'Occupancy summary',
  subtitle: 'An overview of the current occupancy status.',
  counter: 0,
  counterSubject: 'locations',
  actionButtons: [],
  sections: [],
};

/**
 * Default warehouse status
 */
export const defaultWarehouseStatus = {
  id: 'warehouse-status',
  title: 'Warehouse status',
  subtitle: 'An overview of the current issues in the warehouse.',
  counter: 0,
  counterSubject: 'issues',
  actionButtons: [],
  sections: [],
};

/**
 * Current tab default
 */
const currentTabDefault = {
  tableFor: '',
  tableTitle: '',
  tableSubtitle: '',
  headCells: [],
  rows: [],
  redirectTo: '',
};

/**
 * Section interface
 */
export interface Section {
  id: string;
  title: string;
  sectionItems: SectionItem[];
}

/**
 * Section item interface
 */
export interface SectionItem {
  id: string;
  label: string;
  value: string;
  divider?: boolean;
}

/**
 * Get tab data interface
 */
interface IGetTabData {
  activeTab: number;
  issuesTab: number;
  issuesSolvedTab: number;
  amendedTab: number;
  issuesSnoozedTab: number;
  issuesPotentialTab: number;
  issuesInconclusiveTab: number;
  issuesInvalidTab: number;
  fullReportTab: number;
  issues: ILocationReportData[];
  solved: ILocationReportData[];
  fullReport: ILocationReportData[];
  amended: ILocationReportData[];
  snoozed: ILocationReportData[];
  invalid: ILocationReportData[];
  inconclusive: ILocationReportData[];
  potential: ILocationReportData[];
  WHS_ISSUES_COLUMNS: HeadCellProp[];
  WHS_SOLVED_COLUMNS: HeadCellProp[];
  WHS_FULL_REPORT_COLUMNS: HeadCellProp[];
  WHS_AMENDED_COLUMNS: HeadCellProp[];
  WHS_INVALID_COLUMNS: HeadCellProp[];
  WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS: HeadCellProp[];
}

/**
 * Get tab data
 * @param param0 IGetTabData params
 * @returns active tab data
 */
export const getTabData = ({
  activeTab,
  issuesTab,
  issuesSolvedTab,
  amendedTab,
  issuesSnoozedTab,
  issuesPotentialTab,
  issuesInconclusiveTab,
  issuesInvalidTab,
  fullReportTab,
  issues,
  solved,
  fullReport,
  amended,
  snoozed,
  invalid,
  inconclusive,
  potential,
  WHS_ISSUES_COLUMNS,
  WHS_SOLVED_COLUMNS,
  WHS_FULL_REPORT_COLUMNS,
  WHS_AMENDED_COLUMNS,
  WHS_INVALID_COLUMNS,
  WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
}: IGetTabData) => {
  const tabData = {
    [issuesTab]: {
      tableFor: 'issues',
      tableTitle: 'Issues',
      tableSubtitle: 'Current issues in the warehouse.',
      headCells: WHS_ISSUES_COLUMNS,
      rows: issues,
      redirectTo: 'locationModal',
    },
    [issuesSolvedTab]: {
      tableFor: 'solved issues',
      tableTitle: 'Solved issues',
      tableSubtitle:
        'Issues solved in the past 24 hours. The data shown reflects the content of the issue.',
      headCells: WHS_SOLVED_COLUMNS,
      rows: solved,
      redirectTo: 'locationModal',
    },
    [amendedTab]: {
      tableFor: 'amended',
      tableTitle: 'Amended',
      tableSubtitle: 'Locations where the verity data was amended by the user.',
      headCells: WHS_AMENDED_COLUMNS,
      rows: amended,
      redirectTo: 'locationModal',
    },
    [issuesSnoozedTab]: {
      tableFor: 'snoozed issues',
      tableTitle: 'Snoozed issues',
      tableSubtitle:
        'Issues that have been snoozed. They will be ignored until they are either solved or unsnoozed.',
      headCells: WHS_ISSUES_COLUMNS,
      rows: snoozed,
      redirectTo: 'locationModal',
    },
    [issuesInvalidTab]: {
      tableFor: 'invalid issues',
      tableTitle: 'Invalid issues',
      tableSubtitle: 'Locations for which the WMS data uploaded is invalid.',
      headCells: WHS_INVALID_COLUMNS,
      rows: invalid,
      redirectTo: 'locationModal',
    },
    [issuesInconclusiveTab]: {
      tableFor: 'inconclusive issues',
      tableTitle: 'Inconclusive issues',
      tableSubtitle: 'Locations for which Verity data is inconclusive.',
      headCells: WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
      rows: inconclusive,
      redirectTo: 'locationModal',
    },
    [issuesPotentialTab]: {
      tableFor: 'potential issues',
      tableTitle: 'Potential issues',
      tableSubtitle:
        'A barcode was expected, but Verity found a non-empty location with no barcode.',
      headCells: WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS,
      rows: potential,
      redirectTo: 'locationModal',
    },
    [fullReportTab]: {
      tableFor: 'report',
      tableTitle: 'Full report',
      tableSubtitle: 'Details about all the locations covered by the system.',
      headCells: WHS_FULL_REPORT_COLUMNS,
      rows: fullReport,
      redirectTo: 'locationModal',
    },
  };

  return tabData[activeTab] || currentTabDefault;
};

export interface ILocationReportData {
  actions: { data: IOverwriteData; actions: SectionItem[] };
  allowRedirection: boolean;
  barcodeExpectedFoundOn: string;
  barcodeFoundShouldBeOn: string;
  colorizeContentExpected: boolean;
  colorizeContentFound: boolean;
  contentExpected: string[];
  contentFound: string[];
  contentFoundChangedByTheClient: boolean;
  contentFoundDate: string;
  id: string;
  image: ReactElement;
  issue: string;
  issueFirstFoundOn: string;
  issueId: string;
  issueStatus: string;
  issueType: string;
  location: string;
  locationIssueType: string;
  matchArray: boolean[];
  slotUsage: string;
  userName: string;
  userValue: [];
  version: string;
  verityDate: string;
  verityValue: string[];
  wmsArticleNo: string[];
  wmsCustomer: string[];
  wmsDate: string;
  wmsQty: number[];
  wmsSlotStatusVersion: number;
  wmsValue: string[];
}

interface ILocationData {
  issues: ILocationReportData[];
  solved: ILocationReportData[];
  snoozed: ILocationReportData[];
  potential: ILocationReportData[];
  inconclusive: ILocationReportData[];
  invalid: ILocationReportData[];
  fullReport: ILocationReportData[];
  amended: ILocationReportData[];
}

/**
 * Load warehouse data interface
 */
interface ILoadWarehouseData {
  slots: string[];
  logPrefix: string;
  requestController: IRequestController;
  systemId: string;
  clearLocalState: () => void;
  setIssues: (params: (prevState: ILocationReportData[]) => ILocationReportData[]) => void;
  setSolved: (params: (prevState: ILocationReportData[]) => ILocationReportData[]) => void;
  setSnoozed: (params: (prevState: ILocationReportData[]) => ILocationReportData[]) => void;
  setPotential: (params: (prevState: ILocationReportData[]) => ILocationReportData[]) => void;
  setInconclusive: (params: (prevState: ILocationReportData[]) => ILocationReportData[]) => void;
  setInvalid: (params: (prevState: ILocationReportData[]) => ILocationReportData[]) => void;
  setFullReport: (params: (prevState: ILocationReportData[]) => ILocationReportData[]) => void;
  setAmended: (params: (prevState: ILocationReportData[]) => ILocationReportData[]) => void;
  onGeneratedRequestID: (requestID: string) => void;
}

/**
 * Load warehouse data
 * @param param0 ILoadWarehouseData params
 */
export const loadWarehouseData = ({
  slots,
  logPrefix,
  requestController,
  systemId,
  clearLocalState,
  setIssues,
  setSolved,
  setSnoozed,
  setPotential,
  setInconclusive,
  setInvalid,
  setFullReport,
  setAmended,
  onGeneratedRequestID,
}: ILoadWarehouseData) => {
  clearLocalState();
  // Sequentially get location data
  const requestPageSize = NETWORK_REQUEST_SETTINGS.WAREHOUSE_PAGE_SIZE_FOR_FULL_REPORT_TAB;

  const allRequests = [];

  const pageCount = Math.ceil(slots && slots.length / requestPageSize);
  for (let i = 0; i < pageCount; i += 1) {
    console.debug(
      logPrefix,
      `loadFullReportAndAmendedLocations for systemId: ${systemId}, slot: ${i * requestPageSize}`,
    );
    const reservation: ReservedSlot = requestController.reserveSlotForRequest();
    onGeneratedRequestID(reservation.requestId);
    const networkRequest = {
      requestId: reservation.requestId,
      request: () =>
        requestController.doRequest(
          {
            request: WarehouseStore.loadFullReportAndAmendedLocations,
            requestParams: [
              systemId,
              slots[i * requestPageSize],
              requestPageSize,
              reservation.signal,
            ],
            messageErrorFallback: 'The Full Report and Amended Locations could not be loaded.',
            callbackSuccess: ({
              issues,
              solved,
              snoozed,
              potential,
              inconclusive,
              invalid,
              fullReport,
              amended,
            }: ILocationData) => {
              console.debug(
                logPrefix,
                `loadFullReportAndAmendedLocations callback for systemId: ${systemId}, slot: ${
                  i * requestPageSize
                }`,
              );
              setIssues((prevState: ILocationReportData[]) => [...prevState, ...issues]);
              setSolved((prevState: ILocationReportData[]) => [...prevState, ...solved]);
              setSnoozed((prevState: ILocationReportData[]) => [...prevState, ...snoozed]);
              setPotential((prevState: ILocationReportData[]) => [...prevState, ...potential]);
              setInconclusive((prevState: ILocationReportData[]) => [
                ...prevState,
                ...inconclusive,
              ]);
              setInvalid((prevState: ILocationReportData[]) => [...prevState, ...invalid]);
              setFullReport((prevState: ILocationReportData[]) => [...prevState, ...fullReport]);
              setAmended((prevState: ILocationReportData[]) => [...prevState, ...amended]);
            },
            // Since this request is called multiple times, we can not use the dispatcher
            // to display snackbars for messageSuccess, messageError, messageFinally
            // so we are using the callback parameters to do so.
            // TR::TODO::2021-04-26:: Allow generic request handler to display
            // snackbars also for looped requests
          },
          reservation.requestId,
        ),
    };

    allRequests.push(networkRequest);
  }

  requestQueueHandler(
    allRequests,
    NETWORK_REQUEST_SETTINGS.MAX_CONCURRENT_REQUESTS,
    requestController,
  );
};
