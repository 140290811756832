import { isArray } from 'lodash';
import { Box, Typography, IconButton, Tooltip, useTheme } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import Badge from 'components/common/Badge';
import { ISSUE_BADGE_COLORS, ISSUE_BADGE_COLORS_KEYS } from 'common/colors';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { DISPLAY_ISSUE_TYPES } from 'common/issueTypesAndStates';
import { getIssueType, muiColorToTheme } from 'common/functions/issues/issueColorFunctions';
import { NavigationButtons } from '../navigation/NavigationButtons';
import { locationStyles } from '../styles';
import { SnoozeControl } from './SnoozeControl';

type LocationModalHeaderProps = {
  locationData: ILocationData;
  allLocations: ILocationData[];
  currentLocationData: any;
  setCurrentLocationData: React.Dispatch<any>;
  refreshLocationData: (locationLabel: string) => void;
  currentLocationIndex: number;
  setCurrentLocationIndex: React.Dispatch<React.SetStateAction<number>>;
  parentPage: 'WarehouseStatus' | 'Report';
};

export const LocationModalHeader = ({
  locationData,
  allLocations,
  currentLocationData,
  setCurrentLocationData,
  refreshLocationData,
  currentLocationIndex,
  setCurrentLocationIndex,
  parentPage,
}: LocationModalHeaderProps) => {
  const { classes } = locationStyles();
  const theme = useTheme();

  const showNavigation = isArray(allLocations) && allLocations.length > 1;

  const title = locationData.location;
  const issue = DISPLAY_ISSUE_TYPES[locationData.issueType]
    ? DISPLAY_ISSUE_TYPES[locationData.issueType]
    : '';
  const issueType = getIssueType(locationData.issueType);

  return (
    <Box component="div" textAlign="left" p={1} paddingTop="6px" paddingBottom="6px">
      <Box component="div" display="flex" justifyContent="space-between">
        <Box component="div" display="flex" alignItems="center" gap={2}>
          <Typography
            className={classes.modalTitle}
            color="secondary"
            variant="h5"
            data-testid="title"
            minWidth="90px"
          >
            {title}
          </Typography>
          {issue && (
            <Badge
              bgcolor={muiColorToTheme(issueType.color, theme).main}
              color={muiColorToTheme(issueType.color, theme).text}
              bigBadge={false}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              <Typography variant="body1">{issue}</Typography>
            </Badge>
          )}
          <Badge
            bgcolor={
              ISSUE_BADGE_COLORS[(currentLocationData?.state as ISSUE_BADGE_COLORS_KEYS) || 'NONE']
                ?.background
            }
            color={
              ISSUE_BADGE_COLORS[(currentLocationData?.state as ISSUE_BADGE_COLORS_KEYS) || 'NONE']
                ?.text
            }
            bigBadge={false}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Typography variant="body1">
              {(currentLocationData?.state && `${currentLocationData.state} ISSUE`) || 'NO ISSUE'}
            </Typography>
          </Badge>

          <SnoozeControl
            locationData={locationData}
            parentPage={parentPage}
            refreshLocationData={refreshLocationData}
          />
        </Box>
        <Box
          component="div"
          display="flex"
          paddingRight="6em"
          marginTop="-3px"
          alignItems="center"
          gap={3}
        >
          <IconButton
            aria-label="refresh"
            onClick={() => refreshLocationData(locationData?.location)}
            size="large"
          >
            <Tooltip title="Refresh">
              <RefreshIcon />
            </Tooltip>
          </IconButton>
          {showNavigation && (
            <NavigationButtons
              locationsData={allLocations}
              setCurrentLocationData={setCurrentLocationData}
              currentLocationIndex={currentLocationIndex}
              setCurrentLocationIndex={setCurrentLocationIndex}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
