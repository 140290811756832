import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { cleanGridState } from './cleanGridState.util';

export const handleGridStateChange = ({
  gridApiRef,
  searchParams,
  setSearchParams,
}: {
  gridApiRef: React.MutableRefObject<GridApiPremium>;
  searchParams: URLSearchParams;
  setSearchParams: (URLSearchParams: URLSearchParams) => void;
}) => {
  if (gridApiRef?.current?.exportState) {
    let currentState = gridApiRef.current.exportState();
    currentState = cleanGridState(currentState);
    if (!Object.keys(currentState).length) {
      return; // The object is empty so, let's not add it to the URL
    }
    const serializedState = JSON.stringify(currentState);
    const urlState = searchParams.get('dataGridState');
    if (serializedState !== urlState) {
      searchParams.set('dataGridState', serializedState);
      setSearchParams(searchParams);
    }
  }
};
