import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsItem } from '../types';

export const getIssuesOptions = (
  facilitySettings: IFacilitySettingsST,
  handleValuesChange: (prop: string, value: any) => void,
  bcMatchLogics: { label: string; value: string }[],
  issueLogicFilters: { label: string; value: string }[],
): SettingsItem[] => [
  {
    type: 'switch',
    label: 'Allow client amend',
    testId: 'c-allow-client-amend',
    description: 'If enabled, the user can amend results collected by Verity.',
    value: facilitySettings.allow_user_amend,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('allow_user_amend', e.target.checked),
  },
  {
    type: 'select',
    label: 'Barcode match logic',
    selectOptions: bcMatchLogics,
    testId: 'c-barcode-match-logic',
    description: 'The name of the handler that decides if Verity and WMS barcodes match.',
    value: facilitySettings.barcode_match_logic_name,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('barcode_match_logic_name', e.target.value),
  },
  {
    type: 'multi-select',
    label: 'Issue logic filters',
    selectOptions: issueLogicFilters,
    testId: 'c-issue-logic-filter',
    description:
      'Names of handlers that modify the issue logics. Handlers will be applied in list order.',
    values: facilitySettings.issue_logic_filter_names,
    action: (e: React.ChangeEvent<HTMLInputElement>) =>
      handleValuesChange('issue_logic_filter_names', e.target.value),
    clearAction: () => handleValuesChange('issue_logic_filter_names', []),
  },
  {
    type: 'json-textarea',
    label: 'Custom Issue Logic rules',
    testId: 'c-issue-logic-rules',
    description:
      'Define the custom issue logic rules. Please copy/paste your rules definition into the text area.',
    values: facilitySettings.issue_logic_rules,
    action: (jsonRules: any) => handleValuesChange('issue_logic_rules', jsonRules),
  },
];
