import { useQuery } from '@tanstack/react-query';
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';
import BaseCard from 'components/BaseCard/BaseCard';
import { Box } from 'components/common/Box';
import { getLocationHistory } from './api/getLocationHistory';
import { QueryNames } from '../../../../ts-types/QueryNames';
import { CustomTimeLineItem } from './CustomTimeLineItem';
import { transformLocationHistory } from './utils/transformLocationHistory';

const useStyles = makeStyles()((theme: Theme) => ({
  timelineDiv: {
    maxHeight: '400px',
    overflowY: 'auto',
    padding: 0,
    paddingTop: 10,

    '&::-webkit-scrollbar': {
      height: 9,
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BDBDBD',
      borderRadius: 200,
    },
  },

  timeline: {
    [`& .${timelineItemClasses.root}:before`]: {
      flex: '0 1 auto',
      padding: 0,
    },
  },
}));

export const LocationHistory = (props: { slotLabel: string; systemId: string }) => {
  const { systemId, slotLabel } = props;

  const { classes } = useStyles();

  const { isLoading, data = [] } = useQuery({
    queryKey: [QueryNames.LOCATION_MODAL_GET_LOCATION_HISTORY, slotLabel],
    queryFn: () => getLocationHistory(systemId, slotLabel),
    select: ({ data }) => transformLocationHistory(data),
  });

  return (
    <BaseCard
      cardFor="location_history"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      showHeaderDivider={true}
      isLoading={isLoading}
      title="History"
      subtitle="Timeline of the location based on WMS Updates and drone reports."
      scrollable
    >
      <Box className={classes.timelineDiv}>
        <Timeline className={classes.timeline}>
          {data.map((item, i) => (
            <CustomTimeLineItem
              key={item.updated_at}
              item={item}
              lastItem={i === data.length - 1}
            />
          ))}
        </Timeline>
      </Box>
    </BaseCard>
  );
};
