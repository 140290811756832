import moment from 'moment-timezone';
import { getValidIntervalOptions } from 'udb/inventory/reports/features/report-scheduler/features/getValidIntervalOptions';
import { DATETIME_FORMAT, PICKER_DATETIME_FORMAT } from '../datetimeFormats';
import { LocalStore } from './storageFunctions';
import { ICalculateElapsedTime } from './otherFunctions';

export const formatDateInTimezone = (
  date: string,
  format: string = PICKER_DATETIME_FORMAT,
): string => {
  const timezone = LocalStore.getTimezone();

  return moment(date).tz(timezone).format(format);
};

export const calculateElapsedTime = ({ from, until }: ICalculateElapsedTime) => {
  const hours: number = Math.floor(moment(until).diff(moment(from), 'minutes') / 60);
  let minutes: number | string = moment(until).diff(moment(from), 'minutes') % 60;
  minutes = minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

  return {
    label: `${hours}h ${minutes}m`,
    hours,
    minutes,
  };
};
/**
 * Calculate elapsed time moment
 * @param from date from
 * @param until date until
 * @returns object with label, houes and minutes
 */

export const calculateElapsedTimeMoment = (from: moment.MomentInput, until: moment.MomentInput) => {
  const hours: number = Math.floor(moment(until).diff(moment(from), 'minutes') / 60);
  let minutes: number | string = moment(until).diff(moment(from), 'minutes') % 60;
  minutes = minutes < 10 ? `0${minutes.toString()}` : minutes.toString();

  return {
    label: `${hours}h ${minutes}m`,
    hours,
    minutes,
  };
};

/**
 * Interval supported by the load since element
 */
export type loadSinceInterval = 'all' | 'last week' | 'last two weeks' | 'last month';

/**
 * Get dates object, based on loadSince string
 * @param loadSince interval since last load
 * @returns An object with from and until properties which are dates that define time interval.
 */
export const getTimeIntervalDates = (loadSince: loadSinceInterval) => {
  let dates = {};

  switch (loadSince) {
    case 'last week':
      dates = { from: moment.utc().subtract(7, 'd').format(DATETIME_FORMAT) };
      break;
    case 'last two weeks':
      dates = { from: moment.utc().subtract(14, 'd').format(DATETIME_FORMAT) };
      break;
    case 'last month':
      dates = { from: moment.utc().subtract(30, 'd').format(DATETIME_FORMAT) };
      break;
    case 'all':
    default:
      break;
  }

  return dates;
};
/**
 * Calculates valid interval options for given start and end time
 * @param from start time of the interval
 * @param until end time of the interval
 * @returns interval options possible in given time-frame
 */

export const calculateValidIntervalOptions = (
  from: moment.MomentInput,
  until: moment.MomentInput,
) => {
  const { hours, minutes } = calculateElapsedTimeMoment(from, until);
  const passedMinutes = Number(hours * 60) + Number(minutes);
  return getValidIntervalOptions(passedMinutes);
};
