import { VERITY_STATES } from 'common/slotStates';

export const getImageText = (
  verityState: '-' | VERITY_STATES,
  numCurrentlyAvailableImages: number,
  numPendingImages: number,
): string => {
  if (verityState === '-') {
    return 'Images are not available because Verity has no data for this location.';
  }
  if (numCurrentlyAvailableImages === 0 && numPendingImages === 0) {
    return 'There are no images available for this location.';
  }
  if (numPendingImages > 0) {
    return 'Waiting for images to be uploaded.';
  }
  return '';
};
