import React, { useState, useEffect, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
// NOTE: This line is commented on because we use 'react-phone-input-2' only temporarily, until 'material-ui-phone-number' library fixes the empty value error.
// import MuiPhoneNumber from 'material-ui-phone-number';
import PhoneInput from 'react-phone-input-2';
import { IUserAttributesSTStatusEnum } from 'codegen/authentication';
import 'react-phone-input-2/lib/material.css';
import { LocalStore } from 'common/functions/storageFunctions';
import { isEmailAvailable, isPhoneValidFormat, isInputEmpty } from 'common/validators';
import { sort } from 'common/functions/otherFunctions';
import { USER_GROUPS } from 'common/userGroups';
import { TokenManager } from 'common/tokenManager';
import CustomMultiSelect from 'components/common/CustomFormComponents/CustomMultiSelect';
import CustomSwitch from 'components/common/CustomFormComponents/CustomSwitch';
import { Box } from 'components/common/Box';
import { IOption } from '../../../interfaces/inputs';
import UserStore from '../../../store/UserStore';
import AuthServices from '../../../services/AuthServices';
import ModalBase from '../ModalBase';
import AddEditUserSkeleton from '../../Skeletons/AddEditUserSkeleton';
import { useRequestController } from '../../../hooks';
import { useFacilityModalsStore } from '../../../store/Modals';
import { FacilityModalsActionTypes } from '../../../store/Modals/types';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from '../../../store/UserLevelStore/userLevelStore';
import { useClientLevelStore } from '../../../store/ClientLevelStore/clientLevelStore';
import { FacilityActionNames } from '../../../store/FacilityLevelStore/facilityLevelActions';
import { useStyles } from './AddEditUserModal.styles';
import { AddEditUserModalProps } from './AddEditUserModal.model';

const tokenManager = TokenManager.getInstance();

const AddEditUserModal = ({ requestItem, opened, allUserEmails }: AddEditUserModalProps) => {
  const { classes } = useStyles();
  const { requestController } = useRequestController('AddEditUserModal');
  const { stateUserLevel, getUserData } = useUserLevelStore();
  const { asyncPopulateFacilities } = useClientLevelStore();
  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();

  const { stateFacilityLevel, dispatchFacilityLevel } = useFacilityLevelStore();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [values, setValues] = useState({
    family_name: '',
    given_name: '',
    email: '',
    phone_number: '',
    user_groups: [],
    access_to_all_facilities: false,
    status: null,
  });

  const [errors, setErrors] = useState({
    givenName: '',
    familyName: '',
    phoneNumber: '',
    email: '',
    userGroups: '',
  });

  const [userFacilities, setUserFacilities] = useState<string[]>([]);
  const [userFacilitiesOld, setUserFacilitiesOld] = useState<string[]>([]);
  const [userAccessToAllFacilitiesOld, setUserAccessToAllFacilitiesOld] = useState<boolean>();
  const [allFacilities, setAllFacilities] = useState<IOption[]>([]);
  const [allUserGroups, setAllUserGroups] = useState([]);

  const { currentSystemId: systemId, facilityData } = stateFacilityLevel;
  const clientName = facilityData.client;
  const refreshToken = tokenManager.getRefreshToken();

  const isButtonDisabled = () => {
    const fieldsPopulated = Object.keys(values).some(
      (key) => key !== 'phone_number' && values[key as keyof typeof values] === '',
    );
    const errorsCleared = Object.values(errors).every((val) => val === '');

    return !errorsCleared || fieldsPopulated;
  };

  const setCurrentSystemId = (currentSystemId: string) => {
    dispatchFacilityLevel({
      type: FacilityActionNames.SET_CURRENT_SYSTEM_ID,
      payload: currentSystemId,
    });
  };

  const closeModal = () => {
    dispatchFacilityModals({ type: FacilityModalsActionTypes.ADD_OR_EDIT_USER });
  };

  const handleChange = (value: string, prop: string) => {
    setValues({ ...values, [prop]: value });
  };

  const handleAccessToAllFacilitiesSwitchChange = (value: boolean) => {
    setValues({ ...values, access_to_all_facilities: value });

    if (value) {
      setUserFacilities(allFacilities.map((num: any) => num.value.toString()));
    } else if (userAccessToAllFacilitiesOld) {
      setUserFacilities([]);
    } else {
      setUserFacilities(userFacilitiesOld);
    }
  };

  const awaitRefreshToken = async (otherFacilitiesUserHasAccessTo?: string[]) => {
    if (!refreshToken) {
      console.warn('awaitRefreshToken invoked when refreshToken is undefined/null');
      return;
    }

    await requestController.doRequest({
      request: AuthServices.refreshToken,
      requestParams: [stateUserLevel.usernameHashed, refreshToken],
      callbackSuccess: (r) => {
        const authData = r.data;
        LocalStore.dataToLocalStorage({ authData });

        if (!isEmpty(otherFacilitiesUserHasAccessTo) && otherFacilitiesUserHasAccessTo) {
          setCurrentSystemId(otherFacilitiesUserHasAccessTo[0]);
        }

        // Refresh list of facilities
        asyncPopulateFacilities(requestController, false);

        getUserData();
      },
      messageErrorFallback: 'Tokens could not be refreshed.',
    });
  };

  const handleAddEditUser = (
    data: { user_id: string; email: string; user_groups: USER_GROUPS[]; system_ids: string },
    isEditing: boolean,
  ) => {
    if (isEditing) {
      const { signal } = requestController.reserveSlotForRequest();
      const { email, ...rest_data } = data;
      requestController.doRequest({
        request: UserStore.updateUser,
        requestParams: [systemId, rest_data, signal],
        messageSuccess: 'User updated.',
        callbackSuccess: (r) => {
          const { email, user_groups: userGroups, system_ids: systemIds } = data;

          const userSystemIds: string[] = systemIds?.split(',');
          const isEditingCurrentlyLoggedUser: boolean = email === stateUserLevel.username;
          const isVerityUser: boolean = userGroups.includes(USER_GROUPS.VERITY_USER);
          const userHasAccessToCurrentFacility: boolean = userSystemIds?.includes(
            systemId as string,
          );
          const otherFacilitiesUserHasAccessTo: string[] = userSystemIds?.filter(
            (userSystemId: string) => userSystemId !== systemId,
          );

          if (!isEditingCurrentlyLoggedUser) {
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          } else if (!isVerityUser || !userHasAccessToCurrentFacility) {
            awaitRefreshToken(otherFacilitiesUserHasAccessTo);
          } else {
            awaitRefreshToken();
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          }
        },
        messageErrorFallback: 'User could not be updated',
        callbackFinally: () => {
          dispatchFacilityModals({ type: FacilityModalsActionTypes.ADD_OR_EDIT_USER });
        },
      });
    } else {
      const { user_id, email, ...payload } = data;
      const { signal } = requestController.reserveSlotForRequest();
      requestController.doRequest({
        request: UserStore.addUser,
        requestParams: [systemId, { ...payload, user_email: email }, signal],
        messageSuccess: 'User added.',
        messageErrorFallback: 'User could not be added.',
        callbackSuccess: () => {
          Object.keys(facilityModalsState.refreshData).forEach((func) =>
            facilityModalsState.refreshData[func](),
          );
        },
        callbackFinally: () => {
          dispatchFacilityModals({ type: FacilityModalsActionTypes.ADD_OR_EDIT_USER });
        },
      });
    }
  };

  const getUser = useCallback(
    (user_id: string) => {
      const { signal } = requestController.reserveSlotForRequest();
      requestController.doRequest({
        request: UserStore.getUser,
        requestParams: [systemId, user_id, signal],
        callbackBeforeSend: () => setIsLoading(true),
        callbackSuccess: (r) => {
          setIsEditing(true);
          setValues({
            family_name: r.family_name,
            given_name: r.given_name,
            email: r.email,
            phone_number: r.phone_number || '',
            user_groups: r.user_group,
            access_to_all_facilities: r.access_to_all_facilities,
            status: r.status,
          });
          setUserFacilities(r.system_ids.map((num: any) => num.toString()));
          setUserFacilitiesOld(r.system_ids.map((num: any) => num.toString()));
          setUserAccessToAllFacilitiesOld(r.access_to_all_facilities);
        },
        messageErrorFallback: 'User could not be fetched.',
        callbackFinally: () => setIsLoading(false),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [systemId],
  );

  const getFacilitiesAndGroups = useCallback(() => {
    const { signal } = requestController.reserveSlotForRequest();

    requestController.doRequest({
      request: UserStore.getFacilitiesAndGroups,
      requestParams: [systemId, signal],
      callbackSuccess: (r) => {
        const sortedFacilities = sort({
          array: r.facilities,
          sortingOrder: 'asc',
          sortBy: 'label',
        });

        setAllFacilities(sortedFacilities);
        setAllUserGroups(r.groups);
      },
      messageErrorFallback: 'Facilities and Groups could not be fetched.',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [systemId]);

  const addUser = (user_id: string) => {
    const userData = {
      user_id,
      system_ids: userFacilities && userFacilities.join(','),
      email: values.email,
      family_name: values.family_name,
      given_name: values.given_name,
      phone_number: values.phone_number
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', ''),
      user_groups: values.user_groups,
      access_to_all_facilities: values.access_to_all_facilities,
    };
    handleAddEditUser(userData, isEditing);
  };

  useEffect(() => {
    getFacilitiesAndGroups();
    if (!isEmpty(requestItem)) {
      getUser(requestItem.sub);
    }
  }, [requestItem, getFacilitiesAndGroups, getUser]);

  const isEditingInfoDisabled = values.status === IUserAttributesSTStatusEnum.ExternalProvider;
  return (
    <ModalBase
      opened={opened}
      maxWidth="md"
      testId="AddEditUserModal"
      handleClose={closeModal}
      title={
        <Box textAlign="start" p={2}>
          <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
            {`${!isEmpty(requestItem) ? 'Edit' : 'Add'} User`}
          </Typography>
          <Typography color="secondary" variant="subtitle1">
            {`${!isEmpty(requestItem) ? 'Editing user from' : 'Adding user to'} ${clientName}`}
          </Typography>
        </Box>
      }
      actionButtons={
        <>
          <Button onClick={closeModal} variant="outlined" color="primary" fullWidth>
            Cancel
          </Button>
          <Button
            onClick={() => addUser(requestItem.sub)}
            variant="contained"
            color="primary"
            fullWidth
            disabled={isButtonDisabled()}
          >
            {`${!isEmpty(requestItem) ? 'Save' : 'Add'} User`}
          </Button>
        </>
      }
    >
      {!isLoading ? (
        <>
          <Box
            className={classes.inputWrapper}
            mt={3}
            mb={4}
            display="flex"
            justifyContent="space-between"
          >
            <TextField
              className={classes.inputField}
              type="text"
              variant="outlined"
              label="First name"
              required
              error={Boolean(errors.givenName)}
              helperText={errors.givenName}
              value={values.given_name}
              disabled={isEditingInfoDisabled}
              onBlur={() =>
                setErrors((e) => ({ ...e, givenName: isInputEmpty(values.given_name) }))
              }
              onChange={(e) => handleChange(e.target.value, 'given_name')}
            />
            <TextField
              className={classes.inputField}
              type="text"
              variant="outlined"
              label="Last name"
              required
              error={Boolean(errors.familyName)}
              helperText={errors.familyName}
              value={values.family_name}
              disabled={isEditingInfoDisabled}
              onBlur={() =>
                setErrors((e) => ({ ...e, familyName: isInputEmpty(values.family_name) }))
              }
              onChange={(e) => handleChange(e.target.value, 'family_name')}
            />
          </Box>
          <Box
            className={classes.inputWrapper}
            mb={2}
            display="flex"
            justifyContent="space-between"
          >
            <TextField
              className={classes.inputField}
              type="email"
              variant="outlined"
              disabled={isEditing || isEditingInfoDisabled}
              label="User email"
              required
              error={Boolean(errors.email)}
              helperText={errors.email}
              value={values.email}
              onChange={(event) => {
                setErrors((e) => ({
                  ...e,
                  email: isEmailAvailable(event.target.value, allUserEmails, isEditing),
                }));
                handleChange(event.target.value, 'email');
              }}
              onBlur={(event) => {
                setErrors((e) => ({
                  ...e,
                  email: isEmailAvailable(event.target.value, allUserEmails, isEditing),
                }));
              }}
            />
            {/*
              Remove once the BE allows the phone to be optional and does not prevent:
                1) users without phone number to do their first login,
                2) editing a user with a phone number and deleting that number
            */}
            {/* NOTE:
            The 'MuiPhoneNumber' is commented on because the 'PhoneInput' is only temporarily fix, until 'material-ui-phone-number' library fixes the empty value error.
            */}
            {/* <MuiPhoneNumber
              variant="outlined"
              autoFormat={true}
              disableDropdown={true}
              className={classes.inputField}
              value={values.phone_number}
              regions={['america', 'europe', 'asia', 'oceania', 'africa']}
              label="Phone number"
              required
              error={Boolean(errors.phoneNumber)}
              helperText={errors.phoneNumber}
              onChange={(e) => {
                isPhoneValidFormat(values.phone_number, "phoneNumber", setErrors);
                handleChange(e, "phone_number");
              }}
              onBlur={(e) => {
                isPhoneValidFormat(values.phone_number, "phoneNumber", setErrors);
              }}
            /> */}
            <div style={{ width: '49%' }}>
              <PhoneInput
                autoFormat={true}
                disableDropdown={true}
                containerClass={classes.phoneInputHolder}
                inputClass={classes.phoneInput}
                value={values.phone_number}
                disabled={isEditingInfoDisabled}
                regions={['america', 'europe', 'asia', 'oceania', 'africa']}
                specialLabel={values.phone_number ? 'Phone number *' : ''}
                placeholder="Phone number *"
                onChange={(phone) => {
                  setErrors((e) => ({
                    ...e,
                    phoneNumber: isPhoneValidFormat(values.phone_number),
                  }));
                  handleChange(`+${phone}`, 'phone_number');
                }}
                onBlur={() => {
                  setErrors((e) => ({
                    ...e,
                    phoneNumber: isPhoneValidFormat(values.phone_number),
                  }));
                }}
              />
              {!!errors.phoneNumber && (
                <p className={classes.phoneInputError}>{errors.phoneNumber}</p>
              )}
            </div>
          </Box>
          <Box
            className={classes.inputWrapper}
            mb={2}
            display="flex"
            justifyContent="space-between"
          >
            <div style={{ width: '75%' }}>
              <CustomMultiSelect
                id="system_ids"
                name="SystemIds"
                testId="c-multi-choice-dropdown-"
                variant="outlined"
                margin="normal"
                disabled={values.access_to_all_facilities}
                label="Grant access for"
                values={userFacilities || []}
                valueOptions={allFacilities || []}
                onChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setUserFacilities(typeof value === 'string' ? value.split(',') : value);
                  setUserFacilitiesOld(typeof value === 'string' ? value.split(',') : value);
                }}
                onClear={() => setUserFacilities([])}
              />
            </div>
            <div
              style={{
                width: '25%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CustomSwitch
                id="access_to_all_facilities"
                name="AccessToAllFacilities"
                testId="c-switch-access-to-all-facilities"
                label="Grant all facilities"
                checked={values.access_to_all_facilities}
                onChange={(event) => {
                  handleAccessToAllFacilitiesSwitchChange(event);
                }}
              />
            </div>
          </Box>
          <Box>
            <CustomMultiSelect
              id="user_groups"
              name="UserGroupsSelect"
              testId="UserGroupsSelect"
              variant="outlined"
              margin="normal"
              label="User group"
              values={values.user_groups || []}
              valueOptions={allUserGroups || []}
              error={Boolean(errors.userGroups)}
              errorMessage={errors.userGroups || ''}
              onChange={(event) => {
                handleChange(event.target.value, 'user_groups');
              }}
              onClear={() => setValues({ ...values, user_groups: [] })}
            />
          </Box>
        </>
      ) : (
        <AddEditUserSkeleton />
      )}
    </ModalBase>
  );
};

export default AddEditUserModal;
