import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { isEmpty } from 'lodash';
import { DISPLAY_ISSUE_TYPES_KEYS, ISSUE_STATES } from 'common/issueTypesAndStates';
import { VERITY_STATES, WMS_STATES } from 'common/slotStates';
import { IIssueST, ISlotStatusST, IUserOverrideST } from '../../../interfaces';
import { getSnoozeActionLabel, isSnoozedActionDisabled } from '../issues/issueFunctions';
import {
  getWMSDateFromSlotStatus,
  getWMSSlotStatusVersionFromSlotStatus,
  getVerityValueFromSlotStatus,
  getVerityDateFromSlotStatus,
  getUserNameFromSlotStatus,
  getVeritySlotStatusVersionFromSlotStatus,
  getWMSStateFromSlotStatus,
  getWMSArticleNosFromSlotStatus,
  getWMSQtyFromSlotStatus,
  getCustomersFromSlotStatus,
  getVerityStateFromSlotStatus,
  getImageIdsFromSlotStatus,
  getClientUserOverwriteFromSlotStatus,
  getWMSValueFromSlotStatus,
  getUserValueFromSlotStatus,
  getOriginalVerityState,
  getOriginalVerityValue,
} from '../slot/slotFunctions';
import { RowDataForLocation } from './getTableRowForLocation';

export const getRowActions = (issue: IIssueST) => {
  if (userHasPermission(PERMISSION.SNOOZE_LOCATION)) {
    return [
      {
        label: getSnoozeActionLabel(issue),
        disabled: isEmpty(issue) || isSnoozedActionDisabled(issue),
      },
    ];
  }
  return [];
};

type ActionsSlotData = {
  id: string;
  version?: number;
  state: ISSUE_STATES;
  issueType: DISPLAY_ISSUE_TYPES_KEYS;
  location: string;
  slotStatusVersion: number | '-';
  latestSlotVersion: number | '-';
  wmsValue: string;
  wmsState: '-' | WMS_STATES;
  wmsDate: string;
  wmsArticleNo: string[];
  wmsQty: string[];
  wmsCustomer: string[];
  wmsSlotStatusVersion: number | null;
  originalVerityValue: string;
  originalVerityState: string;
  verityValue: string;
  verityState: '-' | VERITY_STATES;
  verityDate: string;
  userOverride?: IUserOverrideST;
  userOverrideValue: string[];
  userOverrideUserName: string;
  imageIds: string[];
  snoozedByUserId?: string;
  snoozedByUserName?: string;
  snoozedOn?: string;
  snoozedUntil?: string;
  rowData: RowDataForLocation;
};

const getActionsSlotData = (
  slotStatus: ISlotStatusST,
  locationIssue: IIssueST,
  rowData: RowDataForLocation,
): ActionsSlotData => ({
  id: locationIssue && locationIssue.issue_id,
  version: locationIssue && locationIssue.version,
  state: locationIssue && locationIssue.state,
  issueType: locationIssue && locationIssue.type,

  location: slotStatus.slot_label,

  slotStatusVersion: getVeritySlotStatusVersionFromSlotStatus(slotStatus),
  latestSlotVersion: getVeritySlotStatusVersionFromSlotStatus(slotStatus), // TR:: I left this here for retro-compatibility reasons

  wmsValue: getWMSValueFromSlotStatus(slotStatus).contentExpectedValues.join(', '), // sent as a string to allow amend
  wmsState: getWMSStateFromSlotStatus(slotStatus),
  wmsDate: getWMSDateFromSlotStatus(slotStatus),
  wmsArticleNo: getWMSArticleNosFromSlotStatus(slotStatus),
  wmsQty: getWMSQtyFromSlotStatus(slotStatus),
  wmsCustomer: getCustomersFromSlotStatus(slotStatus),
  wmsSlotStatusVersion: getWMSSlotStatusVersionFromSlotStatus(slotStatus),

  originalVerityValue: getOriginalVerityValue(slotStatus).join(', '), // sent as a string to allow amend
  originalVerityState: getOriginalVerityState(slotStatus),
  verityValue: getVerityValueFromSlotStatus(slotStatus).join(', '), // sent as a string to allow amend
  verityState: getVerityStateFromSlotStatus(slotStatus),
  verityDate: getVerityDateFromSlotStatus(slotStatus),

  userOverride: getClientUserOverwriteFromSlotStatus(slotStatus),
  userOverrideValue: getUserValueFromSlotStatus(slotStatus),
  userOverrideUserName: getUserNameFromSlotStatus(slotStatus),

  imageIds: getImageIdsFromSlotStatus(slotStatus),

  snoozedByUserId: locationIssue && locationIssue.snoozed_by_user_id,
  snoozedByUserName: locationIssue && locationIssue.snoozed_by_user_name,
  snoozedOn: locationIssue && locationIssue.snoozed_on,
  snoozedUntil: locationIssue && locationIssue.snoozed_until,

  rowData,
});

/**
 * Get the data needed to execute actions on a location / issue
 * used on actions and on the location modal
 * @param slotStatus
 * @param locationIssue
 * @param rowData
 * @param permissions
 * @returns
 */
export const getTableRowActionsForSlotStatus = (
  slotStatus: ISlotStatusST,
  locationIssue: IIssueST,
  rowData: RowDataForLocation,
) => {
  const rowActions = getRowActions(locationIssue);
  return {
    data: getActionsSlotData(slotStatus, locationIssue, rowData),
    actions: rowActions,
  };
};
