import moment from 'moment';
import { Alert, SelectChangeEvent, TextField, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Dispatch, useCallback, useEffect } from 'react';
import { PICKER_DATETIME_FORMAT } from 'common/datetimeFormats';
import { getRecurrenceDataReportDeadline } from 'common/functionsReportSpec';
import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { Box } from 'components/common/Box';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { ReportDeadline, ScheduleFormAction } from '../reducers/ScheduleFormReducer';

const REPORT_DEADLINES = [
  { value: 'DEFAULT_DEADLINE', label: 'Use Default' },
  { value: 'CUSTOM_DEADLINE', label: 'Set Custom Deadline' },
];

const MAX_HOURS_DEADLINE = 24 * 7;

type ReportDeadlinesProps = {
  /**
   * ID of the facility
   */
  systemId: string;
  /**
   * Type of report deadline (Automatic vs. Manual)
   */
  reportDeadline: ReportDeadline;
  /**
   * Deadline / Duration in minutes
   */
  reportDeadlineTimeoutInMinutes: number | null;
  /**
   * Start date of the report
   */
  reportFromDate: moment.MomentInput;
  /**
   * End date of the report
   */
  reportUntilDate: moment.MomentInput;
  reportDeadlineText: string;
  rString: string;
  /**
   * Store dispatcher
   */
  dispatch: Dispatch<ScheduleFormAction>;
};

const logPrefix = getLogPrefixForType('COMPONENT', 'ReportDeadlines');

/**
 * Report deadline picker
 * @param props ReportDeadlinesProps
 * @returns component
 */
export const ReportDeadlines = ({
  systemId,
  reportDeadline,
  reportDeadlineTimeoutInMinutes,
  reportFromDate,
  reportUntilDate,
  reportDeadlineText,
  rString,
  dispatch,
}: ReportDeadlinesProps) => {
  const { report_deadlines: reportDeadlinesFacilitySettings } =
    useFacilityLevelStore().stateFacilityLevel.facilitySettings;

  const maximumDuration = moment.duration(reportDeadlineTimeoutInMinutes, 'minutes');
  const hours = maximumDuration.hours() + maximumDuration.days() * 24;
  const minutes = maximumDuration.minutes();

  const setReportDeadline = useCallback(
    (e: SelectChangeEvent<string>) =>
      dispatch({ type: 'SET_REPORT_DEADLINE', payload: e.target.value as ReportDeadline }),
    [dispatch],
  );

  const handleDeadlineTimeChange = useCallback(
    (hours: number, minutes: number) => {
      const parsedMinutes = moment.duration({ hours, minutes }).asMinutes();
      console.debug(
        logPrefix,
        `setting the report deadline to ${parsedMinutes} minutes (hours: ${hours}, minutes: ${minutes})`,
      );
      dispatch({
        type: 'SET_REPORT_DEADLINE_TIMEOUT_MINUTES',
        payload: reportDeadline === 'CUSTOM_DEADLINE' ? parsedMinutes : null,
      });
    },
    [dispatch, reportDeadline],
  );

  useEffect(() => {
    getRecurrenceDataReportDeadline(systemId, rString, dispatch);
  }, [dispatch, rString, systemId]);

  const automaticReportDeadlineText = (
    <>
      Default report deadlines apply to all reports. They are currently set to: <br />
      <br />
      Mon: {reportDeadlinesFacilitySettings.mon?.split(':').slice(0, 2).join(':') ?? '-'}
      <br />
      Tue: {reportDeadlinesFacilitySettings.tue?.split(':').slice(0, 2).join(':') ?? '-'}
      <br />
      Wed: {reportDeadlinesFacilitySettings.wed?.split(':').slice(0, 2).join(':') ?? '-'}
      <br />
      Thu: {reportDeadlinesFacilitySettings.thu?.split(':').slice(0, 2).join(':') ?? '-'}
      <br />
      Fri: {reportDeadlinesFacilitySettings.fri?.split(':').slice(0, 2).join(':') ?? '-'}
      <br />
      Sat: {reportDeadlinesFacilitySettings.sat?.split(':').slice(0, 2).join(':') ?? '-'}
      <br />
      Sun: {reportDeadlinesFacilitySettings.sun?.split(':').slice(0, 2).join(':') ?? '-'}
      <br />
      <br />
      By setting a custom report specific deadline, the default setup will be ignored.
      <br />
      For further assistance contact Verity Support.
    </>
  );
  return (
    <>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <CustomSelect
          testId="c-schedule-report-deadline"
          variant="outlined"
          margin="dense"
          sx={{ marginBottom: 1.5 }}
          disabled={false}
          id="report-deadline"
          label="Report Deadline"
          name="report-deadline"
          value={reportDeadline}
          defaultValue=""
          valueOptions={REPORT_DEADLINES}
          onChange={setReportDeadline}
          error={false}
          errorMessage=""
        />

        <Box sx={{ marginTop: 2.7 }}>
          <TooltipedIcon
            tooltip={automaticReportDeadlineText}
            icon={<InfoOutlinedIcon fontSize="medium" />}
            placement="right"
          />
        </Box>
      </Box>

      {reportDeadline === 'CUSTOM_DEADLINE' && (
        <div>
          <Box display="flex" gap={2} width="100" alignItems="center">
            <TextField
              fullWidth
              variant="outlined"
              label="Maximum Duration Hours"
              type="number"
              value={hours}
              onChange={(e) => {
                handleDeadlineTimeChange(
                  Number(e.target.value) < MAX_HOURS_DEADLINE
                    ? Number(e.target.value)
                    : MAX_HOURS_DEADLINE,
                  minutes,
                );
              }}
              inputProps={{ min: 0, max: MAX_HOURS_DEADLINE }}
              onFocus={(e) => e.target.select()}
            />

            <TextField
              fullWidth
              variant="outlined"
              label="Maximum Duration Minutes"
              type="number"
              value={minutes}
              onChange={(e) => {
                handleDeadlineTimeChange(
                  hours,
                  Number(e.target.value) < 59 ? Number(e.target.value) : 59,
                );
              }}
              inputProps={{ min: 0, max: 59 }}
              onFocus={(e) => e.target.select()}
            />
          </Box>

          <Alert sx={{ mb: 1, mt: 2 }} icon={false} severity="info">
            <Typography>
              {reportDeadlineText
                ? `${reportDeadlineText} until ${moment(reportUntilDate).format(
                    PICKER_DATETIME_FORMAT,
                  )}`
                : `The report will finish by ${moment(reportFromDate)
                    .add(reportDeadlineTimeoutInMinutes ?? 0, 'minutes')
                    .format(PICKER_DATETIME_FORMAT)}`}
            </Typography>
          </Alert>
        </div>
      )}
    </>
  );
};
