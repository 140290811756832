import ActionsMenu from 'components/common/ActionsMenu';

import { useFacilityModalsStore } from 'store/Modals';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { FacilityModalsActionTypes } from 'store/Modals/types';
import { useCallback } from 'react';

export const SnoozeControl = ({
  locationData,
  parentPage,
  refreshLocationData,
}: {
  locationData: ILocationData;
  parentPage: 'WarehouseStatus' | 'Report';
  refreshLocationData: (locationLabel: string) => void;
}) => {
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const showSnooze =
    parentPage === 'WarehouseStatus' &&
    locationData?.rowData?.actions?.actions?.length &&
    locationData.rowData.actions.actions[0].label === 'Snooze' &&
    !locationData?.rowData.actions?.actions[0].disabled;
  const showUnsnooze =
    parentPage === 'WarehouseStatus' &&
    locationData?.rowData?.actions?.actions?.length &&
    locationData?.rowData.actions?.actions[0]?.label === 'Unsnooze' &&
    !locationData?.rowData.actions?.actions[0]?.disabled;

  const toggleSnoozeModal = useCallback(() => {
    dispatchFacilityModals({
      type: FacilityModalsActionTypes.TOGGLE_SNOOZE_ISSUE,
      payload: locationData.rowData?.actions?.data,
      refreshData: {
        refreshData: () => refreshLocationData(locationData.location),
      },
    });
  }, [
    dispatchFacilityModals,
    locationData.location,
    locationData.rowData?.actions?.data,
    refreshLocationData,
  ]);

  const menuOptions = [
    { label: showSnooze ? 'Snooze' : 'Unsnooze', clickHandler: () => toggleSnoozeModal() },
  ];

  return (showSnooze || showUnsnooze) && <ActionsMenu options={menuOptions} size="large" />;
};
