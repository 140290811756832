import MicrosoftIcon from '@mui/icons-material/Microsoft';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import EnhancedTable from 'components/EnhancedTable';
import { IUserResponseSTStatusEnum } from 'codegen/user_admin';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { QueryNames } from 'ts-types/QueryNames';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { FacilityModalsActionTypes } from '../../../store/Modals/types';
import { useFacilityModalsStore } from '../../../store/Modals';
import { useUserLevelStore } from '../../../store/UserLevelStore/userLevelStore';
import { transformUsersFromDTO } from './model/transformUsersFromDTO';
import { getUsersWithGroups } from './api/getUsers.api';
import { getFacilities } from './api/getFacilities.api';
import { transformFacilitiesFromDTO } from './model/transformFacilitiesFromDTO';
import { usersColumnStructure } from './defaults/usersColumnStructure.defaults';
import {
  User as UserType,
  Users as UsersType,
  UserTableRow,
  UserWithFacilities,
} from './model/user.model';
import { Facilities, Facility } from './model/facility.model';

const Users = () => {
  const { systemId } = useParams();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateUserLevel } = useUserLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const {
    isLoading: isLoadingFacilities,
    isRefetching: isRefetchingFacilities,
    data: facilities,
    refetch: refetchFacilities,
  } = useQuery({
    queryKey: [QueryNames.FETCH_FACILITIES, systemId],
    queryFn: getFacilities,
    select: useCallback(transformFacilitiesFromDTO, []),
  });

  const {
    isLoading: isLoadingUsers,
    isRefetching: isRefetchingUsers,
    data: users,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: [QueryNames.FETCH_USERS, systemId],
    queryFn: getUsersWithGroups(systemId!),
    select: useCallback(transformUsersFromDTO, []),
  });

  const isLoading = () =>
    isLoadingFacilities || isRefetchingFacilities || isLoadingUsers || isRefetchingUsers;

  const refetchData = async () => {
    await refetchUsers();
    await refetchFacilities();
  };

  const usersWithFacilitiesNames: (
    users: UsersType,
    facilities: Facilities,
  ) => UserWithFacilities[] = (users, facilities) =>
    users.map((u: UserType) => ({
      ...u,
      facilities: u.systemIds.map((systemId: number) => {
        const facility = facilities.find((f: Facility) => f.id === systemId);
        return facility ? facility.name : `${systemId}`;
      }),
    }));

  const usersWithActions: (users: Array<UserWithFacilities>) => Array<UserTableRow> = (users) =>
    users?.map((user: UserWithFacilities) => ({
      ...user,
      status_icon:
        user.status === IUserResponseSTStatusEnum.ExternalProvider ? <MicrosoftIcon /> : '',
      actions: {
        actions: [
          {
            label: 'Edit User',
            disabled: false,
            onClick: () => handleEditDeleteUser(user, FacilityModalsActionTypes.ADD_OR_EDIT_USER),
          },
          {
            label: 'Delete User',
            disabled: stateUserLevel.usernameHashed === user.usernameHashed,
            onClick: () => handleEditDeleteUser(user, FacilityModalsActionTypes.DELETE_USER),
          },
        ],
      },
    }));

  const handleEditDeleteUser = (row: UserWithFacilities, action: FacilityModalsActionTypes) => {
    dispatchFacilityModals({
      type: action,
      payload: row,
      refreshData: { refreshData: refetchData },
    });
  };

  useEffect(() => {
    if (users) {
      const usersEmails = users.map((user: UserType) => ({
        email: user.email,
        username: `${user.firstName} ${user.lastName}`,
      }));

      dispatchFacilityModals({
        type: FacilityModalsActionTypes.ALL_USER_EMAILS,
        payload: usersEmails,
      });
    }
  }, [users, dispatchFacilityModals]);

  return (
    <>
      <PageHeaderSection title="Administration" showMenuIcon={false} showLoadedSince={false} />

      <Container maxWidth="xl" sx={{ paddingTop: '32px' }}>
        <Grid direction="column" container className="c-page-content">
          <EnhancedTable
            tableFor="Users"
            tableTitle="Users"
            tableSubtitle={`Here is a list of all the users associated with ${
              stateFacilityLevel.facilityData?.client ?? ''
            }`}
            headCells={usersColumnStructure}
            rows={
              users && facilities
                ? usersWithActions(usersWithFacilitiesNames(users, facilities))
                : []
            }
            isLoading={isLoading()}
            refreshData={{ refreshData: () => refetchData }}
            currentActiveSpinner={{ Users: isLoading() }}
            headerButton={
              <Button
                data-testid="AddUserButton"
                variant="contained"
                color="primary"
                onClick={() =>
                  dispatchFacilityModals({
                    type: FacilityModalsActionTypes.ADD_OR_EDIT_USER,
                    payload: {},
                    refreshData: { refreshData: refetchData },
                  })
                }
              >
                Add user
              </Button>
            }
          />
        </Grid>
      </Container>
    </>
  );
};

export default Users;
