import { Box, Tooltip, Typography } from '@mui/material';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import moment from 'moment';
import { DATETIME_FORMAT_WITH_WEEKDAY } from 'common/datetimeFormats';
import { formatDateInTimezone } from 'common/functions/dateTimeFunctions';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { DroneOnChargerIcon } from 'pages/GroundControl/Icons/DroneOnChargerIcon';

export const LocationModalSubHeader = ({
  rowData,
}: {
  rowData: { wmsDate: string; contentFoundDate: string };
}) => (
  <Box
    component="div"
    display="flex"
    flexDirection="row"
    paddingBottom={2.5}
    paddingLeft={1}
    textAlign="left"
    gap={5}
    sx={{
      cursor: 'default',
    }}
  >
    <Box component="div" display="flex" flexDirection="row" gap={1} alignItems="center">
      <TooltipedIcon
        noMargin
        tooltip="Latest WMS data point for this location"
        icon={<WarehouseIcon sx={{ color: 'GrayText' }} />}
      />
      {rowData?.wmsDate !== '-' ? (
        <Tooltip title={formatDateInTimezone(rowData?.wmsDate, DATETIME_FORMAT_WITH_WEEKDAY) || ''}>
          <Box component="div" display="flex" flexDirection="row" gap={1}>
            <Typography data-testid="c-loc-mod-verity-date" variant="body2" color="GrayText">
              Last WMS Update
            </Typography>
            <Typography data-testid="c-loc-mod-verity-date" variant="body2" color="inherit">
              {moment(rowData?.wmsDate).fromNow()}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <Typography data-testid="c-loc-mod-wms-date" variant="body2" color="inherit">
          Never updated
        </Typography>
      )}
    </Box>

    <Box component="div" display="flex" flexDirection="row" gap={1} alignItems="center">
      <TooltipedIcon
        noMargin
        tooltip="Latest drone scan for this location"
        icon={
          <DroneOnChargerIcon isActive={true} colorActive="GrayText" colorInactive="GrayText" />
        }
      />
      {rowData?.contentFoundDate !== '-' ? (
        <Tooltip
          title={
            formatDateInTimezone(rowData?.contentFoundDate, DATETIME_FORMAT_WITH_WEEKDAY) || ''
          }
        >
          <Box component="div" display="flex" flexDirection="row" gap={1}>
            <Typography data-testid="c-loc-mod-verity-date" variant="body2" color="GrayText">
              Last scan
            </Typography>
            <Typography data-testid="c-loc-mod-verity-date" variant="body2" color="inherit">
              {moment(rowData?.contentFoundDate).fromNow()}
            </Typography>
          </Box>
        </Tooltip>
      ) : (
        <Typography data-testid="c-loc-mod-verity-date" variant="body2" color="inherit">
          Never scanned
        </Typography>
      )}
    </Box>
  </Box>
);
