import { useEffect } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import { AUTH_PAGES_URLS } from 'common/pages.tsx';
import { LocalStore } from 'common/functions/storageFunctions';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useClientModalsStore, useFacilityModalsStore } from '../../store/Modals';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';

import { UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';
import { ClientModalsActionTypes, FacilityModalsActionTypes } from '../../store/Modals/types';

const SignOut = ({ onSignout }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const domainSwitch = searchParams.get('domain_switch');
  const arrivedFromOldDomain = searchParams.get('arrived_from_old_domain');
  const { dispatchFacilityLevel } = useFacilityLevelStore();
  const { dispatchUserLevelStore } = useUserLevelStore();
  const { dispatchClientLevel } = useClientLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();
  const { dispatchClientModals } = useClientModalsStore();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    enqueueSnackbar(location.state?.logoutMessage || 'You are logged out!', {
      variant: location.state?.variant || 'success',
      preventDuplicate: true,
    });
  });

  useEffect(() => {
    LocalStore.cleanStateAndLocalStorage({
      dispatchFacilityLevel,
      dispatchClientLevel,
      dispatchUserLevelStore,
    });
    dispatchFacilityModals({ type: FacilityModalsActionTypes.RESET });
    dispatchClientModals({ type: ClientModalsActionTypes.RESET });
    dispatchUserLevelStore({ type: UserLevelActionNames.SIGNOUT });
    if (onSignout) onSignout();
    navigate(AUTH_PAGES_URLS.SIGNIN, {
      state: { domainSwitch, arrivedFromOldDomain },
    });
  });

  return null;
};

SignOut.propTypes = { onSignout: PropTypes.func };
export default SignOut;
