// components
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { webSocket } from 'shared/web-socket/web-socket';
import BaseCard from 'components/BaseCard/BaseCard';
import BaseCardSimpleItem, {
  IBaseCardSimpleItemSectionItems,
} from 'components/BaseCard/BaseCardSimpleItem';
import { INoFlyZonesResponseST } from 'codegen/no_fly_zone';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { getNoFlyZonesCardData } from './FlightDomainOverviewFunctions';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';
import { getNoFlyZones } from './getNoFlyZones';
import { useRequestController } from '../../../../hooks';
import { useComponentDidMount } from '../../../../hooks/useComponentDidMount';

export const NoFlyZonesCard = ({
  systemId,
  flightDomainId,
}: {
  systemId: string;
  flightDomainId: string;
}) => {
  const navigate = useNavigate();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { requestController } = useRequestController('NoFlyZonesCard');

  const {
    stateGroundControl: { flightDomain },
  } = useGroundControlStore();

  const [noFlyZones, setNoFlyZones] = useState<INoFlyZonesResponseST>({
    flight_domain_id: '',
    no_fly_zones: {},
    no_fly_zones_img_url: '',
  } as INoFlyZonesResponseST);
  const [isLoading, setIsLoading] = useState(true);

  const noData = isEmpty(noFlyZones.no_fly_zones);

  const noFlyZonesData = getNoFlyZonesCardData({
    systemId,
    flightDomain,
    noFlyZones,
    isCoexistenceEnabled: stateFacilityLevel.facilitySettings?.show_coexistence_map,
    navigate,
  });

  useComponentDidMount(() => {
    const socket = webSocket();

    socket.subscribe({
      onMessage(data: INoFlyZonesResponseST): void {
        if (flightDomain.flight_domain_id === data.flight_domain_id) {
          setNoFlyZones(data);
        }
      },
      channel: 'ground-control/no-fly-zone/status-update',
      delegate: 'NoFlyZonesCard',
    });

    getNoFlyZones({
      requestController,
      flightDomainId,
      systemId,
      onBeforeSend: () => setIsLoading(true),
      onFinally: () => setIsLoading(false),
      onSuccess: setNoFlyZones,
    });
  });

  return (
    <BaseCard
      cardFor="no-fly-zones"
      dataTestId={noFlyZonesData!.testId}
      title={noFlyZonesData!.title}
      subtitle={noFlyZonesData!.subtitle}
      actionButtons={noFlyZonesData!.actionButtons}
      noData={noData}
      isLoading={isLoading}
    >
      {noFlyZonesData!.sections.map((item: { sectionItems: IBaseCardSimpleItemSectionItems[] }) => (
        <div key={`item-${item.sectionItems[0].testId}`}>
          <BaseCardSimpleItem sectionItems={item.sectionItems} noData={noData} />
        </div>
      ))}
    </BaseCard>
  );
};
