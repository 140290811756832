import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';

// material-ui core
import TextField from '@mui/material/TextField';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

// material-ui icons
import IconButton from '@mui/material/IconButton';

// functions
import { validatePassword } from 'common/validators';
import { IInputPasswordProps } from '../../../interfaces/inputs/inputsInterfaces';

const InputPassword = (props: IInputPasswordProps) => {
  const {
    value,
    disabled,
    label,
    variant,
    customClasses,
    confirmPassword,
    validate = true,
    onChange,
    activeError,
    ...rest
  } = props;

  const [visibility, setVisibility] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    // Check password equality if component has "confirmPassword" prop
    if (confirmPassword && !isEmpty(value) && value !== confirmPassword) {
      setError('The passwords do not match');
    } else {
      validate && setError(validatePassword(value!));
    }
    activeError && activeError(Boolean(error));
  }, [value, validate, activeError, error, confirmPassword]);

  return (
    <TextField
      className={customClasses}
      type={visibility ? 'text' : 'password'}
      variant={variant}
      label={label}
      disabled={disabled}
      value={value}
      error={Boolean(error)}
      helperText={error}
      onBlur={onChange}
      onChange={onChange}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={`${label} visibility`}
              onClick={() => setVisibility(!visibility)}
              edge="end"
              size="large"
            >
              {visibility ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default InputPassword;
