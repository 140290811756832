import { includes, inRange, isEmpty } from 'lodash';
import { enqueueSnackbar } from 'notistack';
import { getSanitizedInputValues } from 'common/functions/otherFunctions';
import { VERITY_STATES } from 'common/slotStates';

export const validateFormData = ({
  barcodeMinLength,
  barcodeMaxLength,
  barcodeInvalidLengths,
  overwriteContentType,
  overwriteBarcodes,
}: {
  barcodeMinLength: number;
  barcodeMaxLength: number;
  barcodeInvalidLengths: number[];
  overwriteContentType: VERITY_STATES;
  overwriteBarcodes: string;
}) => {
  if (overwriteContentType === VERITY_STATES.BARCODE) {
    const barcodeValid = getSanitizedInputValues(overwriteBarcodes).reduce(
      (acc, barcode) =>
        // result accumulator (starts at true)
        acc &&
        // check if barcode has invalid length
        !includes(barcodeInvalidLengths, barcode.length) &&
        // check if barcode respects min and max lengths specified in settings
        inRange(barcode.length, barcodeMinLength, barcodeMaxLength + 1),
      // accumulator's initial value
      true,
    );

    if (!barcodeValid) {
      const invalidLengthWarning = !isEmpty(barcodeInvalidLengths)
        ? `Barcodes of length ${barcodeInvalidLengths} are not allowed.`
        : '';
      let snackbarMessage = '';

      if (barcodeMinLength === barcodeMaxLength) {
        snackbarMessage = `The entered barcodes must be ${barcodeMinLength} digits long. ${invalidLengthWarning}`;
      } else {
        snackbarMessage = `The entered barcodes must be between ${barcodeMinLength} and ${barcodeMaxLength} digits long. ${invalidLengthWarning}`;
      }

      enqueueSnackbar(snackbarMessage, { variant: 'error', preventDuplicate: true });

      return false;
    }
  }

  return true;
};
