import React from 'react';

import ImageIcon from '@mui/icons-material/Image';
import RepeatIcon from '@mui/icons-material/Repeat';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import { formatDateInTimezone } from 'common/functions/dateTimeFunctions';
import TooltipedIcon from 'components/common/TooltipedIcon';

import { TABLE_DATETIME_FORMAT } from '../datetimeFormats';
import { styleTableCell } from './tableColumnStyleFunctions';
import { TABLE_COLUMN_OPTIONS } from '../settings';

import { ITableRow } from '../../interfaces';

// TABLE COLUMNS FOR WAREHOUSE STATUS PAGE
export const WHS_ISSUES_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueType.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentExpected',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.contentExpected.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.contentExpected.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) =>
      styleTableCell(row, 'contentExpected', innerIndex),
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'barcodeFoundShouldBeOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeFoundShouldBeOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeFoundShouldBeOn.show,
    label: 'Should be at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueFirstFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueFirstFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueFirstFoundOn.show,
    label: 'First found on',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'issueStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const WHS_SOLVED_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.issueType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.issueType.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentExpected',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.contentExpected.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.contentExpected.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) =>
      styleTableCell(row, 'contentExpected', innerIndex),
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'barcodeFoundShouldBeOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.barcodeFoundShouldBeOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.barcodeFoundShouldBeOn.show,
    label: 'Should be at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueFirstFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.issueFirstFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.issueFirstFoundOn.show,
    label: 'First found on',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'issueStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_SOLVED.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const WHS_AMENDED_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.wmsValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.wmsValue.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'wmsValue', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.wmsDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.wmsDate.show,
    label: 'WMS Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'verityValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.verityValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.verityValue.show,
    label: 'Verity Value',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'verityValue', innerIndex),
  },
  {
    id: 'verityDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.verityDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.verityDate.show,
    label: 'Found Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
    style: (row: ITableRow) => styleTableCell(row, 'verityDate'),
  },
  {
    id: 'userValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.userValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.userValue.show,
    label: 'User Value',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'userValue', innerIndex),
  },
  {
    id: 'userName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.userName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.userName.show,
    label: 'User Name',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.issue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.issue.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_AMENDED.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const WHS_POTENTIAL_AND_INCONCLUSIVE_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentExpected',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.contentExpected.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.contentExpected.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) =>
      styleTableCell(row, 'contentExpected', innerIndex),
  },
  {
    id: 'contentFound',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'issueFirstFoundOn',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.issueFirstFoundOn
        .searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.issueFirstFoundOn.show,
    label: 'First found on',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'issueStatus',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_POTENTIAL_AND_INCONCLUSIVE.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const WHS_INVALID_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentExpected',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.contentExpected.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.contentExpected.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) =>
      styleTableCell(row, 'contentExpected', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.wmsDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.wmsDate.show,
    label: 'WMS Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'contentFoundDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.contentFoundDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.contentFoundDate.show,
    label: 'Found date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
    style: (row: ITableRow) => styleTableCell(row, 'verityDate'),
  },
  {
    id: 'issueFirstFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueFirstFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueFirstFoundOn.show,
    label: 'First found on',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'issueStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_INVALID.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const WHS_FULL_REPORT_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsValue.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'wmsValue', innerIndex),
  },
  {
    id: 'barcodeExpectedFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeExpectedFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeExpectedFoundOn.show,
    label: 'Was found at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsArticleNo',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsArticleNo.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsArticleNo.show,
    label: 'WMS Article Nr.',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsQty',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsQty.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsQty.show,
    label: 'WMS Qty',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsDate.show,
    label: 'WMS Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'barcodeFoundShouldBeOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeFoundShouldBeOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeFoundShouldBeOn.show,
    label: 'Should be at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentFoundDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.contentFoundDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.contentFoundDate.show,
    label: 'Found date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
    style: (row: ITableRow) => styleTableCell(row, 'verityDate'),
  },
  {
    id: 'wmsDescription',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsDescription.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsDescription.show,
    label: 'WMS Description',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.issue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.issue.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
    style: (row: ITableRow) => styleTableCell(row, 'issueType'),
  },
  {
    id: 'issueStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const LOCATION_MODAL_INFO = (settingsData: any) => [
  {
    id: 'wmsValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsValue.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'wmsValue', innerIndex),
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'barcodeExpectedFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeExpectedFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeExpectedFoundOn.show,
    label: 'Was found at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'barcodeFoundShouldBeOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeFoundShouldBeOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_ISSUES.barcodeFoundShouldBeOn.show,
    label: 'Should be at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsArticleNo',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsArticleNo.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsArticleNo.show,
    label: 'WMS Article Nr.',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsQty',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsQty.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsQty.show,
    label: 'WMS Qty',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsDescription',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsDescription.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WHS_FULL_REPORT.wmsDescription.show,
    label: 'WMS Description',
    type: 'string',
    align: 'center',
  },
];

// TABLE COLUMNS FOR REPORT PAGE
export const REPORT_ISSUES_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueType.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentExpected',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.contentExpected.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.contentExpected.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) =>
      styleTableCell(row, 'contentExpected', innerIndex),
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'barcodeFoundShouldBeOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.barcodeFoundShouldBeOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.barcodeFoundShouldBeOn.show,
    label: 'Should be at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'userValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.userValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.userValue.show,
    label: 'User amended value',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueFirstFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueFirstFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueFirstFoundOn.show,
    label: 'First found on',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'issueStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORT_SOLVED_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.issueType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.issueType.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentExpected',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.contentExpected.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.contentExpected.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) =>
      styleTableCell(row, 'contentExpected', innerIndex),
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.contentFound.show,
    label: 'Content found',
    type: 'string',
    align: 'center',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'barcodeFoundShouldBeOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.barcodeFoundShouldBeOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.barcodeFoundShouldBeOn.show,
    label: 'Should be at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'userValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.userValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.userValue.show,
    label: 'User amended value',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueFirstFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.issueFirstFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.issueFirstFoundOn.show,
    label: 'First found on',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'issueStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_SOLVED.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORT_AMENDED_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.wmsValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.wmsValue.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'wmsValue', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.wmsDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.wmsDate.show,
    label: 'WMS Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'verityValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.verityValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.verityValue.show,
    label: 'Verity Value',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'verityValue', innerIndex),
  },
  {
    id: 'verityDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.verityDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.verityDate.show,
    label: 'Found Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
    style: (row: ITableRow) => styleTableCell(row, 'verityDate'),
  },
  {
    id: 'userValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.userValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.userValue.show,
    label: 'User Value',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'userValue', innerIndex),
  },
  {
    id: 'userName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.userName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.userName.show,
    label: 'User Name',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.issue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.issue.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_AMENDED.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORT_POTENTIAL_AND_INCONCLUSIVE_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentExpected',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.contentExpected
        .searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.contentExpected.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) =>
      styleTableCell(row, 'contentExpected', innerIndex),
  },
  {
    id: 'contentFound',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'issueFirstFoundOn',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.issueFirstFoundOn
        .searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.issueFirstFoundOn
      .show,
    label: 'First found on',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'issueStatus',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_POTENTIAL_AND_INCONCLUSIVE.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORT_INVALID_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'contentExpected',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.contentExpected.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.contentExpected.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'wmsValue', innerIndex),
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.wmsDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.wmsDate.show,
    label: 'WMS Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'contentFoundDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.contentFoundDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.contentFoundDate.show,
    label: 'Found date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
    style: (row: ITableRow) => styleTableCell(row, 'verityDate'),
  },
  {
    id: 'issueFirstFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueFirstFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueFirstFoundOn.show,
    label: 'First found on',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'issueStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_INVALID.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORT_FULL_REPORT_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsValue.show,
    label: 'Content expected',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'wmsValue', innerIndex),
  },
  {
    id: 'barcodeExpectedFoundOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.barcodeExpectedFoundOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.barcodeExpectedFoundOn.show,
    label: 'Was found at',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsArticleNo',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsArticleNo.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsArticleNo.show,
    label: 'WMS Article Nr.',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsQty',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsQty.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsQty.show,
    label: 'WMS Qty',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsCustomer',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsCustomer.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsCustomer.show,
    label: 'Customer',
    type: 'item_list',
    align: 'left',
  },
  {
    id: 'wmsDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsDate.show,
    label: 'WMS Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'contentFound',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.contentFound.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.contentFound.show,
    label: 'Content found',
    type: 'item_list',
    align: 'left',
    style: (row: ITableRow, innerIndex: number) => styleTableCell(row, 'contentFound', innerIndex),
  },
  {
    id: 'barcodeFoundShouldBeOn',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.barcodeFoundShouldBeOn.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_ISSUES.barcodeFoundShouldBeOn.show,
    label: 'Should be at',
    type: 'string',
    align: 'center',
    style: (row: ITableRow) => styleTableCell(row, 'barcodeFoundShouldBeOn'),
  },
  {
    id: 'contentFoundDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.contentFoundDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.contentFoundDate.show,
    label: 'Found date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
    style: (row: ITableRow) => styleTableCell(row, 'verityDate'),
  },
  {
    id: 'wmsDescription',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsDescription.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.wmsDescription.show,
    label: 'WMS Description',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.issue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.issue.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issueStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.issueStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.issueStatus.show,
    label: 'Issue status',
    type: 'badge',
    align: 'center',
  },
  {
    id: 'image',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.image.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.image.show,
    label: <ImageIcon />,
    type: 'button',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_FULL_REPORT.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORT_REVIEW_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'location',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.location.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.location.show,
    label: 'Location',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsValue.show,
    label: 'WMS Value',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsDate.show,
    label: 'WMS Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'verityValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.verityValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.verityValue.show,
    label: 'Verity Value',
    type: 'string',
    align: 'center',
  },
  {
    id: 'verityDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.verityDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.verityDate.show,
    label: 'Verity Date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'userValue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.userValue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.userValue.show,
    label: 'User Value',
    type: 'string',
    align: 'center',
  },
  {
    id: 'userName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.userName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.userName.show,
    label: 'User Name',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsArticleNo',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsArticleNo.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsArticleNo.show,
    label: 'WMS Article N.',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsDescription',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsDescription.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsDescription.show,
    label: 'WMS Description',
    type: 'string',
    align: 'center',
  },
  {
    id: 'wmsQty',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsQty.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.wmsQty.show,
    label: 'WMS Qty',
    type: 'string',
    align: 'center',
  },
  {
    id: 'issue',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.issue.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.issue.show,
    label: 'Issue',
    type: 'string',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORT_REVIEW.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const WAREHOUSE_DATA_UPLOADS_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'center',
  },
  {
    id: 'uploadDate',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.uploadDate.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.uploadDate.show,
    label: 'Upload date',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== 'n.a.' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'clientFileName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.clientFileName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.clientFileName.show,
    label: 'File name',
    type: 'string',
    align: 'center',
  },
  {
    id: 'uploadUser',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.uploadUser.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.uploadUser.show,
    label: 'Uploaded by',
    type: 'string',
    align: 'center',
  },
  {
    id: 'uploadStatus',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.uploadStatus.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.uploadStatus.show,
    label: 'Upload status',
    type: 'string',
    align: 'center',
  },
  {
    id: 'mostRecentEntryUpdatedAt',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.mostRecentEntryUpdatedAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.mostRecentEntryUpdatedAt.show,
    label: 'Most recent loc WMS time',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== 'n.a.' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'updatedLocations',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.updatedLocations.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.updatedLocations.show,
    label: 'Updated locations',
    type: 'string',
    align: 'center',
  },
  {
    id: 'requestWarnings',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.requestWarnings.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.requestWarnings.show,
    label: 'Request warnings',
    type: 'string',
    align: 'center',
  },
  {
    id: 'locationWarnings',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.locationWarnings.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.locationWarnings.show,
    label: 'Location warnings',
    type: 'string',
    align: 'center',
  },
  {
    id: 'skippedLocations',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.skippedLocations.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.skippedLocations.show,
    label: 'Skipped locations',
    type: 'string',
    align: 'center',
  },
  {
    id: 'processedChunks',
    searchable:
      TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.processedChunks.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.processedChunks.show,
    label: 'Processed chunks',
    type: 'string',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).WAREHOUSE_DATA_UPLOADS.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORTS_ALL_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'left',
  },
  {
    id: 'reportType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.reportType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.reportType.show,
    label: 'Report type',
    type: 'string',
    align: 'center',
  },
  {
    id: 'reportName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.reportName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.reportName.show,
    label: 'Report name',
    type: 'string',
    align: 'center',
    truncate: true,
  },
  {
    id: 'requestParams',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.requestParams.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.requestParams.show,
    label: 'Report params',
    type: 'string',
    align: 'center',
    truncate: true,
  },
  {
    id: 'scheduledBy',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.scheduledBy.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.scheduledBy.show,
    label: 'Scheduled by',
    type: 'string',
    align: 'center',
  },
  {
    id: 'scheduledFor',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.scheduledFor.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.scheduledFor.show,
    label: 'Scheduled for',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'recurrence',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.recurrence.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.recurrence.show,
    label: <TooltipedIcon tooltip="Recurring report" icon={<RepeatIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
    style: () => ({ maxWidth: '250px' }),
  },
  {
    id: 'startedAt',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.startedAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.startedAt.show,
    label: 'Started at',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'finishedAt',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.finishedAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.finishedAt.show,
    label: 'Finished at',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'allLocations',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.allLocations.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.allLocations.show,
    label: <TooltipedIcon tooltip="Total locations" icon={<LocationOnIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
  },
  {
    id: 'locationsToScan',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.locationsToScan.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.locationsToScan.show,
    label: (
      <TooltipedIcon
        tooltip="Locations to scan"
        icon={<LocationOnOutlinedIcon fontSize="small" />}
      />
    ),
    type: 'string',
    align: 'center',
  },
  {
    id: 'completion',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.progress.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.progress.show,
    label: <TooltipedIcon tooltip="Percentage complete" icon="%" />,
    type: 'string',
    align: 'center',
    format: (percentage: number) => Number(100 * percentage).toFixed(1),
  },
  {
    id: 'issues',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.issues.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.issues.show,
    label: 'Issues',
    type: 'string',
    align: 'center',
  },
  {
    id: 'status',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.status.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.status.show,
    label: 'Status',
    type: 'string',
    align: 'center',
  },
  {
    id: 'priority',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.priority.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.priority.show,
    label: 'Priority',
    type: 'icon',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ALL.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORTS_FINISHED_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'left',
  },
  {
    id: 'finishedAt',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.finishedAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.finishedAt.show,
    label: 'Finished at',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'reportType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.reportType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.reportType.show,
    label: 'Report type',
    type: 'string',
    align: 'center',
  },
  {
    id: 'reportName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.reportName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.reportName.show,
    label: 'Report name',
    type: 'string',
    align: 'center',
  },
  {
    id: 'requestParams',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.requestParams.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.requestParams.show,
    label: 'Report params',
    type: 'string',
    align: 'center',
    truncate: true,
  },
  {
    id: 'scheduledBy',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.scheduledBy.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.scheduledBy.show,
    label: 'Scheduled by',
    type: 'string',
    align: 'center',
  },
  {
    id: 'scheduledFor',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.scheduledFor.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.scheduledFor.show,
    label: 'Scheduled for',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'recurrence',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.recurrence.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.recurrence.show,
    label: <TooltipedIcon tooltip="Recurring report" icon={<RepeatIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
    style: () => ({ maxWidth: '250px' }),
  },
  {
    id: 'startedAt',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.startedAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.startedAt.show,
    label: 'Started at',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'allLocations',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.allLocations.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.allLocations.show,
    label: <TooltipedIcon tooltip="Total locations" icon={<LocationOnIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
  },
  {
    id: 'locationsToScan',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.locationsToScan.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.locationsToScan.show,
    label: (
      <TooltipedIcon
        tooltip="Locations to scan"
        icon={<LocationOnOutlinedIcon fontSize="small" />}
      />
    ),
    type: 'string',
    align: 'center',
  },
  {
    id: 'completion',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.progress.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.progress.show,
    label: <TooltipedIcon tooltip="Percentage complete" icon="%" />,
    type: 'string',
    align: 'center',
    format: (percentage: number) => Number(100 * percentage).toFixed(1),
  },
  {
    id: 'issues',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.issues.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.issues.show,
    label: 'Issues',
    type: 'string',
    align: 'center',
  },
  {
    id: 'status',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.status.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.status.show,
    label: 'Status',
    type: 'string',
    align: 'center',
  },
  {
    id: 'priority',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.priority.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.priority.show,
    label: 'Priority',
    type: 'icon',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_FINISHED.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORTS_ONGOING_COLUMNS = (settingsData: any) => [
  {
    id: 'startedAt',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.startedAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.startedAt.show,
    label: 'Started at',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'reportType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.reportType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.reportType.show,
    label: 'Report type',
    type: 'string',
    align: 'center',
  },
  {
    id: 'reportName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.reportName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.reportName.show,
    label: 'Report name',
    type: 'string',
    align: 'center',
  },
  {
    id: 'requestParams',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.requestParams.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.requestParams.show,
    label: 'Report params',
    type: 'string',
    align: 'center',
    truncate: true,
  },
  {
    id: 'scheduledBy',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.scheduledBy.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.scheduledBy.show,
    label: 'Scheduled by',
    type: 'string',
    align: 'center',
  },
  {
    id: 'scheduledFor',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.scheduledFor.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.scheduledFor.show,
    label: 'Scheduled for',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'recurrence',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.recurrence.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.recurrence.show,
    label: <TooltipedIcon tooltip="Recurring report" icon={<RepeatIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
    style: () => ({ maxWidth: '250px' }),
  },
  {
    id: 'allLocations',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.allLocations.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.allLocations.show,
    label: <TooltipedIcon tooltip="Total locations" icon={<LocationOnIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
  },
  {
    id: 'locationsToScan',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.locationsToScan.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.locationsToScan.show,
    label: (
      <TooltipedIcon
        tooltip="Locations to scan"
        icon={<LocationOnOutlinedIcon fontSize="small" />}
      />
    ),
    type: 'string',
    align: 'center',
  },
  {
    id: 'completion',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.progress.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.progress.show,
    label: <TooltipedIcon tooltip="Percentage complete" icon="%" />,
    type: 'string',
    align: 'center',
    format: (percentage: number) => Number(100 * percentage).toFixed(1),
  },
  {
    id: 'status',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.status.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.status.show,
    label: 'Status',
    type: 'string',
    align: 'center',
  },
  {
    id: 'priority',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.priority.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.priority.show,
    label: 'Priority',
    type: 'icon',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_ONGOING.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORTS_SCHEDULED_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'left',
  },
  {
    id: 'scheduledFor',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.scheduledFor.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.scheduledFor.show,
    label: 'Scheduled for',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      !['-', 'Auto'].includes(date) ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'requestType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.reportType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.reportType.show,
    label: 'Report type',
    type: 'string',
    align: 'center',
  },
  {
    id: 'requestName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.requestName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.requestName.show,
    label: 'Report name',
    type: 'string',
    align: 'center',
  },
  {
    id: 'requestParams',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.requestParams.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.requestParams.show,
    label: 'Report Params',
    type: 'string',
    align: 'center',
    truncate: true,
  },
  {
    id: 'createdAt',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.createdAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.createdAt.show,
    label: 'Modified at',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'scheduledBy',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.scheduledBy.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.scheduledBy.show,
    label: 'Created by',
    type: 'string',
    align: 'center',
  },
  {
    id: 'requestExpiresAt',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.requestExpiresAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.requestExpiresAt.show,
    label: 'Expires at',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'recurrence',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.recurrence.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.recurrence.show,
    label: <TooltipedIcon tooltip="Recurring report" icon={<RepeatIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
    style: () => ({ maxWidth: '250px' }),
  },
  {
    id: 'status',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.status.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.status.show,
    label: 'Status',
    type: 'string',
    align: 'center',
  },
  {
    id: 'priority',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.priority.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.priority.show,
    label: 'Priority',
    type: 'icon',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_SCHEDULED.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];

export const REPORTS_TO_REVIEW_COLUMNS = (settingsData: any) => [
  {
    id: 'checkbox',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.checkbox.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.checkbox.show,
    label: 'Checkbox',
    type: 'checkbox',
    align: 'left',
  },
  {
    id: 'reportType',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.reportType.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.reportType.show,
    label: 'Report type',
    type: 'string',
    align: 'center',
  },
  {
    id: 'reportName',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.reportName.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.reportName.show,
    label: 'Report name',
    type: 'string',
    align: 'center',
  },
  {
    id: 'requestParams',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.requestParams.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.requestParams.show,
    label: 'Report params',
    type: 'string',
    align: 'center',
    truncate: true,
  },
  {
    id: 'scheduledBy',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.scheduledBy.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.scheduledBy.show,
    label: 'Scheduled by',
    type: 'string',
    align: 'center',
  },
  {
    id: 'scheduledFor',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.scheduledFor.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.scheduledFor.show,
    label: 'Scheduled for',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'recurrence',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.recurrence.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.recurrence.show,
    label: <TooltipedIcon tooltip="Recurring report" icon={<RepeatIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
    style: () => ({ maxWidth: '250px' }),
  },
  {
    id: 'startedAt',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.startedAt.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.startedAt.show,
    label: 'Started at',
    type: 'string',
    align: 'center',
    format: (date: string) =>
      date !== '-' ? formatDateInTimezone(date, TABLE_DATETIME_FORMAT) : date,
  },
  {
    id: 'allLocations',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.allLocations.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.allLocations.show,
    label: <TooltipedIcon tooltip="Total locations" icon={<LocationOnIcon fontSize="small" />} />,
    type: 'string',
    align: 'center',
  },
  {
    id: 'locationsToScan',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.locationsToScan.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.locationsToScan.show,
    label: (
      <TooltipedIcon
        tooltip="Locations to scan"
        icon={<LocationOnOutlinedIcon fontSize="small" />}
      />
    ),
    type: 'string',
    align: 'center',
  },
  {
    id: 'completion',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.progress.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.progress.show,
    label: <TooltipedIcon tooltip="Percentage complete" icon="%" />,
    type: 'string',
    align: 'center',
    format: (percentage: number) => Number(100 * percentage).toFixed(1),
  },
  {
    id: 'status',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.status.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.status.show,
    label: 'Status',
    type: 'string',
    align: 'center',
  },
  {
    id: 'priority',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.priority.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.priority.show,
    label: 'Priority',
    type: 'icon',
    align: 'center',
  },
  {
    id: 'actions',
    searchable: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.actions.searchable,
    show: TABLE_COLUMN_OPTIONS(settingsData).REPORTS_TO_REVIEW.actions.show,
    label: 'Actions',
    type: 'actionButton',
    align: 'center',
  },
];
