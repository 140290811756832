import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { makeStyles } from 'tss-react/mui';
import { ImageList, ImageListItem, Theme } from '@mui/material';

const useStyles = makeStyles()((theme: Theme) => ({
  imageListContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    margin: 'auto 0',

    width: '100%',
    paddingBottom: 16,

    '&::-webkit-scrollbar': {
      height: 9,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#6359D1',
      borderRadius: 200,
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#D9D9D9',
      borderRadius: 10,
    },
  },

  imageListItem: {
    width: `calc((100% / 3) - ${theme.spacing(1)})`,
    minWidth: `calc((100% / 3) - ${theme.spacing(1)})`,
    maxHeight: 450,
    borderRadius: theme.spacing(1),
    overflow: 'hidden',
  },
}));

export const ImageGridComponent = ({ imageUrls }: { imageUrls: string[] }) => {
  const { classes } = useStyles();

  return (
    <ImageList className={classes.imageListContainer} gap={8}>
      {imageUrls.map((imageURL, index) => (
        <ImageListItem className={classes.imageListItem} key={imageURL}>
          <TransformWrapper>
            <TransformComponent>
              <img
                src={imageURL}
                alt={`DronePic - ${index}`}
                loading="lazy"
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </TransformComponent>
          </TransformWrapper>
        </ImageListItem>
      ))}
    </ImageList>
  );
};
