import isEmpty from 'lodash/isEmpty';
import ImageIcon from '@mui/icons-material/Image';
import { DISPLAY_ISSUE_TYPES, ISSUE_STATES, LOCATION_ISSUE_TYPES } from '../../issueTypesAndStates';
import { IIssueST, ISlotStatusST } from '../../../interfaces';
import {
  getSlotUsageStatus,
  getWMSDateFromSlotStatus,
  getWMSSlotStatusVersionFromSlotStatus,
  getVerityValueFromSlotStatus,
  getVerityDateFromSlotStatus,
  getUserNameFromSlotStatus,
  getUserValueFromSlotStatus,
  getPaddedAndMatchArrays,
} from '../slot/slotFunctions';
import {
  getIssueTypeForFullReportTable,
  getWmsBarcodeLocationAccordingToVerityFromSingleIssue,
  getVerityBarcodeLocationAccordingToWMSFromSingleIssue,
  getLocationIssueType,
} from '../issues/issueFunctions';
import { getTableRowActionsForSlotStatus } from './getTableRowActionsForSlotStatus';

export type RowDataForLocation = {
  id: string;
  location: string;
  slotUsage: '-' | JSX.Element;
  issueId: string | null;
  issue: string;
  issueType: string;
  issueFirstFoundOn: string;
  contentExpected: string[];
  wmsValue: string[];
  colorizeContentExpected: boolean;
  wmsDate: string;
  wmsCustomer: string[];
  wmsArticleNo: string[];
  wmsQty: string[];
  wmsSlotStatusVersion: number | null;
  barcodeExpectedFoundOn: string;
  barcodeFoundShouldBeOn: string;
  contentFound: string[];
  contentFoundDate: string;
  contentFoundChangedByTheClient: boolean;
  colorizeContentFound: boolean;
  matchArray: string[];
  verityValue: string[];
  verityDate: string;
  userValue: string[];
  userName: string;
  locationIssueType: LOCATION_ISSUE_TYPES;
  issueStatus: ISSUE_STATES | 'NONE';
  image: JSX.Element;
  allowRedirection: boolean;
  actions: {
    data: any;
    actions: { label: string; disabled: boolean }[];
  };
};

/**
 * Get the data needed to render a location row
 */
export const getTableRowForLocation = (
  location: string,
  slotStatus: ISlotStatusST,
  issue: IIssueST,
): RowDataForLocation => {
  const rowData: RowDataForLocation = {} as RowDataForLocation;
  const slotContent = getPaddedAndMatchArrays(slotStatus);

  rowData.location = location;
  rowData.slotUsage = getSlotUsageStatus(slotStatus);

  rowData.id = !isEmpty(issue) // TO REMOVE:: once we remove the issue page completely
    ? `${issue.issue_id}?version=${issue.version}`
    : location;
  rowData.issueId = !isEmpty(issue) ? `${issue.issue_id}` : null;

  rowData.issue = getIssueTypeForFullReportTable(issue);
  rowData.issueType = !isEmpty(issue) ? DISPLAY_ISSUE_TYPES[issue.type] : 'None';
  rowData.issueFirstFoundOn = !isEmpty(issue) ? issue.first_found_on : '-';
  rowData.issueStatus = issue.state;

  rowData.contentExpected = slotContent.paddedContentExpected;
  rowData.wmsValue = slotContent.paddedContentExpected;
  rowData.colorizeContentExpected = slotContent.colorizeContentExpected;

  rowData.wmsDate = getWMSDateFromSlotStatus(slotStatus);

  rowData.wmsCustomer = slotContent.paddedCustomer;
  rowData.wmsArticleNo = slotContent.paddedArticleNumber;
  rowData.wmsQty = slotContent.paddedQuantity;
  rowData.wmsSlotStatusVersion = getWMSSlotStatusVersionFromSlotStatus(slotStatus);

  rowData.barcodeExpectedFoundOn =
    !isEmpty(issue) && issue.state !== 'SOLVED'
      ? getWmsBarcodeLocationAccordingToVerityFromSingleIssue(issue)
      : '-';
  rowData.barcodeFoundShouldBeOn = !isEmpty(issue)
    ? getVerityBarcodeLocationAccordingToWMSFromSingleIssue(issue)
    : '-';

  rowData.contentFound = slotContent.paddedContentFound;
  rowData.contentFoundDate = slotContent.contentFoundDate;
  rowData.contentFoundChangedByTheClient = slotContent.contentFoundChangedByTheClient;
  rowData.colorizeContentFound = slotContent.colorizeContentFound;

  rowData.matchArray = slotContent.matchArray;

  rowData.verityValue = getVerityValueFromSlotStatus(slotStatus);
  rowData.verityDate = getVerityDateFromSlotStatus(slotStatus);

  rowData.userValue = slotContent.contentFoundChangedByTheClient
    ? slotContent.paddedContentFound
    : getUserValueFromSlotStatus(slotStatus);
  rowData.userName = getUserNameFromSlotStatus(slotStatus);

  rowData.locationIssueType = getLocationIssueType(issue, rowData.verityDate, rowData.wmsDate);

  rowData.issueStatus = !isEmpty(issue) ? issue.state : 'NONE';
  rowData.image = <ImageIcon />;

  // TR::2020-01-06:: FIX ME, allow all entries to redirect to a location page
  rowData.allowRedirection = !isEmpty(issue);

  rowData.actions = getTableRowActionsForSlotStatus(slotStatus, issue, rowData);

  return rowData;
};
