/*
 * Action types
 */
export enum FacilityModalsActionTypes {
  // Reset
  RESET = 'RESET',

  // requests and issues
  TOGGLE_REQUEST_STATE = 'TOGGLE_REQUEST_STATE',
  TOGGLE_SNOOZE_ISSUE = 'TOGGLE_SNOOZE_ISSUE',
  DELETE_REQUEST = 'DELETE_REQUEST',
  EDIT_REQUEST = 'EDIT_REQUEST',
  AMEND_VERITY_VALUE = 'AMEND_VERITY_VALUE',
  TOGGLE_LOCATION_MODAL = 'TOGGLE_LOCATION_MODAL',

  // reports
  REPORT_ABORT = 'REPORT_ABORT',
  REPORT_UPDATE = 'REPORT_UPDATE',
  REPORT_ARCHIVE = 'REPORT_ARCHIVE',
  REPORT_RESTORE = 'REPORT_RESTORE',

  // user management
  ADD_OR_EDIT_USER = 'ADD_OR_EDIT_USER',
  ALL_USER_EMAILS = 'ALL_USER_EMAILS',
  DELETE_USER = 'DELETE_USER',

  // facility
  SYSTEM_RESET = 'SYSTEM_RESET',
  DELETE_REPORT_SPECIFICATION = 'DELETE_REPORT_SPECIFICATION',

  // delivery
  TOGGLE_DRONE_ERRORS_MODAL = 'TOGGLE_DRONE_ERRORS_MODAL',
}
