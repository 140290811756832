import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export const initialGridState: GridInitialStatePremium = {
  filter: {
    filterModel: {
      items: [],
    },
  },
  columns: {
    columnVisibilityModel: {
      slot_label: true,
      exclusion_status: true,
      'wms_status.state': true,
      wasFoundAt: true,
      'wms_status.article_nos.0': true,
      'wms_status.qtys.0': true,
      'wms_status.customers.0': true,
      'wms_status.changed_at': true,
      'verity_status.state': true,
      shouldBeAt: true,
      'verity_status.collected_at': true,
      'issues.0.first_found_on': true,
      'issues.0.type': true,
      'issues.0.state': true,
      expected_barcodes: false,
      found_barcodes: false,
      missing_barcodes: false,
      missing_barcodes_rate: false,
      unknown_barcodes: false,
      unknown_barcodes_rate: false,
      matching_barcodes: false,
      matching_barcodes_rate: false,
    },
    dimensions: {
      slot_label: {
        maxWidth: -1,
        minWidth: 100,
        width: 100,
        flex: 1,
      },
    },
  },
  rowGrouping: {
    model: [],
  },
};
