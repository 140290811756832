import { Tooltip, Typography } from '@mui/material';
import { useCallback } from 'react';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { DISPLAY_WMS_STATES } from 'common/slotStates';
import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { getContentCellColor } from './ContentCellFunctions';
import { multipleBarcode } from './multipleBarcode.style';

type ContentCellProps = {
  row: ILocationDataST;
};

const logPrefix = getLogPrefixForType('COMPONENT', 'ContentExpectedCell');

/**
 * Functional components rendering a Content (expected)
 * cell in a location data grid
 * @param props
 * @returns
 */
export const ContentExpectedCell = (props: ContentCellProps) => {
  console.debug(logPrefix, props.row);

  const { classes } = multipleBarcode();

  const hasIssues = !!props.row.issues?.length;

  const verityStatus = props.row.verity_status as IVeritySlotStatusST;
  const wmsStatus = props.row.wms_status as IWMSSlotStatusST;

  const isMismatch = useCallback(
    (barcode: string) =>
      !!verityStatus &&
      typeof verityStatus !== 'string' &&
      !verityStatus.barcodes.includes(barcode),
    [verityStatus],
  );

  if (!wmsStatus || typeof wmsStatus === 'string') {
    return <span className="c-data-grid-cell">{wmsStatus}</span>;
  }

  let tooltip: string | JSX.Element = '';
  let content: JSX.Element = <>-</>;

  const { barcodes } = wmsStatus;

  const nonEmptyBarcodes = barcodes.filter((b) => !!b);

  if (nonEmptyBarcodes.length === 1) {
    const barcode = nonEmptyBarcodes[0];

    tooltip = barcode;
    const color = getContentCellColor(!!wmsStatus, isMismatch(barcode), false);
    content = (
      <Typography component="span" variant="body2" sx={{ color, cursor: 'default' }}>
        {barcode}
      </Typography>
    );
  } else if (nonEmptyBarcodes.length > 1) {
    tooltip = nonEmptyBarcodes.join(', ');
    const list = nonEmptyBarcodes.map((barcode) => {
      const color = getContentCellColor(!!wmsStatus, isMismatch(barcode), false);
      return (
        <Typography key={barcode} variant="body2" sx={{ color, cursor: 'default' }}>
          {barcode}
        </Typography>
      );
    });
    content = <span className={classes.verticalCenter}>{list}</span>;
  } else {
    tooltip = DISPLAY_WMS_STATES[wmsStatus.state];
    content = (
      <Typography
        variant="body2"
        component="span"
        sx={{
          color: getContentCellColor(!!wmsStatus, hasIssues, false),
          cursor: 'default',
        }}
      >
        {tooltip}
      </Typography>
    );
  }

  return (
    <Tooltip title={tooltip} className="c-data-grid-cell c-data-grid-cell-content-expected">
      {content}
    </Tooltip>
  );
};
