import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const signInStyles = makeStyles<{ imgHero: string }>()((theme: Theme, { imgHero }) => ({
  grid: {
    height: '100vh',
  },
  formWrapper: {
    height: '100%',
  },
  formInnerWrapper: {
    flex: 1,
    maxWidth: 500,
    width: '100%',
  },
  copyright: {
    marginBottom: 2,
  },
  hero: {
    backgroundImage: `url(${imgHero})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
  },
}));
