export enum WMS_STATES {
  EMPTY = 'EMPTY',
  BARCODE = 'BARCODE',
  INVALID = 'INVALID',
}

export enum VERITY_STATES {
  EMPTY = 'EMPTY',
  BARCODE = 'BARCODE',
  NOT_EMPTY = 'NOT_EMPTY',
  NO_RESULT = 'NO_RESULT',
  UNREACHABLE = 'UNREACHABLE',
}

export enum UNSCANNED_VERITY_STATES {
  ABORTED = 'ABORTED',
  FAILED = 'FAILED',
  EXCLUDED = 'EXCLUDED',
  UNREACHABLE = 'UNREACHABLE',
}

export enum DISPLAY_WMS_STATES {
  EMPTY = 'Empty location',
  BARCODE = 'Barcode',
  INVALID = 'Invalid',
}

export enum DISPLAY_VERITY_STATES {
  EMPTY = 'Empty location',
  BARCODE = 'Barcode',
  NOT_EMPTY = 'Non-empty (no barcode)',
  NO_RESULT = 'Inconclusive',
  UNREACHABLE = 'Unreachable Location',
  ABORTED = 'Out of Time',
  FAILED = 'Failed to Scan',
  EXCLUDED = 'Excluded Location',
}
