import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    rowGap: theme.spacing(2),
    paddingBlockStart: theme.spacing(4),
    paddingInline: theme.spacing(3),

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: `minmax(0, 1fr) minmax(100px, ${theme.breakpoints.values.xl}px) minmax(0, 1fr)`,
    },
  },
  section: {
    [theme.breakpoints.up('xl')]: {
      gridColumn: '2 / -2',
    },
  },
  sectionColumns: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoRows: 'min-content',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  },
  sectionFullWidth: {
    gridColumn: '1 / -1',
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
  },
  cardHeaderSection: {
    alignSelf: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(2),
    flex: '0 0 auto',
    minWidth: 0,
  },
  cardHeaderActions: {
    marginInlineStart: 'auto',
  },
}));

export const dataGridStyles = {
  border: 0,
  borderRadius: 0,
  width: '100%',
  borderWidth: 0,
  display: 'flex',
  '& .MuiDataGrid-container--top [role=row]': {
    backgroundColor: '#ffffff',
  },
  '& .MuiDataGrid-container--bottom [role=row]': {
    backgroundColor: '#ffffff',
  },
  '& .MuiDataGrid-overlayWrapperInner': {
    height: '0 !important',
  },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: '#ffffff',
  },
  '& :not(.MuiDataGrid-headerFilterRow) .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
    border: '1px solid #D3D3D3 !important',
  },
  '& :not(.MuiDataGrid-headerFilterRow) .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor:nth-of-type(n+2)':
    {
      borderLeft: '0 !important',
    },
  '& .MuiDataGrid-headerFilterRow .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
    border: '0 !important',
  },
  '& .MuiDataGrid-headerFilterRow .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--header':
    {
      border: '0 !important',
    },
  '& .MuiDataGrid-columnHeader.MuiDataGrid-withBorderColor': {
    borderTopWidth: '0 !important',
  },
  '& .MuiDataGrid-scrollbarFiller.MuiDataGrid-scrollbarFiller--header': {
    borderTopWidth: '0 !important',
  },
  '& .MuiDataGrid-columnHeader input': {
    fontSize: 'small',
  },
  '& .row-even': {
    backgroundColor: 'rgb(250, 250, 250)',
  },
  '.MuiDataGrid-cell:focus-within,': {
    outline: 'none !important',
  },
  '.MuiDataGrid-columnHeader:focus-within,': {
    outline: 'none !important',
  },
  '& .MuiDataGrid-columnHeader .MuiDataGrid-columnHeader': {
    backgroundColor: 'rgba(0,0,0,0)',
  },
  '& .MuiDataGrid-cell': { borderTopStyle: 'none' },
  '& .MuiDataGrid-headerFilterRow .MuiDataGrid-columnHeader label': {
    fontSize: 'small',
  },
  '& .MuiDataGrid-row:hover': {
    cursor: 'pointer',
  },
  '& .MuiDataGrid-filler': {
    marginTop: '0 !important',
  },
};
