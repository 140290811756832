import { WMS_STATES } from 'common/slotStates';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';

/**
 * Check whether the WMS State of the location is valid
 * @param locationData Location Data
 * @returns true if valid
 */
export const isWMSStateValid = (locationData: ILocationData) =>
  locationData.wmsState !== WMS_STATES.INVALID;

/**
 * Check whether the location value has been overridden
 * @param locationData Location Data
 * @returns true if it has a value override
 */
export const hasOverrideValue = (locationData: ILocationData) =>
  locationData.userOverrideValue !== '';

/**
 * Check whether there is no override and no mismatch
 * @param locationData Location Data
 * @returns true if there is no override & no mismatch
 */
export const hasNoOverrideAndNoMismatch = (locationData: ILocationData) =>
  !hasOverrideValue(locationData) && locationData.verityValue === locationData.wmsValue;

/**
 * Check whether an override to match WMS data has already been made
 * @param locationData Location Data
 * @returns true if the value is overridden to WMS
 */
export const hasOverrideToWMS = (locationData: ILocationData) =>
  hasOverrideValue(locationData) && locationData.userOverrideValue === locationData.wmsValue;

/**
 * Check whether an override to match Verity data has already been made
 * @param locationData Location Data
 * @returns
 */
export const hasOverrideToVerity = (locationData: ILocationData) =>
  hasOverrideValue(locationData) && locationData.userOverrideValue === locationData.verityValue;
