import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import isEmpty from 'lodash/isEmpty';

// material icons
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

import CustomSelect from 'components/common/CustomFormComponents/CustomSelect';

import Spinner from 'components/common/Spinner';
import { Box } from 'components/common/Box';
import { FormInput } from 'components/FormFields/FormInput';
import { Accordion } from 'components/common/Accordion';
import { baseCardStyle } from 'components/BaseCard/styles';
import { useRequestController } from '../../../../../hooks';
import { ASSIGNMENTS, validate, ValidationErrors } from './helpers';
import { connectDroneStyles } from './styles';
import {
  AssignmentType,
  DroneServices,
  IGenerateQRCode,
} from '../../../../../services/DroneServices';
import { StaticAssignment } from './StaticAssignmentInputs';

/**
 * Generate QR code
 * @param param0 IGenerateQrCodeProps props
 * @returns component
 */
export function GenerateQrCode({
  qrCode,
  setQrCode,
}: {
  qrCode: string;
  setQrCode: React.Dispatch<any>;
}) {
  // state
  const initialValues = {
    ssid: '',
    passphrase: '',
    assignment: ASSIGNMENTS[0].value,
    ip: '',
    netmask: '',
    gateway: '',
    useGatewayDns: true,
    preferredDnsServer: '',
    alternateDnsServer: '',
  };

  const [isLoading, setIsLoading] = useState(false);
  const [showClearButton, setShowClearButton] = useState(false);
  const [errors, setErrors] = useState<ValidationErrors>({});

  // params
  const { systemId = '', flightDomainId = '' } = useParams();

  const {
    requestController: { doRequest },
  } = useRequestController('ConnectDroneToWiFiCard');

  const { classes: droneClasses } = connectDroneStyles();
  const { classes: baseCardClasses } = baseCardStyle();

  /**
   * Handle generate QR code
   */
  const handleGenerateQR = (values: Partial<IGenerateQRCode>) => {
    const toSubmit: IGenerateQRCode = {
      ssid: values.ssid!,
      systemId,
      flightDomainId,
    };
    if (values.passphrase) {
      toSubmit.passphrase = values.passphrase;
    }
    toSubmit.assignment = values.assignment;
    if (values.assignment === ASSIGNMENTS[1].value) {
      toSubmit.ip = values.ip;
      toSubmit.netmask = values.netmask;
      toSubmit.gateway = values.gateway;
    }
    if (!values.useGatewayDns) {
      toSubmit.preferredDnsServer = values.preferredDnsServer;

      if (values.alternateDnsServer) {
        toSubmit.alternateDnsServer = values.alternateDnsServer;
      }
    }

    const errors = validate({ ...toSubmit, useGatewayDns: values.useGatewayDns });

    if (!isEmpty(errors)) {
      setErrors(errors);
    } else {
      generateQrCode(toSubmit);
      setErrors({});
      setShowClearButton(false);
    }
  };

  /**
   * Generate QR code
   * @param ssid wifi name
   * @param passphrase wifi password
   * @returns generate QR code request
   */
  const generateQrCode = (values: IGenerateQRCode) =>
    doRequest({
      request: DroneServices.generateQRCode,
      requestParams: [values],
      callbackBeforeSend: () => setIsLoading(true),
      callbackSuccess: ({ data }) => setQrCode(data?.image_url),
      callbackFinally: () => setIsLoading(false),
      messageErrorFallback: 'An error occurred when trying to generate QR code',
    });

  /**
   * Handle regenerate action
   * - Bring back form with previously confirmed values
   */
  const handleCodeRegeneration = () => {
    setQrCode('');
    setShowClearButton(true);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleGenerateQR}>
      {({ handleChange, values }) => {
        const isStaticAssignment = values.assignment === ASSIGNMENTS[1].value;
        return (
          <Form>
            <Card
              data-testid="c-qr-code-generation-card"
              className={droneClasses.cardWrapper}
              elevation={3}
            >
              <Backdrop sx={{ position: 'absolute' }} open={isLoading}>
                <Spinner />
              </Backdrop>
              <CardHeader
                classes={{
                  title: baseCardClasses.title,
                  subheader: baseCardClasses.subheader,
                }}
                data-testid="c-qr-code-generator-card-title"
                title="QR Code Generator"
              />
              <Divider />
              <CardContent sx={{ height: '100%', overflowY: 'auto' }}>
                <Typography mb={4} color="textSecondary">
                  Use the QR code to enter the input into the drone, such as WiFi credentials. Input
                  is being added by scanning the QR code using the drone. For further directions on
                  how to perform drone scanning, follow the instructions on "Connect drone to WiFi"
                  card.
                </Typography>
                {!qrCode ? (
                  <>
                    <FormInput
                      margin="dense"
                      id="ssid"
                      label="WiFi Network Name"
                      name="ssid"
                      inputProps={{ 'data-testid': 'c-ssid' }}
                      autoComplete="new-password"
                      fullWidth
                      error={!!errors.ssid}
                      helperText={errors.ssid}
                    />
                    <FormInput
                      id="passphrase"
                      name="passphrase"
                      margin="dense"
                      label="Password"
                      inputProps={{ 'data-testid': 'c-passphrase' }}
                      togglePasswordVisibility={true}
                      autoComplete="new-password"
                      fullWidth
                      type="password"
                      error={!!errors.passphrase}
                      helperText={errors.passphrase}
                    />
                    <Accordion
                      details={
                        <>
                          <CustomSelect
                            id="assignment"
                            variant="outlined"
                            margin="dense"
                            name="assignment"
                            valueOptions={ASSIGNMENTS}
                            value={values.assignment as AssignmentType}
                            onChange={handleChange}
                            disabled={false}
                            label="Assignment"
                            error={false}
                            errorMessage=""
                            defaultValue=""
                            testId="c-assignment"
                          />

                          {isStaticAssignment && (
                            <StaticAssignment
                              errors={errors}
                              useGatewayDns={!!values.useGatewayDns}
                            />
                          )}
                        </>
                      }
                      summary={<Typography color="textSecondary">Advanced options</Typography>}
                    />
                  </>
                ) : (
                  <Grid
                    data-testid="c-connect-drone-to-wifi-card-qr-code"
                    item
                    xs={12}
                    display="flex"
                    height="100%"
                    justifyContent="center"
                  >
                    <Box position="relative">
                      {qrCode && <img src={qrCode} width="250" height="250" alt="qr code" />}
                    </Box>
                  </Grid>
                )}
              </CardContent>
              <CardActions>
                {!qrCode ? (
                  <Box width="100%" display="flex">
                    {showClearButton && (
                      <Button
                        data-testid="c-generate-button"
                        sx={{ margin: 1 }}
                        variant="outlined"
                        size="medium"
                        color="primary"
                        type="reset"
                        startIcon={<CloseIcon />}
                      >
                        Clear
                      </Button>
                    )}
                    <Button
                      data-testid="c-generate-button"
                      fullWidth
                      sx={{ margin: 1, flex: 1 }}
                      variant="contained"
                      color="primary"
                      size="medium"
                      type="submit"
                      endIcon={<KeyboardArrowRight />}
                    >
                      Generate QR code
                    </Button>
                  </Box>
                ) : (
                  <Button
                    data-testid="c-generate-button"
                    fullWidth
                    variant="outlined"
                    color="primary"
                    size="medium"
                    sx={{ margin: 1 }}
                    startIcon={<KeyboardArrowLeft />}
                    onClick={handleCodeRegeneration}
                  >
                    Back to generator
                  </Button>
                )}
              </CardActions>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}
