import FeedbackIcon from '@mui/icons-material/Feedback';
import { Box, IconButton } from '@mui/material';
import TooltipedIcon from 'components/common/TooltipedIcon';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { isFeedbackShown } from './FeedbackDrawer.utils';

export const FeedbackDrawerToggler = ({
  feedbackDrawerOpen,
  setFeedbackDrawerOpen,
}: {
  feedbackDrawerOpen: boolean;
  setFeedbackDrawerOpen: (value: React.SetStateAction<boolean>) => void;
}) => {
  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;

  const showFeedback = isFeedbackShown(facilitySettings);

  facilitySettings.support_email_recipients !== '' &&
    userHasPermission(PERMISSION.SHARE_FEEDBACK_ON_LOCATION);

  if (!showFeedback) {
    return null;
  }

  return feedbackDrawerOpen ? (
    <Box component="div" position="fixed" bottom="40px" right={{ xs: 'max(26%, 290px)' }}>
      <IconButton
        size="small"
        onClick={() => setFeedbackDrawerOpen(false)}
        data-testid="show-feedback-drawer"
      >
        <TooltipedIcon
          tooltip="Collapse the feedback drawer."
          icon={<FeedbackIcon sx={{ transform: 'scaleX(-1)' }} />}
        />
      </IconButton>
    </Box>
  ) : (
    <Box component="div" position="fixed" bottom="40px" right="40px">
      <IconButton
        size="small"
        onClick={() => setFeedbackDrawerOpen(true)}
        data-testid="show-feedback-drawer"
      >
        <TooltipedIcon
          tooltip="Expand the feedback drawer."
          icon={<FeedbackIcon sx={{ transform: 'scaleX(-1)' }} />}
        />
      </IconButton>
    </Box>
  );
};
