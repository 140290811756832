import { TextField, InputAdornment, IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { setFilterOnColumns } from './setFilterOnColumns';

export const SearchField = ({
  gridApiRef,
  columns,
}: {
  gridApiRef: React.MutableRefObject<GridApiPremium>;
  columns: string[];
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState('');

  const updateTerm = (term: string) => {
    debounce(() => {
      setFilterOnColumns(gridApiRef, term, columns);
    }, 200)();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value;
    setValue(term);
    updateTerm(term);
  };

  const handleClear = () => {
    setValue('');
    setFilterOnColumns(gridApiRef, '', columns);
  };

  return (
    <TextField
      variant="outlined"
      size="small"
      color="primary"
      aria-label="filter"
      data-testid="c-search-filter"
      onChange={handleChange}
      value={value}
      inputRef={inputRef}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="c-clear-search-filter"
              aria-label="clear filter"
              onClick={handleClear}
              size="small"
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
