import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import WarehouseServices from 'services/WarehouseServices';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { useRequestController } from 'hooks';
import SettingsCard from '../../SettingsCard';
import { getIssuesOptions } from '../../options/getIssuesOptions';
import { CustomIssueLogicVisualizer } from './rules-visualizer/CustomIssueLogicVisualizer';

const logPrefix = getLogPrefixForType('COMPONENT', 'IssueLogic');

export const IssueLogic = ({
  systemId,
  isLoading,
  facilitySettings,
  handleValuesChange,
}: {
  systemId: string;
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  handleValuesChange: (prop: string, value: any) => void;
}) => {
  const [bcMatchLogics, setBcMatchLogics] = useState({} as any);
  const [issueLogicFilters, setIssueLogicFilters] = useState({} as any);
  const [issueLogicRules, setIssueLogicRules] = useState(facilitySettings.issue_logic_rules);

  useEffect(() => {
    if (!isLoading) {
      setIssueLogicRules(facilitySettings.issue_logic_rules);
    }
  }, [facilitySettings.issue_logic_rules, isLoading]);

  const { requestController } = useRequestController(logPrefix);

  useEffect(() => {
    requestController.doRequest({
      request: WarehouseServices.getBcMatchLogics,
      requestParams: [systemId],
      messageErrorFallback: 'The Bc Match Logics could not be fetched.',
      callbackSuccess: (r) => {
        const matchLogic = r.data.map((item: { name: string }) => ({
          label: item.name,
          value: item.name,
        }));
        matchLogic.push({ label: 'Default', value: 'Default' });
        setBcMatchLogics(matchLogic);
      },
    });

    requestController.doRequest({
      request: WarehouseServices.getIssueLogicFilters,
      requestParams: [systemId],
      messageErrorFallback: 'The Issue Logic Filters could not be fetched.',
      callbackSuccess: (r) => {
        const filter = r.data.map((item: { name: string; description: string }) => ({
          label: item.name,
          value: item.name,
          description: item.description,
        }));
        setIssueLogicFilters(filter);
      },
    });
  }, [requestController, systemId]);

  return (
    <Grid md={12} sm={12} xs={12} item>
      <SettingsCard
        title="Issues"
        isLoading={isLoading || !bcMatchLogics.length || !issueLogicFilters.length}
        testId="c-issues-settings-card"
        items={getIssuesOptions(
          facilitySettings,
          handleValuesChange,
          bcMatchLogics,
          issueLogicFilters,
        )}
        content={
          issueLogicRules && <CustomIssueLogicVisualizer issueLogicRules={issueLogicRules} />
        }
      />
    </Grid>
  );
};
