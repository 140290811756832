import { Tooltip, Typography } from '@mui/material';
import { useCallback } from 'react';
import { DISPLAY_VERITY_STATES } from 'common/slotStates';
import { ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/warehouse_status';
import { isDataStale, getContentCellColor } from './ContentCellFunctions';
import { multipleBarcode } from './multipleBarcode.style';

type ContentCellProps = {
  row: ILocationDataST;
};

/**
 * Functional components rendering a Content (found)
 * cell in a location data grid
 * @param props
 * @returns
 */
export const ContentFoundCell = (props: ContentCellProps) => {
  const hasIssues = !!props.row.issues?.length;

  const { classes } = multipleBarcode();

  const verityStatus = props.row.verity_status as IVeritySlotStatusST;
  const wmsStatus = props.row.wms_status as IWMSSlotStatusST;

  const isMismatch = useCallback(
    (barcode: string) =>
      hasIssues &&
      !!wmsStatus &&
      typeof wmsStatus !== 'string' &&
      !wmsStatus.barcodes.includes(barcode),
    [hasIssues, wmsStatus],
  );

  if (!verityStatus || typeof verityStatus === 'string') {
    return <span className="c-data-grid-cell">{verityStatus}</span>;
  }

  const { barcodes: verityBarcodes, user_overrides: userOverrides } = verityStatus;

  const isStale = isDataStale(verityStatus, wmsStatus);

  let tooltip: string | JSX.Element = '';
  let content: JSX.Element = <>-</>;

  const showOverrides = userOverrides?.length;

  const barcodes = showOverrides
    ? userOverrides![userOverrides!.length - 1].barcodes
    : verityBarcodes;

  if (barcodes.length === 1) {
    const barcode = barcodes[0];
    tooltip = barcode;
    const color = getContentCellColor(!!wmsStatus, isMismatch(barcode), isStale);
    content = (
      <Typography component="span" variant="body2" sx={{ color, cursor: 'default' }}>
        {barcode}
        {showOverrides ? ' *' : ''}
      </Typography>
    );
  } else if (barcodes.length > 1) {
    tooltip = barcodes.join(', ');
    const list = barcodes.map((barcode) => {
      const color = getContentCellColor(!!wmsStatus, isMismatch(barcode), isStale);
      return (
        <Typography key={barcode} variant="body2" sx={{ color, cursor: 'default' }}>
          {barcode}
          {showOverrides ? ' *' : ''}
        </Typography>
      );
    });
    content = <span className={classes.verticalCenter}>{list}</span>;
  } else if (showOverrides) {
    const overridenValue = DISPLAY_VERITY_STATES[userOverrides![userOverrides!.length - 1].state];
    content = (
      <Typography
        variant="body2"
        component="span"
        sx={{ color: getContentCellColor(!!wmsStatus, hasIssues, isStale), cursor: 'default' }}
      >
        {overridenValue} {' *'}
      </Typography>
    );
  } else {
    tooltip = DISPLAY_VERITY_STATES[verityStatus.state];
    content = (
      <Typography
        variant="body2"
        component="span"
        sx={{ color: getContentCellColor(!!wmsStatus, hasIssues, isStale), cursor: 'default' }}
      >
        {tooltip}
      </Typography>
    );
  }

  if (showOverrides) {
    const lastOverride = userOverrides![userOverrides!.length - 1];

    tooltip = (
      <span>
        value overridden: <br />
        by {lastOverride.user_name} <br />
        on {lastOverride.timestamp}. <br />
        Original value:{' '}
        <b>
          {verityBarcodes.length > 0
            ? verityBarcodes.join(' ,')
            : DISPLAY_VERITY_STATES[verityStatus.state]}
        </b>
      </span>
    );
  }

  return (
    <Tooltip title={tooltip} className="c-data-grid-cell c-data-grid-cell-content-found">
      {content}
    </Tooltip>
  );
};
