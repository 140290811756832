import { useState } from 'react';
import { Drawer } from '@mui/material';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import IssuesStore from 'store/IssuesStore';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { ISSUE_STATES } from 'common/issueTypesAndStates';
import { getIsSnoozable } from '../utils/getIsSnoozable';
import { FeedbackReasonType } from '../ShareFeedback/feedbackReason.model';
import { ShareFeedback } from '../ShareFeedback/ShareFeedback';
import { AmendValues } from '../amend/AmendValues';
import { isAmendForumShown, isShareFeedbackOnLocationShown } from './FeedbackDrawer.utils';

export const FeedbackDrawer = ({
  isOpen,
  locationData,
  setSpinnerLoading,
  refreshLocationData,
  setDidUserOverwrite,
  currentLocationIndex,
  sendLocationInfo,
}: {
  isOpen: boolean;
  locationData: ILocationData;
  setSpinnerLoading: React.Dispatch<React.SetStateAction<boolean>>;
  refreshLocationData: (locationLabel: string) => void;
  setDidUserOverwrite: React.Dispatch<React.SetStateAction<boolean>>;
  currentLocationIndex: number;
  sendLocationInfo: (feedbackCategories: FeedbackReasonType[], message: string) => any;
}) => {
  const { currentSystemId: systemId, facilitySettings } =
    useFacilityLevelStore().stateFacilityLevel;

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(true);

  const showAmendForm = isAmendForumShown(facilitySettings);
  const showShareFeedbackForm = isShareFeedbackOnLocationShown(facilitySettings);

  const issueId = locationData.rowData?.issueId;

  const snooze = () =>
    IssuesStore.toggleSnoozeIssue(systemId!, issueId, ISSUE_ACTIONS.SNOOZE).then(() => {
      refreshLocationData(locationData?.location);
    });

  const isSnoozed = locationData.rowData?.state === ISSUE_STATES.SNOOZED;

  if (!showAmendForm && !showShareFeedbackForm) {
    return null;
  }

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      id="feedback-drawer"
      PaperProps={{
        style: {
          position: 'absolute',
          top: '93px',
          width: '25%',
          height: 'calc(100vh - 141px)',
          minWidth: '280px',
        },
      }}
      variant="persistent"
    >
      {showAmendForm && (
        <AmendValues
          locationData={locationData}
          setSpinnerLoading={setSpinnerLoading}
          refreshLocationData={refreshLocationData}
          setDidUserOverwrite={setDidUserOverwrite}
          currentLocationIndex={currentLocationIndex}
          isExpanded={!isFeedbackOpen}
          toggledCallback={(isOpen: boolean) => {
            setIsFeedbackOpen(!isOpen);
          }}
        />
      )}
      {showShareFeedbackForm && (
        <ShareFeedback
          key={locationData.location}
          isSnoozed={isSnoozed}
          isSnoozable={getIsSnoozable(locationData)}
          snooze={snooze}
          sendLocationInfo={sendLocationInfo}
          isExpanded={isFeedbackOpen}
          toggledCallback={(isOpen: boolean) => {
            setIsFeedbackOpen(isOpen);
          }}
        />
      )}
    </Drawer>
  );
};
