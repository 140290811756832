/* eslint-disable no-prototype-builtins */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import {
  DataGridPremium,
  GridInitialState,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { SHOW_PAGE_HEADER_MENU_ICON } from 'common/settings';
import PageHeaderSection from 'components/Page/PageHeaderSection';
import { WarehouseStatusOverview } from 'pages/WarehouseStatus/warehouse-status-overview/WarehouseStatusOverview';
import { ISSUE_ACTIONS } from 'common/Actions/actionTypes';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { useUserLevelStore } from 'store/UserLevelStore/userLevelStore';
import { MutationNames } from 'ts-types/MutationNames';
import { ModalConfirm } from 'components/ModalsAndPopups/ModalConfirm';
import { GridPremiumToolbarButton } from 'common/Tables/toolbar/GridPremiumToolbarButton';
import { ILocationDataST } from 'codegen/warehouse_status';
import { LinearProgress } from '@mui/material';
import { useRequestController } from '../../hooks';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useFacilityModalsStore } from '../../store/Modals';
import { columns } from './models/WHSColumns';
import { WHSStore } from './WHStore';
import { WHSToolbar } from './toolbar/WHSToolbar';
import { getGridRowHeight } from './utils/getGridRowHeight.util';
import { WarehouseStatusXTabs } from './WarehouseStatusXTabs';
import { WAREHOUSE_STATUS_TABS } from './models/WarehouseStatusXTabs.model';
import SnoozeMultipleIssuesModal from './snooze-issues/SnoozeMultipleIssuesModal';
import { handleRowClickEvent } from './utils/handleRowClickEventutil';
import { handleGridStateChange } from './utils/handleGridStateChange.util';
import { onSaveCustomGrid } from './utils/onSaveCustomGrid.util';
import { onLoadCustomGrid } from './utils/onLoadCustomGrid.util';
import { sendWarehouseExportEmail as sendWarehouseExportEmailAPI } from './api/sendWarehouseExportEmail.api';
import { SearchField } from './search-field/SearchField';
import { searchFieldColumns } from './models/searchFieldColumns.model';
import { dataGridStyles, useStyles } from './WarehouseStatusX.styles';

const logPrefix = getLogPrefixForType('PAGE', 'WHS Page');
const noRows = [] as never[];

const useRerender = () => {
  const [, setState] = useState(Date.now());
  return () => setState(Date.now());
};

const STARTING_TAB = 1;

export const WarehouseStatusX = ({
  disableVirtualization,
}: {
  disableVirtualization?: boolean;
}) => {
  const { cx, classes } = useStyles();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { dispatchFacilityModals } = useFacilityModalsStore();

  const { slots } = stateFacilityLevel;
  const rerender = useRerender();
  const { requestController } = useRequestController('WarehouseStatusX');
  const { systemId = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<number>(
    Number(searchParams.get('activeTab') ?? STARTING_TAB),
  );
  const gridApiRef = useGridApiRef();

  const [checkboxSelection, setCheckboxSelection] = useState(false);
  const [isSnoozeModalOpen, setIsSnoozeModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [snoozeAction, setSnoozeAction] = useState(ISSUE_ACTIONS.SNOOZE);
  const [tabCounts, setTabCounts] = useState<{
    [key: string]: number;
  }>({});

  const dataGridStateFromURL = searchParams.get('dataGridState');
  const [gridViewFromURL] = useState<GridInitialState>(
    dataGridStateFromURL ? JSON.parse(dataGridStateFromURL) : undefined,
  );
  const isVisibleDownloadButton = userHasPermission(PERMISSION.EXPORT_WAREHOUSE_STATUS_VIA_EMAIL);

  const { stateUserLevel } = useUserLevelStore();

  const { enqueueSnackbar } = useSnackbar();

  const { mutate: sendWarehouseExportEmail, isPending: isDownloading } = useMutation({
    mutationFn: () => sendWarehouseExportEmailAPI(systemId),
    mutationKey: [MutationNames.SEND_WAREHOUSE_EXPORT_MAIL],
    onSuccess: () => {
      enqueueSnackbar('The warehouse status export shall be in your inbox in a few minutes.', {
        variant: 'success',
      });
      setIsDownloadModalOpen(false);
    },
    onError: (error: Error) => {
      enqueueSnackbar('The Warehouse Status could not be sent by email.', {
        variant: 'error',
      });
    },
  });

  const store = useMemo(() => {
    console.debug(logPrefix, 'running WHS store memo -');
    if (gridApiRef.current.hasOwnProperty('updateRows')) {
      return WHSStore(systemId, gridApiRef.current, requestController, slots);
    }
    console.debug(logPrefix, '- Grid API is not available, store not created');
    return null;
  }, [gridApiRef.current]);

  const loadLocationsData = () => {
    if (!store) {
      rerender();
    } else {
      store.loadLocationData();
    }
  };

  useEffect(() => {
    console.debug(logPrefix, `useEffect run start ${store ? 'with' : 'without'} store`);
    loadLocationsData();
  }, [store]);

  const rowCount = gridApiRef.current?.getRowsCount && gridApiRef.current.getRowsCount();

  useEffect(() => {
    if (gridApiRef.current.hasOwnProperty('getSortedRows')) {
      const rows: ILocationDataST[] = gridApiRef.current.getSortedRows() as ILocationDataST[];

      const counts = {
        'FULL REPORT': rows.length,
        ISSUES: 0,
        POTENTIAL: 0,
        INCONCLUSIVE: 0,
        EXCLUDED: 0,
        SNOOZED: 0,
      };

      rows.forEach((row) => {
        if (row.slot_settings?.exclusion_status === 'EXCLUDE') {
          counts.EXCLUDED += 1;
        }

        if (row.issues.length > 0 && row.issues[0].state !== 'SOLVED') {
          if (row.issues[0].type === 'INCONCLUSIVE1_W_BC_V_NOTEMPTY') {
            counts.POTENTIAL += 1;
          } else if (
            row.issues[0].type === 'INCONCLUSIVE2_W_BC_V_NORESULT' ||
            row.issues[0].type === 'INCONCLUSIVE3_W_EMPTY_V_NORESULT' ||
            row.issues[0].type === 'INCONCLUSIVE4_W_INVALID' ||
            row.issues[0].type === 'INCONCLUSIVE5_V_INVALID'
          ) {
            counts.INCONCLUSIVE += 1;
          } else if (row.issues[0].state === 'SNOOZED') {
            counts.SNOOZED += 1;
          } else {
            counts.ISSUES += 1;
          }
        }
      });

      setTabCounts(counts);
    }
  }, [rowCount]);

  console.debug(
    logPrefix,
    `rendering ${rowCount} rows in columns`,
    columns,
    'on api',
    gridApiRef.current,
  );

  const loadingString =
    store && !store.isEverythingLoaded()
      ? `- Loading... (${rowCount} of ${slots.length} locations)`
      : '';

  const selectedRows = () =>
    Array.from(gridApiRef.current.getSelectedRows(), ([id, value]) => value) as ILocationDataST[];

  const tableToolbar = () => (
    <WHSToolbar
      checkboxSelection={checkboxSelection}
      setCheckboxSelection={setCheckboxSelection}
      onSnooze={() => {
        setSnoozeAction(ISSUE_ACTIONS.SNOOZE);
        setIsSnoozeModalOpen(true);
      }}
      onUnSnooze={() => {
        setSnoozeAction(ISSUE_ACTIONS.UNSNOOZE);
        setIsSnoozeModalOpen(true);
      }}
      gridApiRef={gridApiRef}
    />
  );

  const handleTabChange = (event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
    setActiveTab(newValue);
    const { gridState } = WAREHOUSE_STATUS_TABS[newValue];
    gridApiRef.current.restoreState({ ...gridState });

    searchParams.set('activeTab', newValue.toString());
    setSearchParams(searchParams);
  };

  return (
    <>
      <PageHeaderSection
        title="BETA Warehouse status"
        subtitle={`The latest information about your warehouse ${loadingString}`}
        showMenuIcon={SHOW_PAGE_HEADER_MENU_ICON.WAREHOUSE_STATUS}
        onClickDownload={() => setIsDownloadModalOpen(true)}
        downloadSpinning={isDownloading}
        showDownloadBtn={isVisibleDownloadButton}
      />

      <div className={classes.wrapper}>
        <div className={cx(classes.section, classes.sectionColumns)}>
          <WarehouseStatusOverview systemId={systemId} />
        </div>

        <div className={cx(classes.section, classes.sectionFullWidth)}>
          <Card
            sx={{
              height: 'clamp(500px, 700px, calc(100vh - 200px))',
            }}
          >
            <div className={classes.cardHeader}>
              <WarehouseStatusXTabs
                tabs={WAREHOUSE_STATUS_TABS}
                activeTab={activeTab}
                handleTabChange={handleTabChange}
                tabCounts={tabCounts}
              />

              <div className={cx(classes.cardHeaderSection, classes.cardHeaderActions)}>
                <GridPremiumToolbarButton
                  name="SAVE VIEW"
                  tooltip="Save View"
                  onClick={() => onSaveCustomGrid({ activeTab, gridApiRef })}
                  icon={<BookmarkAddIcon />}
                />

                <GridPremiumToolbarButton
                  name="LOAD VIEW"
                  tooltip="Load View"
                  disabled={!localStorage.getItem('savedGridState')}
                  onClick={() => onLoadCustomGrid({ activeTab, gridApiRef })}
                  icon={<BookmarkIcon />}
                />
              </div>

              <div className={classes.cardHeaderSection}>
                <SearchField gridApiRef={gridApiRef} columns={searchFieldColumns} />
              </div>
            </div>
            <DataGridPremium
              sx={dataGridStyles}
              columns={columns}
              apiRef={gridApiRef}
              rows={noRows}
              getRowId={(row) => row.slot_label}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? 'row-even' : 'row-odd'
              }
              getRowHeight={getGridRowHeight}
              columnHeaderHeight={45}
              slotProps={{ toolbar: { excelOptions: { disableToolbarButton: true } } }}
              slots={{
                toolbar: tableToolbar,
                loadingOverlay: LinearProgress as GridSlots['loadingOverlay'],
              }}
              loading={store?.isLoading()}
              onRowClick={(params) =>
                handleRowClickEvent({
                  params,
                  gridApiRef,
                  dispatchFacilityModals,
                  loadLocationsData,
                })
              }
              localeText={{
                // @ts-expect-error customising types for localeText is not fully supported currently. https://github.com/mui/mui-x/blob/HEAD/packages/x-data-grid/src/constants/localeTextConstants.ts
                headerFilterOperatorNotContains: 'not contain',
                headerFilterOperatorIsNotAnyOf: 'is not any of',
              }}
              checkboxSelection={checkboxSelection}
              rowGroupingColumnMode="multiple"
              disableRowSelectionOnClick
              disableAggregation
              headerFilters
              headerFilterHeight={60}
              onStateChange={() =>
                handleGridStateChange({ gridApiRef, searchParams, setSearchParams })
              }
              initialState={
                gridViewFromURL
                  ? { ...gridViewFromURL }
                  : {
                      ...WAREHOUSE_STATUS_TABS[activeTab].gridState,
                    }
              }
              disableVirtualization={disableVirtualization}
            />
          </Card>
        </div>
      </div>

      {isSnoozeModalOpen && (
        <SnoozeMultipleIssuesModal
          locations={selectedRows()}
          action={snoozeAction}
          onClose={() => setIsSnoozeModalOpen(false)}
          refreshData={loadLocationsData}
        />
      )}

      {isDownloadModalOpen && (
        <ModalConfirm
          handleClose={() => setIsDownloadModalOpen(false)}
          opened={isDownloadModalOpen}
          title="Send warehouse status export via e-mail?"
          message={`The warehouse status export will be sent to ${stateUserLevel.username} via email. The process may take a few minutes.`}
          onConfirm={() => {
            setIsDownloadModalOpen(false);
            sendWarehouseExportEmail();
          }}
        />
      )}
    </>
  );
};
